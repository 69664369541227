import React, { useEffect, useState } from 'react';

import {
	PatientDetails,
	PatientMobileAndLocation
} from '../../components/common/patientDetails';

import { useAuthSelector } from '../../services/selectors/authSelector';
import { ErrorMessages } from '../../components/error/errorMessages';
import { useCustomStates } from '../../hooks/useCustomStates';

import { CounsellingNewRxData } from '../../components/common/dispenseRxData';
import { SOSemergencyEnableButton } from '../../components/common/SOSemergencyEnableButton';
import { fetchPatientNewRxData } from '../../services/apis/supervisor/patientNewRXData';

import { useSuperVisorSelector } from '../../services/selectors/allSuperVisorSelector';
import { removePatientNewRxData } from '../../services/slices/supervisor/patientNewRXData';

import {
	ErrorMessage,
	SuccessMessage
} from '../../components/common/AllConfirmationMessages';

import { UpdateNewRXDataOption } from '../../services/apis/supervisor/updateNewRxOption';

import SearchBox from '../../components/common/searchBox';

import * as routeNames from '../../routes/routeName'
import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';

import * as Hooks from '../../hooks';
import * as Loading from '../../components/common/loader'

import PageNotFound from '../notFound/pageNotFound';
import Sheet from 'react-modal-sheet';

const Counselling = () => {

	const { pharmacyId } = useAuthSelector()
	const { patientNewRxDataResponse } = useSuperVisorSelector()

	const dispatch = Hooks.useCustomDispatch();
	const navigate = Hooks.useCustomNavigate();
	const [defaultValue, setDefaulValue] = useState('')

	const { openIndex, setOpenIndex,
		loading, setLoading,
		setShowData, apiCallMade, setApiCallMade,
	} = useCustomStates()

	const [showModel, setShowModel] = useState(false)
	const [patientData, setPatientData] = useState(null)
	const [counsellingOption, setCounsellingOption] = useState('');

	const [showToast, setShowToast] = useState({ sucessToast: false, errorToast: false })
	const [PatientName, setPatientName] = useState(null)

	const patientNewRxData = patientNewRxDataResponse?.data

	const handleDropdownToggle = (index, data) => {
		if (openIndex === index) {
			setOpenIndex(null);
		} else {
			setOpenIndex(index);
		}
		setShowData(false)
		setTimeout(() => {
			const nextPatientCard = document.getElementById(`patientCard-${index}`);
			if (nextPatientCard) {
				nextPatientCard.scrollIntoView({ behavior: "smooth", block: "start" });
			}
		}, 100)
		const defaultOptions = data?.patients_new_rx_data?.map(patient => patient?.counselling || '');
		setCounsellingOption(defaultOptions)
	}

	useEffect(() => {
		if (pharmacyId) {
			if (!apiCallMade) {
				const params = {
					pharmacy_id: pharmacyId,
					setLoading: setLoading
				}
				setLoading(true)
				dispatch(removePatientNewRxData())
				dispatch(fetchPatientNewRxData(params))
				setApiCallMade(true)
			}
		}
		return () => { }
	}, [])

	const handleRadioChange = (e, index, option, patient_name) => {
		const newOptions = [...counsellingOption];
		newOptions[index] = e.target.value;
		setDefaulValue(e.target.value)
		setCounsellingOption(newOptions);
		setShowModel(true)
		
		setPatientData(option)
		setPatientName(`${patient_name?.first_name} ${patient_name?.last_name}`)
	};

	const updateRXOptions = () => {
		// const rxDataIDS = patientData?.patients_new_rx_data?.map((value) => value.id)

		const requestedData = {
			pharmacy_id: pharmacyId,
			rx_data_id: patientData?.id,
			counselling: defaultValue,
			setShowToast: setShowToast,
			dispatch: dispatch,
			setLoading: setLoading
		}
		setShowToast(prevState => ({ ...prevState, sucessToast: true }))
		setTimeout(() => {
			setShowToast(prevState => ({ ...prevState, sucessToast: false }))
		}, 2000);

		dispatch(UpdateNewRXDataOption(requestedData))
		setShowModel(false)
	}

	return (
		<>
			{pharmacyId ? <>
				<div className="app-wrapper flex justify-center">
					<div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden">
						<div className='flex items-center justify-center'>
							<SOSemergencyEnableButton />
						</div>

						<div className="relative p-4" >
							<div className="card bg-white p-4 rounded-lg shadow-sm">

								<div className="flex items-center justify-between gap-3">
									<div>
										<Common.TopBar pharmacyId={pharmacyId} />
									</div>

									<div className='flex items-center justify-center btnnn pt-4' onClick={() => navigate(`${routeNames.DASHBOARD_PHARMACY}`)} >
										<Icons.HomeIcon />
									</div>
								</div>
							</div>

							<div className="title-row my-2" >
								<h1 className="text-xl font-bold">Counselling</h1>
							</div>

							<div className="flex gap-3 mb-3">
								<SearchBox pharmacyId={pharmacyId} />
							</div>

							<div className="routes-list">
								{patientNewRxDataResponse?.loading && loading ?
									<div className='flex justify-center h-96'>
										<Loading.LoadingView />
									</div> :

									patientNewRxDataResponse?.error !== null ?
										<div className='flex  justify-center pb-4 mb-4 h-96'>
											<ErrorMessages error={'Oops! Got an issue while fetching Data.Please try again!'} />
										</div> :

										(patientNewRxData?.length === 0 && (!loading && !patientNewRxDataResponse?.loading)) ?
											<p className='flex  justify-center pb-4 pt-3 h-96'>No Data Available.</p>
											:
											<>
												{patientNewRxData?.map((data, index) => (
													<React.Fragment key={index}>
														{data?.patients_new_rx_data?.length === 0 || data?.patients_new_rx_data === null ? null :

															<div className="card bg-white p-4 rounded-lg shadow-sm mb-3" id={`patientCard-${index}`}>
																<PatientDetails index={index} data={data} totalRxData={data?.patients_new_rx_data?.length} />
																<div className="flex items-center justify-between mt-4">
																	<PatientMobileAndLocation data={data} />
																	<button className="p-2.5 rounded-md bg-gray-100" onClick={() => handleDropdownToggle(index, data)}>
																		{openIndex === index ?
																			<Icons.DropdownBoxIcon2 /> :
																			<Icons.DropdownBoxIcon1 />}
																	</button>
																</div>

																{openIndex === index &&
																	<>
																		<CounsellingNewRxData rxData={data?.patients_new_rx_data}
																			counsellingOption={counsellingOption}
																			handleRadioChange={handleRadioChange}
																			patient_name={data}
																			// setCounsellingOption={setCounsellingOption}
																			patientData={patientData}
																		/>
																	</>}
															</div>}
													</React.Fragment>))}
											</>}
							</div>
						</div>
					</div>
				</div>

				{showToast.sucessToast && <SuccessMessage message={'Updation Successfull'} setShowToast={() => setShowToast(prevState => ({ ...prevState, sucessToast: false }))} />}

				{showToast.errorToast && <ErrorMessage message={'Oops Got an issue while updating.please try again '}
					setShowToast={() => setShowToast(prevState => ({ ...prevState, errorToast: false }))} />}


				{showModel &&
					<Sheet isOpen={showModel} onClose={() => setShowModel(false)}>
						<Sheet.Container>
							<Sheet.Content>
								<div className="flex items-center justify-between p-2 px-4 md:p-3 border-b border-dashed rounded-t">
									<h3 className="text-sm font-bold text-gray-900">
										{/* {patientData?.first_name} {patientData?.last_name} */}
										{PatientName}
									</h3>

									<button type="button"
										onClick={() => { return setShowModel(false), setCounsellingOption('') }}
										className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
										data-modal-hide="bottom-right-modal">
										<Icons.CloseModelIcon />
										<span className="sr-only">Close modal</span>
									</button>
								</div>

								<div className="p-4 md:p-5 space-y-4">
									<form>
										<h1 className='text-md font-semibold flex items-center justify-center'>Do you want to proceed ?</h1>

										<div className="flex justify-center items-center gap-2 mt-2">
											<button type="button" onClick={updateRXOptions} className="bg-red-600 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded focus:outline-none 
																	focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">
												Yes
											</button>
											<button onClick={() => { return setCounsellingOption(''), setShowModel(false) }} type="button" className="bg-gray-300 hover:bg-gray-400 text-gray-800 
											font-semibold px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50">
												Cancel
											</button>
										</div>

									</form>
								</div>

							</Sheet.Content>
						</Sheet.Container>
						<Sheet.Backdrop />
					</Sheet>}

			</>
				: <PageNotFound />}
		</>
	)
}

export default Counselling
