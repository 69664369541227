import { createSlice } from "@reduxjs/toolkit";
import { fetchTodayMissed } from "../../apis/dispense/todayMissed";

const initialState = {
	loading: false,
	data: null,
	error: null
}

const todayMissedListSlice = createSlice({
	name: 'fetchTodayMissed',
	initialState,

	extraReducers: (builder) => {
		builder.addCase(fetchTodayMissed.pending.type, (state) => {
			return {
				...state,
				loading: true,
				// data: null,
				error: null
			}
		})

		builder.addCase(fetchTodayMissed.fulfilled.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			}
		})

		builder.addCase(fetchTodayMissed.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			}
		})
	}

})

export default todayMissedListSlice.reducer