import { createAsyncThunk } from "@reduxjs/toolkit";
import { patient_without_pharmacist_sign } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const fetchPatientListWithoutPharmacistSign = createAsyncThunk('fetchPatientListWithoutPharmacistSign', async (params, { rejectWithValue }) => {
    try {

        const response = await Axios.get(`${patient_without_pharmacist_sign}?pharmacy_id=${params.pharmacy_id}&page=${params.page}`)

        if (typeof params.setLoading === 'function') {
        	params.setLoading(false)
        }

        if (typeof params.setLoadMore === 'function') {
        	params.setLoadMore(false)
        }


        const data = response.data
        return data;

    } catch (error) {

        if (typeof params.setLoading === 'function') {
        	params.setLoading(false)
        }

        if (typeof params.setLoadMore === 'function') {
        	params.setLoadMore(false)
        }


        return rejectWithValue(error.response)
    }
});