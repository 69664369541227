import { createAsyncThunk } from "@reduxjs/toolkit";
import { patient_prescription } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const UploadPatientPrescripition = createAsyncThunk('UploadPatientPrescripition', async (requestedData, { rejectWithValue }) => {
	try {

		const formData = new FormData();

		formData.append('pharmacy_id', requestedData.pharmacy_id);

		requestedData.prescription?.forEach((file, index) => {
			formData.append(`prescription[${index}]`, file);
		});

		const response = await Axios.post(`${patient_prescription}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				"Accept": "multipart/form-data",
			}
		})

		const data = response.data

		// if (typeof requestedData.setShowToast === 'function') {
		// 	requestedData.setShowToast(prevState => ({ ...prevState, successToast: true }))

		// 	setTimeout(() => {
		// 		requestedData.setShowToast(prevState => ({ ...prevState, successToast: false }))
		// 	}, 2000);
		// 	requestedData.setSelectedImages([])
		// }
		return data;

	} catch (error) {
		if (typeof requestedData.setShowToast === 'function') {
			requestedData.setShowToast(prevState => ({ ...prevState, errorToast: true }))

			setTimeout(() => {
				requestedData.setShowToast(prevState => ({ ...prevState, errorToast: false }))
			}, 2000);
		}

		return rejectWithValue(error.response)
	}
});