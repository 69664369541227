import Axios from "../../../networking/interceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_SOS_emergency } from "../../../networking/urlEndPoints";


export const SOS_EmergencyEnabled = createAsyncThunk('SOS_EmergencyEnabled', async (requesteData, { rejectWithValue }) => {
	try {
		const uploadData = {
			pharmacy_id: requesteData.pharmacy_id,
			latitude: requesteData.latitude,
			longitude: requesteData.longitude
		}

		const response = await Axios.post(driver_SOS_emergency, uploadData)
		const data = response.data;

		return data;

	} catch (error) {

		if (typeof requesteData.setShowToast === 'function') {
			requesteData.setShowToast(prevState => ({ ...prevState, ApiError: true }));

			setTimeout(() => {
				requesteData.setShowToast(prevState => ({ ...prevState, ApiError: false }))
			}, 3000);
		}

		return rejectWithValue(error.response)
	}

});