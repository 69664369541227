import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_patient_search, search_patient } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const fetchPatientSearchList = createAsyncThunk('fetchPatientSearchList', async (params, { rejectWithValue }) => {
	try {
		const response = await Axios.get(driver_patient_search, { params })
		const data = response.data
		return data;

	} catch (error) {
		return rejectWithValue(error.response)
	}
});


export const fetchPatientConsentAutoFillNamesData = createAsyncThunk('fetchPatientConsentAutoFillNamesData', async (params, { rejectWithValue }) => {
	try {
		const url = search_patient + params
		const response = await Axios.get(url)
		const data = response.data
		return data;

	} catch (error) {
		return rejectWithValue(error.response)
	}
});