import React, { useState } from 'react'

import { useAuthSelector } from '../../services/selectors/authSelector';
import { SOSemergencyEnableButton } from '../../components/common/SOSemergencyEnableButton';

import { useAllSelector } from '../../services/selectors/allSelector';
import { UploadPatientPrescripition } from '../../services/apis/pharmacy/uploadPatientPrescriptions';

import {
	ErrorMessage,
	SuccessMessage
} from '../../components/common/AllConfirmationMessages';

import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';
import * as Hooks from '../../hooks';
import * as routeNames from '../../routes/routeName';

const UploadPrescripition = () => {

	const { pharmacyId } = useAuthSelector()
	const { UploadPrescripitionResponse } = useAllSelector()

	const dispatch = Hooks.useCustomDispatch();
	const navigate = Hooks.useCustomNavigate();

	const [selectedImages, setSelectedImages] = useState([]);
	const [showToast, setShowToast] = useState({ successToast: false, errorToast: false })

	const handleFileChange = (e) => {
		
		const files = Array.from(e.target.files);

		const imageFiles = files?.filter(file => file.type.startsWith('image/'));
		setSelectedImages(prevFiles => [...prevFiles, ...imageFiles]);
	};

	const handleRemoveImg = (i) => {
		const delImg = selectedImages.filter((value, index) => index !== i)
		setSelectedImages(delImg)
	}

	const handleUploadPrescription = () => {
		if (selectedImages?.length > 0) {
			const requestedData = {
				pharmacy_id: pharmacyId,
				prescription: selectedImages,
				setShowToast: setShowToast,
				setSelectedImages: setSelectedImages
			}

			setShowToast(prevState => ({ ...prevState, successToast: true }))

			setTimeout(() => {
				setShowToast(prevState => ({ ...prevState, successToast: false }))
			}, 2000);

			dispatch(UploadPatientPrescripition(requestedData))
			setSelectedImages([])
		}
	}

	return (
		<React.Fragment>
			<div className="app-wrapper flex justify-center">
				<div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden h-screen">
					<div className='flex items-center justify-center'>
						<SOSemergencyEnableButton />
					</div>

					<div className="relative p-4">
						<div className="card bg-white p-4 rounded-lg shadow-sm">
							<div className="flex items-center justify-between gap-3">
								<div>
									<Common.TopBar pharmacyId={pharmacyId} />
								</div>

								<div className='flex items-center justify-center btnnn pt-4'
									onClick={() => { return navigate(`${routeNames.DASHBOARD_PHARMACY}`) }}>
									<Icons.HomeIcon />
								</div>
							</div>
						</div>

						<div className="title-row my-2" >
							<h1 className="text-xl font-bold">Upload Prescription</h1>
						</div>


						<div className="mt-2 rounded-md mb-3">

							<div className="mt-3">
								<div className="w-full relative flex items-center justify-center rounded-md">

									<div className="w-full">
										<label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-54 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
											<div className="flex flex-col items-center justify-center pt-5 pb-6">
												<svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
													<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
												</svg>
												<p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span></p>
												<p className="text-xs text-gray-500 dark:text-gray-400"></p>
											</div>
											<input id="dropzone-file" type="file" accept="image/jpeg, image/jpg, image/png,capture=camera" className="hidden" onChange={handleFileChange}
												multiple />
										</label>
									</div>
								</div>
							</div>


							<div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-2">
								{selectedImages?.map((imageUrl, index) => (
									<div key={index} className='w-full p-2 bg-gray-200 rounded-md flex gap-2 jutsift-center items-center'>
										<img
											src={URL?.createObjectURL(imageUrl)}
											alt={`all-${index + 1}`}
											className="w-14 h-14 object-cover rounded-lg shadow-lg"
										/>
										<span className='text-sm text-gray-500 break-all'>{imageUrl?.name}</span>

										<button type='button'
											onClick={() => handleRemoveImg(index)}
											className="text-xs text-red-800 mt-3 ml-auto top-0 right-2 bg-red-50 rounded-md p-1 flex items-center gap-2 uppercase">
											X
										</button>
									</div>
								))}

								{selectedImages?.length > 0 &&
									<button type="button" onClick={UploadPrescripitionResponse?.loading ? null : handleUploadPrescription}
										className={`text-white w-full bg-blue-800 flex items-center justify-center  font-bold rounded-lg text-sm px-5 py-3 
															me-2 mb-2 group-invalid:pointer-events-none group-invalid:opacity-30`}>
										Submit
									</button>
								}

							</div>
						</div>
					</div>
				</div>
			</div>

			{showToast.successToast &&
				<SuccessMessage message={'Prescription Uploaded Successfully.'} setShowToast={() => setShowToast(prevState => ({ ...prevState, successToast: true }))} />}

			{showToast.errorToast &&
				<ErrorMessage message={'Oops! Got an issue while uploading prescription. Please try to upload again.'} setShowToast={() => setShowToast(prevState => ({ ...prevState, errorToast: true }))} />
			}

		</React.Fragment>
	)
}

export default UploadPrescripition