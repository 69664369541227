import { createAsyncThunk } from "@reduxjs/toolkit";
import {  driver_get_patients } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const fetchPatientlistOnBasisOfRoute = createAsyncThunk('fetchPatientDataOnBasisOfRoute', async (params, { rejectWithValue }) => {
	
	try {
		const response = await Axios.get(driver_get_patients, {
			params: {
				route: params.route,
				pharmacy_id: params.pharmacy_id,
				page: params.page
			}
		})
		const data = response.data
		if (typeof params.setLoading === 'function') {
			params.setLoading(false)
		}
		if (typeof params.setLoadMore === 'function') {
			params.setLoadMore(false)
		}
		return data;

	} catch (error) {
		if (typeof params.setLoading === 'function') {
			params.setLoading(false)
		}
		if (typeof params.setLoadMore === 'function') {
			params.setLoadMore(false)
		}
		return rejectWithValue(error.response)
	}
});
