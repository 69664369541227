export const authLogin = '79D*zW@';
export const switchPharmacy = 'IOP5n9#w<4k9';
export const routeList = '*R+bX-7T35BZ';

export const pharmacistOfPharmacyList = '#wre@12&';
export const driverDeliveredRouteList = 'u&T8Ud50Z*A2'

export const todayMissedList = '20U1P|2"IlWj'
export const patientDeliveredDataBasisOfRoute = '6HR17~mf%pDW'
export const patientDataBasisOfRoute = '$]6MZr04x&9'
export const pharmacyDetail = '_#9*hDOsd'
