import React from 'react';
import { Datepicker } from 'flowbite-react';

export const DatePickers = ({ selectedDate, setSelectedDate }) => {

    const handleDatePickerChange = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

        setSelectedDate(formattedDate);
    };

    return (
        <>
            <div >
                <Datepicker
                    value={selectedDate}
                    onSelectedDateChanged={handleDatePickerChange}
                    id='dob'
                    style={{background:'wjite'}}
                    name='dob'
                    className="focus:ring-blue-500 bg-white-50 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={"DOB"}
                    maxDate={new Date()}
                />
            </div>
        </>
    );
};
