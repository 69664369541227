import { useSelector } from "react-redux";
import * as EncryptedKey from '../constants/encryptedRootReducerKeys';

export const useAllSelector = () => {

	const switchPharmacyResponse = useSelector((state) => state[EncryptedKey.switchPharmacy]);
	const patientDataBasisOfRouteResponse = useSelector((state) => state[EncryptedKey.patientDataBasisOfRoute]);

	const patientDeliveredDataBasisOfRouteResponse = useSelector((state) => state[EncryptedKey.patientDeliveredDataBasisOfRoute]);
	const pharmacistOfPharmacyListResponse = useSelector((state) => state[EncryptedKey.pharmacistOfPharmacyList]);
	const todayMissedListResponse = useSelector((state) => state[EncryptedKey.todayMissedList])
	const driverDeliveredRouteListResponse = useSelector((state) => state[EncryptedKey.driverDeliveredRouteList]);

	const patientSearchListResponse = useSelector((state) => state.patientSearchList);
	const changePasswordResponse = useSelector((state) => state.changePassword);
	const changePasscodeResponse = useSelector((state) => state.changePasscode);
	const driverLogoutResponse = useSelector((state) => state.driverLogout);

	const patientDeliveryRxListResponse = useSelector((state) => state.patientDeliveryRxList);
	const patientPendingListResponse = useSelector((state) => state.patientPendingList);
	const confirmDeliveryResponse = useSelector((state) => state.confirmDelivery);

	const updateProfileResponse = useSelector((state) => state.updateProfile);
	const finalMissedListResponse = useSelector((state) => state.finalMissedList);
	const updateDeliveryListResponse = useSelector((state) => state.updateDeliveryList);

	const patientConsentResponse = useSelector((state) => state.patientConsent);
	const allPharmacyListResponse = useSelector((state) => state.allPharmacyList);

	const pharmacistDetail = pharmacistOfPharmacyListResponse?.data?.data?.pharmacist
	const routeListResponse = pharmacistOfPharmacyListResponse?.data?.data?.route
	const supervisor_pharmacist = pharmacistOfPharmacyListResponse?.data?.data?.supervisor_pharmacist

	const pharmacyDetail = switchPharmacyResponse?.data?.data
	const todayMissedList = todayMissedListResponse?.data?.data

	const deliveredRoutes = driverDeliveredRouteListResponse?.data?.data || [];
	const todaysDispenseList = patientDataBasisOfRouteResponse?.data

	const deliveredCount = pharmacistOfPharmacyListResponse?.data?.data?.delivered_count
	const finalMissedCount = pharmacistOfPharmacyListResponse?.data?.data?.final_missed_count

	const patientRxData = patientDeliveryRxListResponse?.data?.data?.patient

	const finalMissedList = finalMissedListResponse?.data?.data

	const todaysAnalyticsResponse = useSelector(state => state?.todaysAnalytics)

	const UploadPrescripitionResponse = useSelector(state => state.UploadPrescripition)
	const updatePatientProfileResponse = useSelector(state => state.updatePatientProfile)

	const validateReturnedPatchesResponse = useSelector(state => state.validateReturnedPatches)

	const UploadedTriplicatesPatientSignResponse = useSelector(state => state.UploadedTriplicatesPatientSign)
	const GenrateRxLinkResponse = useSelector(state => state.GenrateRxLink)

	return {
		switchPharmacyResponse,
		changePasswordResponse,
		changePasscodeResponse,

		driverLogoutResponse,
		routeListResponse,
		patientDataBasisOfRouteResponse,
		patientDeliveredDataBasisOfRouteResponse,

		patientSearchListResponse,
		pharmacistOfPharmacyListResponse,
		patientDeliveryRxListResponse,
		supervisor_pharmacist,

		patientPendingListResponse,
		confirmDeliveryResponse,
		updateProfileResponse,

		finalMissedListResponse,
		todayMissedListResponse,
		updateDeliveryListResponse,

		driverDeliveredRouteListResponse,
		patientConsentResponse,
		allPharmacyListResponse,

		pharmacyDetail,
		pharmacistDetail,
		todayMissedList,
		deliveredRoutes,
		todaysDispenseList,

		deliveredCount,
		finalMissedCount,
		patientRxData,
		finalMissedList,
		todaysAnalyticsResponse,
		UploadPrescripitionResponse,
		updatePatientProfileResponse,
		validateReturnedPatchesResponse,
		UploadedTriplicatesPatientSignResponse,
		GenrateRxLinkResponse
	}
}