import React, { useEffect } from 'react';

import {
	Loader,
	LoadingView
} from '../../components/common/loader';

import {
	DeliverSuccessfull,
	DeliveryErrorMessages,
} from '../../components/common/AllConfirmationMessages';

import {
	apiKey,
	deliveryDriverDeliveredDispense
} from '../../networking/urlEndPoints';

import {
	PatientDetails,
	PatientMobileAndLocation,
} from '../../components/common/patientDetails';

import { ErrorMessages } from '../../components/error/errorMessages';
import { fetchTodayMissed } from '../../services/apis/dispense/todayMissed';

import { useAllSelector } from '../../services/selectors/allSelector';
import { DispenseRxData } from '../../components/common/dispenseRxData';
import { handleToggleRXData } from '../../utils/helper/helper';
import { useCustomStates } from '../../hooks/useCustomStates';

import { useAuthSelector } from '../../services/selectors/authSelector';
import { SOSemergencyEnableButton } from '../../components/common/SOSemergencyEnableButton';
import { RemoveAllPatchesId } from '../../services/slices/dispense/validateReturnPatches';
import { FinalDeliveredByAnotherDriverAblyHelper } from '../../utils/helper/ablyDeliveredDispenseByOthers';
import { handleMissedConfirmDelivery } from '../../utils/helper/deliveriesHelper';

import SearchBox from '../../components/common/searchBox';

import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';
import * as Hooks from '../../hooks';
import * as routeNames from '../../routes/routeName';


const FinalMissedDelivery = () => {

	const dispatch = Hooks.useCustomDispatch()
	const navigate = Hooks.useCustomNavigate()
	const location = Hooks.useCustomLocation()

	const { pharmacyId, pharmacyInspectionMode } = useAuthSelector()
	const { confirmDeliveryResponse, todayMissedListResponse, validateReturnedPatchesResponse, todayMissedList } = useAllSelector();

	const {
		comment,
		setComment,

		cordinate,
		setCordinate,

		openIndex,
		setOpenIndex,

		patientSignature,
		setPatientSignature,

		pharmacistSignature,
		setPharmacistSignature,

		showErrorToast,
		setShowErrorToast,

		showSuccessToast,
		setShowSuccessToast,

		showComment,
		setShowComment,

		showPatientSignature,
		setShowPatientSignature,

		showData,
		setShowData,

		showPharmacistSignature,
		setShowPharmacistSignature,

		isRefused,
		setIsRefused,

		signatureErrors,
		setSignatureErrors,

		covid19,
		setCovid19,
		setPId,

		loading,
		setLoading,

		isTransferRequest,
		setIsTransferRequest

	} = useCustomStates()

	const handleDropdownToggle = (index, data) => {
		handleToggleRXData(
			index,
			data,
			openIndex,
			setOpenIndex,
			dispatch,
			setSignatureErrors,
			setShowData,
			RemoveAllPatchesId,
			setPatientSignature,
			setShowComment,
			setShowPatientSignature,
			setShowPharmacistSignature,
			setPharmacistSignature,
			setComment,
			setPId,
			pharmacyId
		);
	};

	useEffect(() => {
		if (pharmacyId) {
			const params = {
				pharmacy_id: pharmacyId,
				status: 'final_missed',
			};
			setLoading(true)
			dispatch(fetchTodayMissed(params))
		}
		return () => { };
	}, []);


	const handleConfirmDelivery = (patientData) => {

		let filteredData = patientData?.patients_win_rx_data_final_missed?.filter(item => item.is_din_matched.is_fentanyl === 1);
		const hasRxWitness = patientData?.patients_win_rx_data_final_missed?.some(patient => patient.rx_witness === "Y");

		handleMissedConfirmDelivery({
			patientData,
			pharmacyId: pharmacyId,
			selectedPharmacistID: null,
			patientSignature: patientSignature,
			pharmacistSignature: pharmacistSignature,
			cordinate: cordinate,
			comment: comment,
			dispatch,
			setOpenIndex: setOpenIndex,
			setShowErrorToast: setShowErrorToast,
			setShowSuccessToast: setShowSuccessToast,
			isRefused: isRefused,
			covid19: covid19,
			validateReturnedPatchesResponse: validateReturnedPatchesResponse,
			isTransferRequest: isTransferRequest,
			setIsTransferRequest: setIsTransferRequest,
			pharmacyInspectionMode: pharmacyInspectionMode,
			setSignatureErrors: setSignatureErrors,
			setIsRefused: setIsRefused,

			fetchAction: 'final_missed',
			filteredData: filteredData,
			hasRxWitness: hasRxWitness
		});
	};

	return (
		<>
			<div className="app-wrapper flex justify-center">
				<div x-data="{toastShow:true}"
					className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden ">

					<div className='flex items-center justify-center'>
						<SOSemergencyEnableButton />
					</div>

					<div className="relative p-4" >
						<div className="card bg-white p-4 rounded-lg shadow-sm">
							<div className="flex items-center justify-between gap-3">
								<div>
									<Common.TopBar pharmacyId={pharmacyId} />
								</div>

								<div className='flex items-center justify-center btnnn pt-4' onClick={() => navigate(`${routeNames.DASHBOARD_PHARMACY}`)} >
									<Icons.HomeIcon />
								</div>
							</div>
						</div>
						<div className="title-row my-2" >
							<h1 className="text-xl font-bold">Missed Dispense</h1>
						</div>

						<div className="flex gap-3 mb-3">
							<SearchBox pharmacyId={pharmacyId} />
						</div>

						<div className="routes-list">
							{(todayMissedListResponse?.loading && loading) ?
								<div className='h-96 flex justify-center'>
									<LoadingView /> </div> :
								todayMissedListResponse?.error !== null ?
									<div className='flex items-center justify-center pb-4 mb-4 h-96'>
										<ErrorMessages error={'Oops! Got an issue while fetching Data.Please try again!'} />
									</div> :
									todayMissedList?.length === 0 ?
										<p className='flex  justify-center pb-4 pt-3 h-96'>No more deliveries for today.</p>
										:
										todayMissedList?.map((data, index) => (
											<React.Fragment key={index}>
												<div className="card bg-white p-4 rounded-lg shadow-sm mb-3" id={`patientCard-${index}`}>
													<PatientDetails data={data} totalRxData={data?.patients_win_rx_data_final_missed?.length} patientImg={data.profile_image} index={index} />

													<div className="flex items-center justify-between mt-4">
														<PatientMobileAndLocation data={data}
															setIsTransferRequest={setIsTransferRequest}
															isTransferRequest={isTransferRequest}
															index={index}
														/>

														<button className="p-2.5 rounded-md bg-gray-100" onClick={() => handleDropdownToggle(index, data)}>
															{openIndex === index ?
																<Icons.DropdownBoxIcon2 /> :
																<Icons.DropdownBoxIcon1 />}
														</button>
													</div>

													{openIndex === index &&
														<>

															<DispenseRxData rxData={data?.patients_win_rx_data_final_missed} patientData={data} />

															{showData &&
																<React.Fragment>

																	<Common.Comment setComment={setComment}
																		comment={comment}
																		showComment={showComment}
																		setShowComment={setShowComment} />

																	<Common.PatientSignature patientSignature={patientSignature}
																		setPatientSignature={setPatientSignature}
																		showPatientSignature={showPatientSignature}
																		setShowPatientSignature={setShowPatientSignature}
																		setIsRefused={setIsRefused}
																		isRefused={isRefused}
																		signatureErrors={signatureErrors}
																		setSignatureErrors={setSignatureErrors}
																		covid19={covid19}
																		setCovid19={setCovid19}
																	/>

																	<Common.PharmacistSignature
																		pharmacistSignature={pharmacistSignature}
																		setPharmacistSignature={setPharmacistSignature}
																		showPharmacistSignature={showPharmacistSignature}
																		setShowPharmacistSignature={setShowPharmacistSignature}
																		signatureErrors={signatureErrors}
																		setSignatureErrors={setSignatureErrors}
																	/>

																</React.Fragment>
															}


															<div className="flex items-center gap-3">
																{showData ?
																	validateReturnedPatchesResponse?.loading ? null :
																		<button className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md"
																			onClick={() => handleConfirmDelivery(data)}>
																			{confirmDeliveryResponse?.loading ? <p className='flex justify-center items-center'><Loader /></p> : '  Continue'}

																		</button>
																	:
																	<button className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md"
																		onClick={() => {
																			setShowData(true);
																			setTimeout(() => {
																				const windowHeight = window.innerHeight;
																				const scrollAmount = windowHeight / 2;
																				window.scrollBy({ top: scrollAmount, behavior: "smooth" });
																			}, 100);
																		}}>
																		Deliver</button>

																}
															</div>
														</>
													}
												</div>
											</React.Fragment>))}
						</div>
					</div>

					{showErrorToast && <DeliveryErrorMessages setShowErrorToast={setShowErrorToast} />}
					{showSuccessToast && <DeliverSuccessfull setShowSuccessToast={setShowSuccessToast} />}

				</div>
			</div>

			<FinalDeliveredByAnotherDriverAblyHelper
				apiKey={apiKey}
				deliveryDriverDeliveredDispense={deliveryDriverDeliveredDispense}
				pharmacyId={pharmacyId}
				dispatch={dispatch}
				setCordinate={setCordinate}
				location={location}
				setLoading={setLoading}
			/>
		</>
	)
}

export default FinalMissedDelivery
