import React from 'react';

import * as Common from '../../components/common';

const PageNotFound = () => {

    return (

        <React.Fragment>
            <div className="container mx-auto px-4 flex items-center justify-center h-screen">
                <div className="rounded-lg w-full max-w-md m-auto">
                    <div className="py-4 px-6 mb-5">
                        <h5 className="flex items-center justify-center font-semibold text-5xl text-red-700">
                            404 </h5>
                        <p className='flex py-2 items-center justify-center font-semibold text-3xl text-gray-700' >Page Not Found</p>
                        <div className='flex items-center justify-center pl-1 gap-4'>
                            <Common.HomeButton />
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment>
    )
}

export default PageNotFound