import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_get_pharmacist } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const fetchPharmacistOfPharmacy = createAsyncThunk('fetchPharmacistOfPharmacy', async (pharmacyId, { rejectWithValue }) => {
    try {
        const params = {
            pharmacy_id: pharmacyId
        }

        const response = await Axios.get(driver_get_pharmacist, { params })
        const data = response.data
        return data;

    } catch (error) {
        return rejectWithValue(error.response)
    }
});