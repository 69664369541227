import Axios from "../../../networking/interceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_update_delivery_status } from "../../../networking/urlEndPoints";

import { fetchTodayMissed } from "./todayMissed";
import { removeTodayDispenePatientById } from "../../slices/dispense/patientForRouteList";
import { fetchPatientPendingList } from "./patientPendingList";

export const updateDeliveryStatus = createAsyncThunk('updateDeliveryStatus', async (requesteData, { rejectWithValue }) => {
	try {
		const datas = {
			pharmacy_id: requesteData.pharmacy_id,
		}

		const response = await Axios.post(`${driver_update_delivery_status}/${requesteData?.patient_id}`, datas);
		const data = response.data;

		if (response?.status === 200) {
			if (requesteData?.type === 'today_missed') {
				if (requesteData?.pharmacy_id) {
					const params = {
						pharmacy_id: requesteData.pharmacy_id,
						status: 'today_missed',
					};
					requesteData.dispatch(fetchTodayMissed(params))
				}
			}

			else if (requesteData.params.pharmacy_id && requesteData.params.route) {
				// requesteData.dispatch(fetchPatientlistOnBasisOfRoute(requesteData.params))
				requesteData.dispatch(removeTodayDispenePatientById(requesteData?.patient_id))
				requesteData.dispatch(fetchTodayMissed(requesteData.params))
			}
		}

		if (typeof requesteData.setOpenIndex === 'function') {
			requesteData.setOpenIndex(null)
		}

		if (typeof requesteData.setMissedDeliveryConfirmation === "function") {
			requesteData.setMissedDeliveryConfirmation(false)
		}

		return data;

	} catch (error) {
		if (typeof requesteData.setMissedDeliveryErrorToast === 'function') {

			if (typeof requesteData.setMissedDeliveryConfirmation === "function") {
				requesteData.setMissedDeliveryConfirmation(false)
			}

			if (typeof requesteData.setMissedDeliveryErrorToast === "function") {
				requesteData.setMissedDeliveryErrorToast(false)
				setTimeout(() => {
					requesteData.setMissedDeliveryErrorToast(false)
				}, 2000);
			}
		}

		if (requesteData.type === 'Pending_dispense') {
			requesteData.dispatch(fetchPatientPendingList({
				page: 1,
				pharmacy_id: requesteData.pharmacy_id,
				// setLoading: setLoading
			}))
		}
		return rejectWithValue(error.response)
	}
});