import { createSlice } from "@reduxjs/toolkit";
import { fetchPatientPendingList } from "../../apis/dispense/patientPendingList";

const initialState = {
	loading: false,
	data: [],
	error: null,
	currentPage: 1,
	totalPages: null
}

const patientPendingListSlice = createSlice({
	name: 'patientPendingList',
	initialState,

	reducers: {
		removePatientById(state, action) {
			const patientIdToRemove = action.payload;
			state.data = state.data.filter(item => item.id !== patientIdToRemove);
		},
		clearData(state) {
			state.data = []
		},
		pendingDispenseRemovingPageNumber(state) {
			state.currentPage = 1
		}
	},

	extraReducers: (builder) => {
		builder.addCase(fetchPatientPendingList.pending.type, (state) => {
			return {
				...state,
				loading: true,
				error: null,
				// data: []
			}
		})
		builder.addCase(fetchPatientPendingList.fulfilled.type, (state, action) => {
			const existingIds = new Set(state?.data?.map(item => item.id));
			const newData = action?.payload?.data?.filter(item => !existingIds.has(item.id));

			return {
				...state,
				loading: false,
				data: [...state.data, ...newData],
				currentPage: action.payload.meta.current_page,
				totalPages: action.payload.meta.last_page,
				error: null
			};
		});

		builder.addCase(fetchPatientPendingList.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				error: action.payload,
				data: []
			}
		})
		builder.addCase(patientPendingListSlice.actions.removePatientById, (state, action) => {
			const patientIdToRemove = action.payload;
			state.data = state.data.filter(item => item.patient_id !== patientIdToRemove);
		});
	}

})

export const { removePatientById, clearData, pendingDispenseRemovingPageNumber } = patientPendingListSlice.actions;

export default patientPendingListSlice.reducer;