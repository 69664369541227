import React, { useState, useEffect } from 'react'

import { SuccessMessage } from './AllConfirmationMessages';
import { useCustomDispatch, useCustomLocation } from '../../hooks';
import { updatePatientProfile } from '../../services/apis/setting/updatePatientProfile';
import { useAuthSelector } from '../../services/selectors/authSelector';

import avatar from '../../assets/images/userimg.png';
import * as Icons from '../../assets/svg/icons'
import * as routeName from '../../routes/routeName'

import mapPic from '../../assets/images/google-maps.svg'
import phoneCallPic from '../../assets/images/phone-call.svg'
import contract from '../../assets/images/agreement.svg'


export const displaySelectedImage = (event, setSelectedImage, index, setShowToast, data, dispatch) => {

	if (event.target.files.length > 0) {
		const file = event.target.files[0];

		const requestedData = {
			pharmacy_id: data.pharmacy_id,
			patient_id: data.id,
			profile_image: file
		}
		dispatch(updatePatientProfile(requestedData))

		if (file.type.startsWith('image/')) {
			const reader = new FileReader();

			reader.onload = function (e) {
				setSelectedImage(e.target.result, index);
				setShowToast(prevState => ({ ...prevState, successToast: true }))

				setTimeout(() => {
					setShowToast(prevState => ({ ...prevState, successToast: false }))

				}, 2000);
			};

			reader.readAsDataURL(file);
		}
	}
}

export const PatientDetails = ({ data, totalRxData, index, patientImg }) => {

	const dispatch = useCustomDispatch()
	const location = useCustomLocation()

	const [selectedImage, setSelectedImage] = useState(patientImg || avatar)
	const [showToast, setShowToast] = useState({ successToast: '' })

	useEffect(() => {
		if (data?.profile_image != selectedImage) {
			setSelectedImage(avatar)
		}
	}, [selectedImage, data])

	return (
		<React.Fragment>
			<div className="flex items-center gap-2">
				<h2 className="text-lg font-medium uppercase flex justify-center items-center">
					<img src={selectedImage} alt='avtar' className='rounded-full w-8 h-8 mr-2'
						onClick={() => {
							const filePicker = document.getElementById(`selectProfileImage-${index}`);
							filePicker.click()
						}}
					/>
					{data?.first_name} {data?.last_name}</h2>
				<span className="flex items-center justify-center h-5 w-5 text-sm rounded-full bg-green-600 text-white">
					{totalRxData}</span>
				<input name='avatar' accept="image/jpeg, image/jpg, image/png,capture=camera"
					onChange={(event) => displaySelectedImage(event, setSelectedImage, index, setShowToast, data, dispatch)} type='file' id={`selectProfileImage-${index}`} className='hidden' />

			</div>

			{!data?.phone ? <p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>PHN: {data?.phn}
				{data?.dob && <> | DOB: {data?.dob} </>} {data?.gender && <> | Gender: {data?.gender}</>}
			</p>
				: <>
					<p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>PHN: {data?.phn}
						{data.phone && <> | PH: {data?.phone}  </>} {data?.dob && <> | DOB: {data?.dob} </>}
					</p>

					<p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>
						{data?.gender && <> Gender: {data?.gender}</>}
					</p>
				</>}

			{location.pathname === routeName.DRIVER_ROUTE_DELIVERED &&
				<p className='text-xs text-gray-400 my-1 break-all'>{data?.delivery_human_time}</p>}

			<div className="flex gap-2 mt-3">
				<Icons.LocationIcon />
				<p className="text-gray-500">{data?.full_address}</p>
			</div>

			{data?.delivey_note && <p className='mt-2 bg-fuchsia-200 p-1 pl-2 rounded-md' style={{ color: 'black', fontSize: '13px', fontWeight: 'bold', backgroundColor: 'rgb(132 204 22)' }}>
				<span className='text-red-700'>Note: </span>{data?.delivey_note}
			</p>}

			{showToast.successToast &&
				<label className='fixed -left-3 -right-0 bottom-0 z-50'>
					<SuccessMessage message={'Profile Updated Successfully.'} setShowToast={() => setShowToast(prevState => ({ ...prevState, successToast: false }))} />
				</label>
			}


		</React.Fragment >)
}

export const PatientMobileAndLocation = ({ data }) => {

	const { driverSelectedPharmacy } = useAuthSelector()
	const location = useCustomLocation()

	return (
		<React.Fragment>
			<div className="flex items-center gap-3">
				<button className="px-2 py-1.5 rounded-md">
					<a href={`tel:${data?.phone}`}>
						<img src={phoneCallPic} alt='phone-call-Pic' className='w-6 h-6' />
					</a>
				</button>
				<button className="px-1 py-1.5 rounded-md bg-opacity-10">
					<a target='_blank' href={data?.map_url}>
						<img src={mapPic} alt='mapPic' className='w-6 h-6' />
					</a>
				</button>

				{driverSelectedPharmacy?.pharmacies?.transfer_concent === 1 ?
					location.pathname === routeName.DRIVER_ROUTE_DELIVERED || location.pathname === routeName.pending_pharmacist_signatures || location.pathname === routeName.Counselling ? null :
						data?.pharmacy_transfer_concents === null ?
							null
							:
							<button className="px-1 py-1.5 rounded-md  bg-opacity-10">
								<a>
									<img src={contract} alt='mapPic' className='w-6 h-6' />
								</a>
							</button>
					: null
				}
			</div>
		</React.Fragment>
	)
}



