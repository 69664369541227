import { createSlice } from "@reduxjs/toolkit";
import { SOS_EmergencyEnabled } from "../../apis/auth/SOS_EmergencyEnabled";

const initialState = {
	loading: false,
	data: null,
	error: null
}

const SOS_EmergencyEnabledSlice = createSlice({
	name: 'SOS_EmergencyEnabled',
	initialState,

	extraReducers: (builder) => {
		builder.addCase(SOS_EmergencyEnabled.pending.type, (state) => {
			return {
				...state,
				loading: true,
				data: null,
				error: null
			}
		})

		builder.addCase(SOS_EmergencyEnabled.fulfilled.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			}
		})

		builder.addCase(SOS_EmergencyEnabled.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			}
		})
	}

})

export default SOS_EmergencyEnabledSlice.reducer