import { createSlice } from "@reduxjs/toolkit";
import { fetchPharmacyDetails } from "../../apis/dispense/getPharmacyDetail";

const initialState = {
	loading: false,
	data: null,
	error: null
}

const PharmacyDetailSlice = createSlice({
	name: 'fetchPharmacyDetails',
	initialState,

	extraReducers: (builder) => {
		builder.addCase(fetchPharmacyDetails.pending.type, (state) => {
			return {
				...state,
				loading: true,
				// data: null,
				error: null
			}
		})

		builder.addCase(fetchPharmacyDetails.fulfilled.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			}
		})

		builder.addCase(fetchPharmacyDetails.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			}
		})
	}

})

export default PharmacyDetailSlice.reducer