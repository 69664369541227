import { createSlice } from "@reduxjs/toolkit";
import { fetchPatientDeliveredList } from "../../apis/supervisor/deliveredDispense";

const initialState = {
	loading: false,
	data: [],
	error: null,
	currentPage: 1,
	totalPages: null
}

const patientDelivedListSlice = createSlice({
	name: 'fetchPatientDeliveredList',
	initialState,
	reducers: {
		clearDeliveredDispenseData(state) {
			state.data = []
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPatientDeliveredList.pending.type, (state) => {
			return {
				...state,
				loading: true,
				error: null,
				// data: []
			}
		})

		builder.addCase(fetchPatientDeliveredList.fulfilled.type, (state, action) => {
			const existingIds = new Set(state?.data.map(item => item.id))
			const newData = action?.payload?.data?.filter(item => !existingIds.has(item.id));

			return {
				...state,
				loading: false,
				data: [...state?.data, ...newData],
				currentPage: action.payload.meta.current_page,
				totalPages: action.payload.meta.last_page,
				error: null
			};
		});

		builder.addCase(fetchPatientDeliveredList.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				error: action.payload,
				data: []
			}
		})
	}

})
export const { clearDeliveredDispenseData } = patientDelivedListSlice.actions

export default patientDelivedListSlice.reducer