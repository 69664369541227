import React from 'react'
import { RotatingLines } from 'react-loader-spinner'
import animationData from '../../assets/lottie/oatrx-loader';
import Lottie from 'react-lottie';

export const Loader = () => {

	return (
		<RotatingLines
			strokeColor="white"
			strokeWidth="5"
			animationDuration="1.75"
			width="20"
			visible={true}
		/>
	)
}

export const ScrollLoader = () => {

	return (
		<RotatingLines
			strokeColor="black"
			strokeWidth="5"
			animationDuration="1.75"
			width="20"
			visible={true}
		/>
	)
}


export const SwitchPharmacyLoader = () => {

	return (
		<RotatingLines
			strokeColor="#282828"
			strokeWidth="5"
			animationDuration="0.5"
			width="35"
			visible={true}
		/>

	)
}

export const LoadingView = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<div >
			<Lottie options={defaultOptions}
				height={150}
				width={100}
				componentWillUpdate={() => { }}
			/>
		</div>
	);
}


export const LoadMoreData = () => {
	return (
		<div className='flex items-center justify-center mt-8 mb-4'>
			<div className="loader "></div>
		</div>
	)
}
