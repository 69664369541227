import { createSlice } from "@reduxjs/toolkit";
import { fetchPatientlistOnBasisOfRoute } from "../../apis/dispense/patientsForRouteLIst";

const initialState = {
	loading: false,
	data: [],
	currentPage: 1,
	totalPages: null
}

const patientDataBasisOfRouteSlice = createSlice({
	name: 'fetchPatientlistOnBasisOfRoute',
	initialState,

	reducers: {
		removeTodayDispenePatientById(state, action) {
			const patientIdToRemove = action.payload;
			state.data = state.data.filter(item => item.id !== patientIdToRemove);
		},
		clearAllTodayDispenseData(state) {
			state.data = []
		},
		todayDispenseRemovingPageNumber(state) {
			state.currentPage = 1
		}
	},

	extraReducers: (builder) => {
		builder.addCase(fetchPatientlistOnBasisOfRoute.pending.type, (state) => {
			return {
				...state,
				loading: true,
				// data: [],
				error: null
			}
		})

		// builder.addCase(fetchPatientlistOnBasisOfRoute.fulfilled.type, (state, action) => {
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		error: null,
		// 		data: action.payload,
		// 	};
		// });
		builder.addCase(fetchPatientlistOnBasisOfRoute.fulfilled.type, (state, action) => {
			const existingIds = new Set(state?.data?.map(item => item.id));
			const newData = action?.payload?.data?.filter(item => !existingIds.has(item.id));
			return {
				...state,
				loading: false,
				data: [...state.data, ...newData],
				currentPage: action.payload.meta.current_page,
				totalPages: action.payload.meta.last_page,
				error: null
			};
		});

		builder.addCase(fetchPatientlistOnBasisOfRoute.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: [],
				error: action.payload
			}
		})
	}

})

export const { clearAllTodayDispenseData, removeTodayDispenePatientById, todayDispenseRemovingPageNumber } = patientDataBasisOfRouteSlice.actions;

export default patientDataBasisOfRouteSlice.reducer