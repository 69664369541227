import { createSlice } from "@reduxjs/toolkit";
import { fetchPatientNewRxData } from "../../apis/supervisor/patientNewRXData";

const initialState = {
	loading: false,
	data: [],
	error: null
}

const PatientNewRxDataSlice = createSlice({
	name: 'fetchPatientNewRxData',
	initialState,


	reducers: {
		removeNewPatientRXData(state, action) {
			const updatedData = state?.data?.filter(patient => patient.id !== action.payload);
			state.data = updatedData;
		},

		removePatientNewRxData(state) {
			state.data = []
		}
	},

	extraReducers: (builder) => {
		builder.addCase(fetchPatientNewRxData.pending.type, (state) => {
			return {
				...state,
				loading: true,
				// data: [],
				error: null
			}
		})

		builder.addCase(fetchPatientNewRxData.fulfilled.type, (state, action) => {
			const existingIds = new Set(state?.data?.map(item => item.id));
			const newData = action?.payload?.data?.filter(item => !existingIds.has(item.id));
			return {
				...state,
				loading: false,
				data: [...state.data, ...newData],
				error: null
			}
		})

		builder.addCase(fetchPatientNewRxData.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: [],
				error: action.payload
			}
		})
	}

})

export const { removeNewPatientRXData, removePatientNewRxData } = PatientNewRxDataSlice.actions;

export default PatientNewRxDataSlice.reducer