import React, {
	useEffect,
	useState,
} from 'react';

import { RoutePharmacistModal } from '../../components/common/routePharmacistModal';
import { useAllSelector } from '../../services/selectors/allSelector';
import { useAuthSelector } from '../../services/selectors/authSelector';

import { fetchPharmacistOfPharmacy } from '../../services/apis/dispense/pharmacistOfPharmacy';
import { useLocation } from 'react-router-dom';

import { clearAllTodayDispenseData } from '../../services/slices/dispense/patientForRouteList';
import { SOSemergencyEnableButton } from '../../components/common/SOSemergencyEnableButton';

import SearchBox from '../../components/common/searchBox';

import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';
import * as Hooks from '../../hooks';
import * as routeNames from '../../routes/routeName';

const DriverDashboard = () => {

	const navigate = Hooks.useCustomNavigate();
	const dispatch = Hooks.useCustomDispatch();
	const location = useLocation()

	const [isDelivered, setIsDelivered] = useState(false);
	const [showModel, setShowModel] = useState(false);
	const [apiCallMade, setApiCallMade] = useState(false)

	const { finalMissedCount, pharmacistOfPharmacyListResponse, supervisor_pharmacist } = useAllSelector()
	const { pharmacyId, isSupervisor, authPharmacy } = useAuthSelector()

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (pharmacyId && !apiCallMade && (location?.pathname === routeNames.DASHBOARD_PHARMACY)) {
			dispatch(fetchPharmacistOfPharmacy(pharmacyId))
			dispatch(clearAllTodayDispenseData())
			setApiCallMade(true)
		}
		return () => { }
	}, [apiCallMade, pharmacyId, location.pathname, dispatch])

	const openTodayDispenseModal = () => {
		setIsDelivered(false)
		setShowModel(true)
	}

	return (
		<>
			<div className="app-wrapper flex justify-center h-screen">
				<div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-screen overflow-x-hidden">
					<div className='flex items-center justify-center'>
						<SOSemergencyEnableButton />
					</div>

					<div className="relative p-4">
						{pharmacistOfPharmacyListResponse?.loading &&
							<div className="progress-loader">
								<div className="progress"></div>
							</div>
						}

						<div className="card bg-white p-4 rounded-lg shadow-sm">
							<div className='flex'><div>
								<Common.TopBar pharmacyId={pharmacyId} />
							</div>
							</div>

							<div className='my-4'>
								<SearchBox pharmacyId={pharmacyId} />
							</div>

							<div className="dashboard-cards-grid grid grid-cols-2 gap-3">
								<div className="card flex flex-col p-3 rounded-md btnnn bg-green-50 gap-3"
									onClick={openTodayDispenseModal}>
									<div className="rounded-md bg-green-700 flex items-center justify-center"
										style={{ width: '40px', height: '40px' }}>
										<Icons.TodayDispenseIcon />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Pick Route</h1>
									</div>
								</div>

								{/* {isSupervisor === 1 && */}
								{isSupervisor === 1 && supervisor_pharmacist &&
									<div className="card flex flex-col p-3 rounded-md bg-gray-400 bg-opacity-10 gap-3"
										onClick={() => navigate(`${routeNames.today_analytics}`)}>
										<div className="rounded-md bg-gray-700 flex items-center justify-center"
											style={{ width: '40px', height: '40px' }}>
											<Icons.AnalyticsIcon />
										</div>
										<div className="text-info">
											<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Supervisor</h1>
										</div>
									</div>}

								<div className="card flex flex-col p-3 rounded-md bg-cyan-400 bg-opacity-10 gap-3"
									onClick={() => navigate(`${routeNames.upload_prescripition}`)} style={{ background: 'rgb(237 241 227 / 72%)' }}>
									<div className="rounded-md bg-cyan-700 flex items-center justify-center"
										style={{ width: '40px', height: '40px', background: '#4d7c0f' }}>
										<Icons.UploadPrescritption />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Upload Prescription</h1>
									</div>
								</div>

								{pharmacistOfPharmacyListResponse?.data === null ? null :
									finalMissedCount !== 0 &&
									<div className="card flex flex-col p-3 rounded-md bg-pink-400 bg-opacity-10 gap-3"
										onClick={() => navigate(`${routeNames.driver_Missed_Route}`)}>
										<div className="rounded-md bg-pink-700 flex items-center justify-center"
											style={{ width: '40px', height: '40px' }}>
											<Icons.CrossIcon />
										</div>
										<div className="text-info">
											<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Missed Dispense</h1>
										</div>
									</div>}


								<div className="card flex flex-col p-3 rounded-md bg-purple-400 bg-opacity-10 gap-3"
									onClick={() => navigate(`${routeNames.driver_today_pending}`)} >
									<div className="rounded-md bg-purple-700 flex items-center justify-center"
										style={{ width: '40px', height: '40px' }}>
										<Icons.PendingDispenseIcon />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Pending Dispense</h1>
									</div>
								</div>

								{authPharmacy?.length === 1 ? null :
									<div className="card btnnn flex flex-col p-3 rounded-md bg-red-400 bg-opacity-10 gap-3"
										onClick={() => navigate(routeNames.SWITCH_PHARMACY)} >
										<div className="rounded-md bg-red-700 flex items-center justify-center"
											style={{ width: '40px', height: '40px' }}>
											<Icons.SwitchPharmacyIcon />
										</div>
										<div className="text-info">
											<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Switch Pharmacy</h1>
										</div>
									</div>}

								<div className="card flex flex-col p-3 rounded-md bg-orange-300 bg-opacity-10 gap-3" onClick={() => navigate(`${routeNames.Patient_Consent}`)} >
									<div className="rounded-md flex items-center justify-center"
										style={{ width: '40px', height: '40px', backgroundColor: 'orange' }}>
										<Icons.PatientConsent />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Patient Consent</h1>
									</div>
								</div>

								<div className="card flex flex-col p-3 rounded-md bg-blue-50 gap-3" onClick={() => navigate(`${routeNames.PHARMACY_FRONT_PROFILE}`)}>
									<div className="rounded-md bg-blue-700 flex items-center justify-center"
										style={{ width: '40px', height: '40px' }}>
										<Icons.ContactIcon />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Contact Pharmacy</h1>
									</div>
								</div>

								<div className="card flex flex-col p-3 rounded-md bg-opacity-10 gap-3" onClick={() => navigate(`${routeNames.MY_PROFILE}`)} style={{ backgroundColor: 'rgb(104 30 71 / var(--tw-bg-opacity))' }}>
									<div className="rounded-md flex items-center justify-center"
										style={{ width: '40px', height: '40px', backgroundColor: 'rgb(104 30 71 )' }}>
										<Icons.ProfileSetting />
									</div>
									<div className="text-info">
										<h1 className="text-sm font-medium" style={{ fontSize: '13px', fontWeight: '500' }}>Profile Settings</h1>
									</div>
								</div>

							</div>
							<Common.BottomBar pharmacyId={pharmacyId} />
						</div>

						<RoutePharmacistModal
							showModel={showModel}
							setShowModel={setShowModel}
							isDelivered={isDelivered} />

					</div>
				</div>
			</div>
		</>
	)
}

export default DriverDashboard