import React, {
	useEffect,
	useRef,
	useState
} from 'react';

import { fetchPatientSearchList } from '../../services/apis/dispense/patientSearch';
import { useAllSelector } from '../../services/selectors/allSelector';
import { useAuthSelector } from '../../services/selectors/authSelector';
import { clearAllPatientSearchData } from '../../services/slices/dispense/patientSearch';

import ThemeTextInput from '../form/textInput';
import * as Icons from '../../assets/svg/icons';
import * as Hooks from '../../hooks';
import * as routeNames from '../../routes/routeName'

const SearchBox = () => {

	const { pharmacyId } = useAuthSelector()

	const dispatch = Hooks.useCustomDispatch();
	const navigate = Hooks.useCustomNavigate();

	const [query, setQuery] = useState("");
	const [show, setShow] = useState(false);

	const currentTimeout = useRef();
	const { patientSearchListResponse } = useAllSelector();
	const patientSearchData = patientSearchListResponse?.data?.data;

	const searchPatient = async (query) => {
		const params = {
			pharmacy_id: pharmacyId,
			query: query
		}
		try {
			await dispatch(fetchPatientSearchList(params))
		} catch (error) {

		}
	}

	useEffect(() => {
		if (currentTimeout.current)
			clearTimeout(currentTimeout.current);
		currentTimeout.current = setTimeout(() => {
			if (query)
				searchPatient(query);
		}, 200);

		if (!query) {
			dispatch(clearAllPatientSearchData())
		}
	}, [query])

	return (
		<form className="w-full flex-1 h-full relative">
			<ThemeTextInput
				icon={() => (<Icons.SearchIcon />)
				}
				value={query}
				placeholder='Search Patients'
				onChange={(e) => setQuery(e.target.value)}
				inputProps={{
					sizing: 'lg', onFocus: () => setShow(true),
					onBlur: () => setTimeout(() => {
						setShow(false)
					}, 100)
				}}
				isBottomMargin={false}
			/>

			{show && (
				<div id="dropdown" className={`absolute mt-1 overflow-auto z-10 
				${patientSearchListResponse?.loading || patientSearchData?.length === 0 || patientSearchData?.length > 0
						? `bg-white max-h-52 rounded-lg shadow-lg` : ``} divide-y divide-gray-100  w-full dark:bg-gray-700`}>
					<ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">

						{patientSearchListResponse?.loading ? (
							<li>
								<a className="text-lg px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white flex items-center justify-between">
									Searching... <span className="font-bold text-sm uppercase p-1 px-3 rounded-full text-white"></span>
								</a>
							</li>
						) :
							patientSearchData?.length === 0 ? (
								<li>
									<a className="text-lg px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white flex items-center justify-between">
										No Patient Found.<span className="font-bold text-sm uppercase p-1 px-3 rounded-full text-white"></span>
									</a>
								</li>
							) : patientSearchData?.length > 0 ? (
								patientSearchData.map(patient => (
									<li key={patient.id}
										onClick={() => { return navigate(`${routeNames?.search_patient_data}?id=${patient?.id}`) }}>
										<a className="text-lg px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white flex items-center justify-between">
											{patient?.first_name} {patient?.last_name}  {patient?.route && <>- {patient?.route}</>}

											<span className="font-bold text-sm uppercase  rounded-full text-white" style={{ color: 'black' }}>
												{patient?.delivery_status === 'pending' || patient?.delivery_status === 'final_missed' || patient?.delivery_status === 'today_missed' ? `[P]` :
													patient?.delivery_status === 'delivered' ? `[D]` :
														patient?.delivery_status === 'missed' ? `[M]` :
															null
												}
											</span>

										</a>
									</li>
								))
							) : null}
					</ul>
				</div>
			)
			}


		</form>
	)
}

export default SearchBox;