import { createAsyncThunk } from "@reduxjs/toolkit";
import { set_temp_driver_pharmacist } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const UpdatedDefaultPharmacist = createAsyncThunk('UpdatedDefaultPharmacist', async (requestedData, { rejectWithValue }) => {

    try {

        const uploadData = {
            pharmacy_id: requestedData.pharmacy_id,
            pharmacist_id: requestedData.pharmacist_id
        }

        const response = await Axios.post(`${set_temp_driver_pharmacist}`, uploadData)
        const data = response.data

        if (typeof requestedData.setShowToast === 'function') {
            requestedData.setShowToast(prevState => ({ ...prevState, successToast: true }))

            setTimeout(() => {
                requestedData.setShowToast(prevState => ({ ...prevState, successToast: false }))
            }, 2000);
        }


        return data;

    } catch (error) {

        if (typeof requestedData.setShowToast === 'function') {
            requestedData.setShowToast(prevState => ({ ...prevState, errorToast: true }))

            setTimeout(() => {
                requestedData.setShowToast(prevState => ({ ...prevState, errorToast: false }))
            }, 2000);
        }

        return rejectWithValue(error.response)
    }
})