import { createAsyncThunk } from "@reduxjs/toolkit";
import { oat_driver_passcode_verify } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";
import Cookies from "js-cookie";

export const verifyDriverPasscode = createAsyncThunk('verifyDriverPasscode', async (requesteData, { rejectWithValue }) => {
    try {

        const response = await Axios.post(oat_driver_passcode_verify, requesteData);
        const data = response.data;

        Cookies.set('authToken', data?.data?.token)
        return data;

    } catch (error) {
        return rejectWithValue(error.response)
    }
});