// import React, {
// 	useEffect,
// 	useState
// } from 'react';

// import {
// 	Loader,
// 	LoadingView
// } from '../../components/common/loader';

// import {
// 	DeliverSuccessfull,
// 	DeliveryErrorMessages,
// 	SuccessMessage,
// } from '../../components/common/AllConfirmationMessages';

// import { useAllSelector } from '../../services/selectors/allSelector';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { ErrorMessages } from '../../components/error/errorMessages';
// import { confirmDelivery } from '../../services/apis/dispense/confirmDelivery';

// import { fetchPatientDeliveryRx } from '../../services/apis/dispense/patientDeliveryRx';
// import { RouteConfirmation } from '../../components/common/routeConfirmation';

// import { useCustomStates } from '../../hooks/useCustomStates';
// import { getLocation } from '../../utils/helper/helper';

// import { removePatientById } from '../../services/slices/dispense/patientPendingList';
// import { apiKey, deliveryDriverDeliveredDispense } from '../../networking/urlEndPoints';

// import { useAuthSelector } from '../../services/selectors/authSelector';
// import { SOSemergencyEnableButton } from '../../components/common/SOSemergencyEnableButton';

// import { displaySelectedImage, PatientMobileAndLocation } from '../../components/common/patientDetails';
// import { GenrateRxNumberDataPDF, ReturnedPatches } from '../../components/common/dispenseRxData';

// import Ably from 'ably';
// import SearchBox from '../../components/common/searchBox';
// import PageNotFound from '../notFound/pageNotFound';

// import * as Icons from '../../assets/svg/icons';
// import * as Common from '../../components/common';
// import * as Hooks from '../../hooks';
// import * as routeName from '../../routes/routeName';
// import avatar from '../../assets/images/userimg.png';
// import { RemoveAllPatchesId } from '../../services/slices/dispense/validateReturnPatches';
// import { UploadTripilicatesPatientSigns } from '../../components/common/uploadTripilicatesPatientSigns';

// const SearchPatientList = ({ patientImg }) => {

// 	const location = useLocation()
// 	const navigate = useNavigate()

// 	const pharmacyId = parseInt(new URLSearchParams(location.search).get('p_id'))
// 	const patientId = parseInt(new URLSearchParams(location.search).get('id'))

// 	const patient_Id = Number(patientId)

// 	const dispatch = Hooks.useCustomDispatch();

// 	const { confirmDeliveryResponse,
// 		patientDeliveryRxListResponse,
// 		pharmacistDetail, validateReturnedPatchesResponse
// 	} = useAllSelector();

// 	const { pharmacyInspectionMode } = useAuthSelector()

// 	const patientsData = patientDeliveryRxListResponse?.data?.data

// 	const { comment, setComment, cordinate, setCordinate,
// 		patientSignature, setPatientSignature, pharmacistSignature, setPharmacistSignature,
// 		showErrorToast, setShowErrorToast, setLoading, showSuccessToast, setShowSuccessToast,
// 		showComment, setShowComment, showPatientSignature, setShowPatientSignature, showPharmacistSignature,

// 		setShowPharmacistSignature, showData, setShowData, showConfirmationModal, setShowConfirmationModal, loading,
// 		isRefused, setIsRefused, signatureErrors, setSignatureErrors, covid19, setCovid19
// 	} = useCustomStates()

// 	const [pharamacistId, setPharamacistID] = useState(null)
// 	const [showToast, setShowToast] = useState({ successToast: '' })
// 	const [image, setImage] = useState('')

// 	const [isTransferRequest, setIsTransferRequest] = useState(false)
// 	const [hasRxWitnessRequired, sethasRxWitnessRequired] = useState('')

// 	useEffect(() => {
// 		getLocation(setCordinate)
// 	}, [])

// 	useEffect(() => {
// 		if (patientDeliveryRxListResponse?.loading) {
// 			setShowData(false)
// 			setShowPharmacistSignature(false)
// 			setShowPatientSignature(false)
// 			setShowComment(false)
// 			setComment('')
// 			setPatientSignature([null])
// 			setPharmacistSignature(null)
// 			dispatch(RemoveAllPatchesId())
// 		}
// 	}, [patientDeliveryRxListResponse])

// 	useEffect(() => {
// 		const params = {
// 			id: patient_Id,
// 			pharmacy_id: pharmacyId
// 		}
// 		if (patientId) {
// 			setLoading(true)
// 			dispatch(fetchPatientDeliveryRx(params))
// 		}
// 		return () => { }
// 	}, [patient_Id])

// 	const getStatusSpan = (status, bgColor, textColor) => (
// 		<span className={`px-2 py-1.5 text-sm  rounded-md bg-${bgColor}-600 bg-opacity-10 text-${textColor}-900 w-auto flex justify-center items-center`}>
// 			{status}
// 		</span>
// 	);

// 	const handleConfirmDelivery = (patient_id) => {
// 		const params = {
// 			route: patient_id.route,
// 			pharmacy_id: pharmacyId,
// 			setLoading: setLoading,
// 		}
// 		const requesteData = {
// 			pharmacy_id: pharmacyId,
// 			patient_sign: [...patientSignature],
// 			pharmacist_sign: pharmacistSignature,
// 			delivery_latitude: cordinate?.latitude,
// 			delivery_longitude: cordinate?.longitude,
// 			patient_id: patient_id.id,
// 			pharmacist_id: pharamacistId,
// 			comment: comment,
// 			dispatch: dispatch,
// 			params: params,
// 			setShowErrorToast: setShowErrorToast,
// 			setLoading: setLoading,
// 			setShowSuccessToast: setShowSuccessToast,
// 			type: 'patient_search_list',
// 			patientSerchData: { patientId: patientId, type: 'patient_search_list' },
// 			is_refused: isRefused ? 1 : 0,
// 			covid19_exemptions: covid19 ? 1 : 0,
// 			is_return: validateReturnedPatchesResponse.isReturn,
// 			isTransferRequest,
// 			setIsTransferRequest
// 		}

// 		try {
// 			const winrx_data = (patientsData?.rx_data || patientsData?.patients_win_rx_data_delivered || patientsData?.patients_win_rx_data || patientsData?.patients_win_rx_data_today_missed || patientsData?.patients_win_rx_data_final_missed)

// 			const hasRxWitness = winrx_data?.some(patient => patient.rx_witness == "Y");

// 			const filteredData = patientsData && winrx_data.filter(item => item.is_din_matched.is_fentanyl === 1);

// 			if (patient_id && pharmacyId && pharamacistId) {
// 				const signaturesRequired = pharmacyInspectionMode === 1 && ((isRefused ? null : patientSignature[0] == null));

// 				if (hasRxWitness) {
// 					if (patientSignature[0] == null && !covid19 && !isRefused) {
// 						setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red' }))
// 					} else if (filteredData) {
// 						if (validateReturnedPatchesResponse?.winrx_data_id?.length !== filteredData?.length) {
// 							alert('Return Patches Feilds is required.')
// 						} else {
// 							handleSignature(signaturesRequired)
// 						}
// 					} else {
// 						handleSignature(signaturesRequired);
// 					}
// 				}

// 				else if (filteredData) {
// 					if (validateReturnedPatchesResponse?.winrx_data_id?.length !== filteredData?.length) {
// 						alert('Return Patches Feilds is required.')
// 					} else {
// 						handleSignature(signaturesRequired)
// 					}
// 				} else {
// 					handleSignature(signaturesRequired)
// 				}
// 				function handleSignature(signaturesRequired) {
// 					if (signaturesRequired) {
// 						setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red' }));
// 					} else if (isTransferRequest && patientSignature[0] === null) {
// 						setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red' }));
// 					}

// 					else {
// 						setSignatureErrors(prevState => ({ ...prevState, patientSign: null, pharmacistSign: null }));
// 						dispatch(confirmDelivery(requesteData))
// 						setIsRefused(false)
// 					}
// 				}
// 			}
// 		}
// 		catch {
// 		}
// 	}

// 	useEffect(() => {
// 		if (pharmacistDetail && pharmacistDetail?.length > 0) {
// 			let lastId = pharmacistDetail[pharmacistDetail?.length - 1]?.id;
// 			setPharamacistID(lastId)
// 		}
// 	}, [pharmacistDetail])

// 	useEffect(() => {

// 		const ably = new Ably.Realtime({ key: apiKey });
// 		const channel = ably.channels.get(deliveryDriverDeliveredDispense);

// 		channel.subscribe(`delivered-dispense-${pharmacyId}`, (message) => {
// 			if (message?.name === `delivered-dispense-${pharmacyId}`) {
// 				if (message?.data?.patient_id === patientId) {
// 					setLoading(false)
// 					dispatch(removePatientById(message?.data?.patient_id))
// 				}
// 			}
// 		})

// 		return () => {
// 			if (ably.connection.state === 'connected') {
// 				ably.close();
// 			}
// 		}
// 	}, [patientId, pharmacyId])

// 	const [selectedImage, setSelectedImage] = useState(patientsData?.profile_image || avatar)

// 	useEffect(() => {
// 		setSelectedImage(patientsData?.profile_image || avatar)
// 	}, [patientsData])

// 	return (
// 		<>
// 			{pharmacyId && patientId ? <>
// 				<div className="app-wrapper flex justify-center">
// 					<div x-data="{toastShow:true}"
// 						className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden ">

// 						<div className='flex items-center justify-center'>
// 							<SOSemergencyEnableButton />
// 						</div>
// 						<div className="relative p-4" >
// 							<div className="card bg-white p-4 rounded-lg shadow-sm">
// 								<div className="flex items-center justify-between gap-3">
// 									<div>
// 										<Common.TopBar pharmacyId={pharmacyId} />
// 									</div>
// 									<div className='flex items-center justify-center btnnn pt-4' onClick={() => navigate(routeName.DASHBOARD_PHARMACY)} >
// 										<Icons.HomeIcon />
// 									</div>
// 								</div>
// 							</div>

// 							<div className="flex gap-3 mb-3">
// 								<SearchBox pharmacyId={pharmacyId} />
// 							</div>

// 							<div className="routes-list">
// 								{(patientDeliveryRxListResponse?.loading) ?
// 									<div className='h-96'>
// 										<LoadingView />
// 									</div> :
// 									patientDeliveryRxListResponse?.error !== null ?
// 										<div className='flex items-center justify-center pb-4 mb-4 h-96'>
// 											<ErrorMessages error={'Oops! Got an issue while fetching Data.Please try again!'} />
// 										</div> :
// 										patientDeliveryRxListResponse?.data === null ? null :
// 											<>

// 												<div className="card bg-white p-4 rounded-lg shadow-sm mb-3 mt-2">
// 													<div className="flex items-center gap-2 ">
// 														<h2 className="text-lg font-medium uppercase flex justify-center items-center">
// 															<img src={selectedImage} alt='avtar' className='rounded-full w-8 h-8 mr-2'
// 																onClick={() => {
// 																	const filePicker = document.getElementById(`selectProfileImage`);
// 																	filePicker.click()
// 																}}
// 															/>

// 															{patientsData?.first_name} {patientsData?.last_name}</h2>

// 														<input name='avatar' accept="image/jpeg, image/jpg, image/png"
// 															onChange={(event) => displaySelectedImage(event, setSelectedImage, null, setShowToast, patientsData, dispatch)} type='file' id={`selectProfileImage`} className='hidden' />

// 														<span className="flex items-center justify-center h-5 w-5 text-sm rounded-full bg-green-600 text-white">
// 															{patientsData?.rx_data?.length || patientsData?.patients_win_rx_data?.length ||
// 																patientsData?.patients_win_rx_data_delivered?.length
// 																|| patientsData?.patients_win_rx_data_today_missed?.length || patientsData?.patients_win_rx_data_final_missed?.length}
// 														</span>

// 														{patientsData?.delivery_status &&
// 															(patientsData?.delivery_status === 'delivered' ?
// 																getStatusSpan('Delivered', 'green', 'green') :
// 																patientsData.delivery_status === 'missed' ?
// 																	getStatusSpan('Today Missed', 'red', 'red') :
// 																	patientsData?.delivery_status === 'final_missed' ?
// 																		getStatusSpan('Final Missed', 'red', 'red') :
// 																		patientsData?.delivery_status === 'pending' ?
// 																			getStatusSpan('Pending', 'red', 'red') :
// 																			patientsData?.delivery_status === 'today_missed' ?
// 																				getStatusSpan('Today Missed', 'red', 'red') :
// 																				getStatusSpan(patientsData?.delivery_status, 'yellow', 'yellow')
// 															)
// 														}

// 													</div>

// 													{!patientsData?.phone ? <p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>PHN: {patientsData?.phn}
// 														{patientsData?.dob && <> | DOB: {patientsData?.dob} </>} {patientsData?.gender && <> | Gender: {patientsData?.gender}</>}
// 													</p>
// 														: <>
// 															<p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>PHN: {patientsData?.phn}
// 																{patientsData.phone && <> | PH: {patientsData?.phone}  </>} {patientsData?.dob && <> | DOB: {patientsData?.dob} </>}
// 															</p>

// 															<p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>
// 																{patientsData?.gender && <> Gender: {patientsData?.gender}</>}
// 															</p>
// 														</>
// 													}

// 													<div className="flex gap-2 mt-3">
// 														<Icons.LocationIcon />
// 														<p className="text-gray-500">{patientsData?.full_address}</p>
// 													</div>

// 													<div className="flex items-center justify-between mt-4">
// 														<PatientMobileAndLocation
// 															data={patientsData} setIsTransferRequest={setIsTransferRequest}
// 															isTransferRequest={isTransferRequest}
// 															index={''}
// 														/>
// 													</div>


// 													<>
// 														{patientsData?.delivey_note && <p className='mt-2 bg-fuchsia-200 p-1 pl-2 rounded-md' style={{ color: 'black', fontSize: '13px', fontWeight: 'bold', backgroundColor: 'rgb(132 204 22)' }}>
// 															<span className='text-red-700'>Note: </span>{patientsData?.delivey_note}
// 														</p>}

// 														{patientsData &&
// 															(patientsData?.rx_data || patientsData?.patients_win_rx_data_delivered || patientsData?.patients_win_rx_data
// 																|| patientsData?.patients_win_rx_data_today_missed || patientsData?.patients_win_rx_data_final_missed)
// 																?.flatMap(rxData => rxData || [])
// 																?.sort((a, b) => {
// 																	if (a?.is_din_matched?.status === 1 && b?.is_din_matched?.status !== 1) {
// 																		return -1;
// 																	} else if (a?.is_din_matched?.status !== 1 && b?.is_din_matched?.status === 1) {
// 																		return 1;
// 																	} else {
// 																		return 0;
// 																	}
// 																})
// 																?.map((value, index) => (
// 																	value && (
// 																		<React.Fragment key={index}>
// 																			<div className="mt-2 mb-2">
// 																				<div className="p-2 rounded-md" style={{ backgroundColor: value?.is_din_matched?.status === 1 && value?.is_din_matched?.color, border: '1px solid lightgray' }}>

// 																					{/* <span className="text-sm font-normal text-white bg-green-500 rounded-md p-1">{value?.rxnumber}</span> */}

// 																					<div className='flex gap-2'>
// 																						<p className="text-sm font-normal text-white bg-green-500 rounded-md p-1" style={{ fontSize: '12px', height: '29px', background: 'black', paddingLeft: '10px', paddingRight: '10px' }}>
// 																							{value?.rxnumber}
// 																						</p>

// 																						{value.rx_witness == 'Y' &&
// 																							<p className="text-sm font-normal text-white bg-yellow-500 rounded-md p-1 " style={{ fontSize: '12px', height: '29px', background: '#6A057B', paddingLeft: '10px', paddingRight: '10px' }}>
// 																								Witness Required
// 																							</p>}

// 																						<GenrateRxNumberDataPDF value={value} />

// 																						{value?.is_triplicates_pending?.is_triplicates &&
// 																							<div className='text-sm font-normal text-white bg-red-500 rounded-md' onClick={() => setImage(value?.is_triplicates_pending?.precription_image)}>
// 																								<UploadTripilicatesPatientSigns
// 																									index={index}
// 																									rxData={value}
// 																									image={image} />
// 																							</div>
// 																						}

// 																					</div>

// 																					<h3 className="text-sm font-medium my-1">[{value?.refill_reqty}]{value?.drug}</h3>
// 																					<p className="text-sm text-gray-900">{value?.rxsig}</p>
// 																					<p className='pt-1' dangerouslySetInnerHTML={{ __html: value.prescriptionExpiryDay }} />
// 																					{value.note && <p className=' text-gray-700 bg-gray-200 p-1 pl-2 rounded-md' style={{ color: 'black', fontSize: '10px' }}>{value?.note}</p>}
// 																					{value?.is_din_matched?.is_fentanyl === 1 && <div className='mb-3'>
// 																						<ReturnedPatches
// 																							index={index}
// 																							rxData={value} />
// 																					</div>}
// 																				</div>
// 																			</div>
// 																		</React.Fragment>
// 																	)
// 																))}

// 														{showData && patientsData?.delivery_status !== 'delivered' &&
// 															<React.Fragment>
// 																<Common.Comment showComment={showComment}
// 																	setShowComment={setShowComment}
// 																	setComment={setComment} comment={comment} />

// 																<Common.PatientSignature patientSignature={patientSignature}
// 																	setPatientSignature={setPatientSignature}
// 																	showPatientSignature={showPatientSignature}
// 																	setShowPatientSignature={setShowPatientSignature}
// 																	setIsRefused={setIsRefused}
// 																	isRefused={isRefused}
// 																	signatureErrors={signatureErrors}
// 																	setSignatureErrors={setSignatureErrors}
// 																	covid19={covid19}
// 																	setCovid19={setCovid19}
// 																/>

// 																<Common.PharmacistSignature
// 																	pharmacistSignature={pharmacistSignature}
// 																	setPharmacistSignature={setPharmacistSignature}
// 																	showPharmacistSignature={showPharmacistSignature}
// 																	setShowPharmacistSignature={setShowPharmacistSignature}
// 																	signatureErrors={signatureErrors}
// 																	setSignatureErrors={setSignatureErrors}
// 																/>

// 															</React.Fragment>
// 														}

// 														{patientsData?.delivery_status !== 'delivered' &&
// 															<div className="flex items-center gap-3">
// 																{showData ?
// 																	validateReturnedPatchesResponse?.loading ? null :
// 																		<button className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md"
// 																			onClick={() => handleConfirmDelivery(patientsData)}>
// 																			{confirmDeliveryResponse?.loading ? <p className='flex justify-center items-center'><Loader /></p> : '  Continue'}

// 																		</button>
// 																	:
// 																	<button className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md" onClick={() => setShowData(true)} >Deliver</button>}
// 															</div>}
// 													</>
// 												</div>
// 											</>
// 								}
// 							</div>
// 						</div>

// 						{showErrorToast && <DeliveryErrorMessages setShowErrorToast={setShowErrorToast} />}
// 						{showSuccessToast && <DeliverSuccessfull setShowSuccessToast={setShowSuccessToast} />}

// 					</div>
// 				</div>

// 				{showConfirmationModal && <RouteConfirmation
// 					setShowConfirmationModal={setShowConfirmationModal}
// 					showConfirmationModal={showConfirmationModal}
// 					pharmacyId={pharmacyId} />
// 				}

// 				{showToast.successToast &&
// 					<SuccessMessage message={'Profile Updated Successfully.'} setShowToast={() => setShowToast(prevState => ({ ...prevState, successToast: false }))} />
// 				}

// 			</> : <PageNotFound />}
// 		</>
// 	)
// }

// export default SearchPatientList


import React, {
	useEffect,
	useState
} from 'react';

import {
	Loader,
	LoadingView
} from '../../components/common/loader';

import {
	DeliverSuccessfull,
	DeliveryErrorMessages,
	SuccessMessage,
} from '../../components/common/AllConfirmationMessages';

import {
	displaySelectedImage,
	PatientMobileAndLocation,
} from '../../components/common/patientDetails';

import {
	apiKey,
	deliveryDriverDeliveredDispense
} from '../../networking/urlEndPoints';

import {
	GenrateRxNumberDataPDF,
	ReturnedPatches
} from '../../components/common/dispenseRxData';

import {
	getStatusSpan,
	SearchPatientListDeliveredByAnotherDriverAblyHelper
} from '../../utils/helper/ablyDeliveredDispenseByOthers';

import { useAllSelector } from '../../services/selectors/allSelector';
import { ErrorMessages } from '../../components/error/errorMessages';

import { confirmDelivery } from '../../services/apis/dispense/confirmDelivery';
import { fetchPatientDeliveryRx } from '../../services/apis/dispense/patientDeliveryRx';

import { useCustomStates } from '../../hooks/useCustomStates';
import { removePatientById } from '../../services/slices/dispense/patientPendingList';

import { useAuthSelector } from '../../services/selectors/authSelector';
import { SOSemergencyEnableButton } from '../../components/common/SOSemergencyEnableButton';

import { RemoveAllPatchesId } from '../../services/slices/dispense/validateReturnPatches';
import { UploadTripilicatesPatientSigns } from '../../components/common/uploadTripilicatesPatientSigns';

import SearchBox from '../../components/common/searchBox';
import PageNotFound from '../notFound/pageNotFound';

import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';
import * as Hooks from '../../hooks';
import * as routeName from '../../routes/routeName';

import avatar from '../../assets/images/userimg.png';
import { getPharmacyOrderDeliveryTime } from '../../utils/helper/helper';

const SearchPatientList = () => {

	const dispatch = Hooks.useCustomDispatch()
	const navigate = Hooks.useCustomNavigate()

	const location = Hooks.useCustomLocation()

	const patientId = parseInt(new URLSearchParams(location.search).get('id'))
	const patient_Id = Number(patientId)

	const { confirmDeliveryResponse, patientDeliveryRxListResponse, validateReturnedPatchesResponse } = useAllSelector();
	const { pharmacyInspectionMode, selectedPharmacistID, pharmacyId } = useAuthSelector()

	const patientsData = patientDeliveryRxListResponse?.data?.data

	const { comment,
		setComment,

		cordinate,
		setCordinate,

		patientSignature,
		setPatientSignature,

		pharmacistSignature,
		setPharmacistSignature,

		showErrorToast,
		setShowErrorToast,

		setLoading,

		showSuccessToast,
		setShowSuccessToast,

		showComment,
		setShowComment,

		showPatientSignature,
		setShowPatientSignature,

		showPharmacistSignature,
		setShowPharmacistSignature,

		showData,
		setShowData,

		isRefused,
		setIsRefused,

		signatureErrors,
		setSignatureErrors,

		covid19,
		setCovid19,

		isTransferRequest,
		setIsTransferRequest

	} = useCustomStates()

	const [showToast, setShowToast] = useState({ successToast: '' })
	const [image, setImage] = useState('')
	const [selectedImage, setSelectedImage] = useState(patientsData?.profile_image || avatar)

	useEffect(() => {
		if (patientDeliveryRxListResponse?.loading) {
			setShowData(false)
			setShowPharmacistSignature(false)
			setShowPatientSignature(false)
			setShowComment(false)
			setComment('')
			setPatientSignature([null])
			setPharmacistSignature(null)
			dispatch(RemoveAllPatchesId())
		}
	}, [patientDeliveryRxListResponse])

	useEffect(() => {
		const params = {
			id: patient_Id,
			pharmacy_id: pharmacyId
		}
		if (patientId) {
			setLoading(true)
			dispatch(fetchPatientDeliveryRx(params))
		}
		return () => { }
	}, [patient_Id])


	const handleConfirmDelivery = (patient_id) => {
		const front_delivery_time = getPharmacyOrderDeliveryTime()

		const params = {
			route: patient_id.route,
			pharmacy_id: pharmacyId,
			setLoading: setLoading,
		}
		const requesteData = {
			pharmacy_id: pharmacyId,
			patient_sign: [...patientSignature],
			pharmacist_sign: pharmacistSignature,
			delivery_latitude: cordinate?.latitude,
			delivery_longitude: cordinate?.longitude,
			patient_id: patient_id.id,
			pharmacist_id: selectedPharmacistID,
			comment: comment,
			dispatch: dispatch,
			params: params,
			setShowErrorToast: setShowErrorToast,
			setLoading: setLoading,
			setShowSuccessToast: setShowSuccessToast,
			type: 'patient_search_list',
			patientSerchData: { patientId: patientId, type: 'patient_search_list' },
			is_refused: isRefused ? 1 : 0,
			covid19_exemptions: covid19 ? 1 : 0,
			is_return: validateReturnedPatchesResponse.isReturn,
			isTransferRequest,
			setIsTransferRequest,
			front_delivery_time: front_delivery_time
		}

		try {
			const hasRxWitness = (patientsData?.rx_data || patientsData?.patients_win_rx_data_delivered || patientsData?.patients_win_rx_data
				|| patientsData?.patients_win_rx_data_today_missed || patientsData?.patients_win_rx_data_final_missed).some(patient => patient.rx_witness == "Y");

			let filteredData = patientsData &&
				(patientsData?.rx_data || patientsData?.patients_win_rx_data_delivered || patientsData?.patients_win_rx_data
					|| patientsData?.patients_win_rx_data_today_missed || patientsData?.patients_win_rx_data_final_missed)?.filter(item => item.is_din_matched.is_fentanyl === 1);

			if (patient_id && pharmacyId) {

				const signaturesRequired = pharmacyInspectionMode === 1 && ((isRefused ? null : patientSignature[0] == null));

				if (hasRxWitness) {

					if (patientSignature[0] == null && !covid19 && !isRefused) {
						setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red' }))
					}

					else if (filteredData) {
						if (validateReturnedPatchesResponse?.winrx_data_id?.length !== filteredData?.length) {
							alert('Return Patches Feilds is required.')
						} else {
							handleSignature(signaturesRequired)
						}
					}

					else {
						handleSignature(signaturesRequired);
					}
				}

				else if (filteredData) {
					if (validateReturnedPatchesResponse?.winrx_data_id?.length !== filteredData?.length) {
						alert('Return Patches Feilds is required.')
					} else {
						handleSignature(signaturesRequired)
					}
				} else {
					handleSignature(signaturesRequired)
				}

				function handleSignature(signaturesRequired) {

					if (signaturesRequired) {
						setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red' }));
					}

					else if (isTransferRequest && patientSignature[0] === null) {
						setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red' }));
					}

					else {
						setSignatureErrors(prevState => ({ ...prevState, patientSign: null, pharmacistSign: null, pharmacistID: null }));
						dispatch(confirmDelivery(requesteData))
						setIsRefused(false)
					}

				}
			}
		}

		catch {
		}
	}

	useEffect(() => {
		setSelectedImage(patientsData?.profile_image || avatar)
	}, [patientsData])

	return (
		<>
			{pharmacyId && patientId ? <>
				<div className="app-wrapper flex justify-center">
					<div x-data="{toastShow:true}"
						className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden ">

						<div className='flex items-center justify-center'>
							<SOSemergencyEnableButton />
						</div>

						<div className="relative p-4" >

							<div className="card bg-white p-4 rounded-lg shadow-sm">
								<div className="flex items-center justify-between gap-3">
									<div>
										<Common.TopBar pharmacyId={pharmacyId} />
									</div>
									<div className='flex items-center justify-center btnnn pt-4' onClick={() => navigate(routeName.DASHBOARD_PHARMACY)} >
										<Icons.HomeIcon />
									</div>
								</div>
							</div>

							<div className="flex gap-3 mb-3">
								<SearchBox pharmacyId={pharmacyId} />
							</div>

							<div className="routes-list">

								{(patientDeliveryRxListResponse?.loading) ?

									<div className='h-96'>
										<LoadingView />
									</div> :

									patientDeliveryRxListResponse?.error !== null ?

										<div className='flex items-center justify-center pb-4 mb-4 h-96'>
											<ErrorMessages error={'Oops! Got an issue while fetching Data.Please try again!'} />
										</div> :

										patientDeliveryRxListResponse?.data === null ? null :
											<>
												<div className="card bg-white p-4 rounded-lg shadow-sm mb-3 mt-2">
													<div className="flex items-center gap-2 ">
														<h2 className="text-lg font-medium uppercase flex justify-center items-center">
															<img src={selectedImage} alt='avtar' className='rounded-full w-8 h-8 mr-2'
																onClick={() => {
																	const filePicker = document.getElementById(`selectProfileImage`);
																	filePicker.click()
																}}
															/>

															{patientsData?.first_name} {patientsData?.last_name}</h2>

														<input name='avatar' accept="image/jpeg, image/jpg, image/png"
															onChange={(event) => displaySelectedImage(event, setSelectedImage, null, setShowToast, patientsData, dispatch)} type='file' id={`selectProfileImage`} className='hidden' />

														<span className="flex items-center justify-center h-5 w-5 text-sm rounded-full bg-green-600 text-white">
															{patientsData?.rx_data?.length || patientsData?.patients_win_rx_data?.length ||
																patientsData?.patients_win_rx_data_delivered?.length
																|| patientsData?.patients_win_rx_data_today_missed?.length || patientsData?.patients_win_rx_data_final_missed?.length}
														</span>

														{patientsData?.delivery_status &&
															(patientsData?.delivery_status === 'delivered' ?
																getStatusSpan('Delivered', 'green', 'green') :
																patientsData.delivery_status === 'missed' ?
																	getStatusSpan('Today Missed', 'red', 'red') :
																	patientsData?.delivery_status === 'final_missed' ?
																		getStatusSpan('Final Missed', 'red', 'red') :
																		patientsData?.delivery_status === 'pending' ?
																			getStatusSpan('Pending', 'red', 'red') :
																			patientsData?.delivery_status === 'today_missed' ?
																				getStatusSpan('Today Missed', 'red', 'red') :
																				getStatusSpan(patientsData?.delivery_status, 'yellow', 'yellow')
															)
														}

													</div>

													{!patientsData?.phone ? <p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>PHN: {patientsData?.phn}
														{patientsData?.dob && <> | DOB: {patientsData?.dob} </>} {patientsData?.gender && <> | Gender: {patientsData?.gender}</>}
													</p>
														: <>
															<p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>PHN: {patientsData?.phn}
																{patientsData.phone && <> | PH: {patientsData?.phone}  </>} {patientsData?.dob && <> | DOB: {patientsData?.dob} </>}
															</p>

															<p className="text-xs text-gray-400 my-1 break-all" style={{ fontSize: '10px' }}>
																{patientsData?.gender && <> Gender: {patientsData?.gender}</>}
															</p>
														</>
													}

													<div className="flex gap-2 mt-3">
														<Icons.LocationIcon />
														<p className="text-gray-500">{patientsData?.full_address}</p>
													</div>

													<div className="flex items-center justify-between mt-4">
														<PatientMobileAndLocation
															data={patientsData} setIsTransferRequest={setIsTransferRequest}
															isTransferRequest={isTransferRequest}
															index={''}
														/>
													</div>

													<>
														{patientsData?.delivey_note && <p className='mt-2 bg-fuchsia-200 p-1 pl-2 rounded-md' style={{ color: 'black', fontSize: '13px', fontWeight: 'bold', backgroundColor: 'rgb(132 204 22)' }}>
															<span className='text-red-700'>Note: </span>{patientsData?.delivey_note}
														</p>}

														{patientsData &&
															(patientsData?.rx_data || patientsData?.patients_win_rx_data_delivered || patientsData?.patients_win_rx_data
																|| patientsData?.patients_win_rx_data_today_missed || patientsData?.patients_win_rx_data_final_missed)
																?.flatMap(rxData => rxData || [])
																?.sort((a, b) => {
																	if (a?.is_din_matched?.status === 1 && b?.is_din_matched?.status !== 1) {
																		return -1;
																	} else if (a?.is_din_matched?.status !== 1 && b?.is_din_matched?.status === 1) {
																		return 1;
																	} else {
																		return 0;
																	}
																})
																?.map((value, index) => (
																	value && (
																		<React.Fragment key={index}>
																			<div className="mt-2 mb-2">
																				<div className="p-2 rounded-md" style={{ backgroundColor: value?.is_din_matched?.status === 1 && value?.is_din_matched?.color, border: '1px solid lightgray' }}>
																					<div className='flex gap-2'>
																						<p className="text-sm font-normal text-white bg-green-500 rounded-md p-1 " style={{ fontSize: '12px', height: '29px', background: 'black', paddingLeft: '10px', paddingRight: '10px' }}>
																							{value?.rxnumber}
																						</p>

																						{value.rx_witness == 'Y' &&
																							<p className="text-sm font-normal text-white bg-yellow-500 rounded-md p-1" style={{ fontSize: '12px', height: '29px', background: '#6A057B', paddingLeft: '10px', paddingRight: '10px' }}>
																								Witness Required
																							</p>}

																						<GenrateRxNumberDataPDF value={value} />

																						{value?.is_triplicates_pending?.is_triplicates &&
																							<div className='text-sm font-normal text-white bg-red-500 rounded-md' onClick={() => setImage(value?.is_triplicates_pending?.precription_image)}>
																								<UploadTripilicatesPatientSigns
																									index={index}
																									rxData={value}
																									image={image} />
																							</div>
																						}

																					</div>

																					<h3 className="text-sm font-medium my-1">[{value?.refill_reqty}]{value?.drug}</h3>
																					<p className="text-sm text-gray-900">{value?.rxsig}</p>
																					<p className='pt-1' dangerouslySetInnerHTML={{ __html: value.prescriptionExpiryDay }} />
																					{value.note && <p className=' text-gray-700 bg-gray-200 p-1 pl-2 rounded-md' style={{ color: 'black', fontSize: '10px' }}>{value?.note}</p>}
																					{value?.is_din_matched?.is_fentanyl === 1 && <div className='mb-3'>
																						<ReturnedPatches
																							index={index}
																							rxData={value} />
																					</div>}
																				</div>
																			</div>
																		</React.Fragment>
																	)
																))}

														{showData && patientsData?.delivery_status !== 'delivered' &&
															<React.Fragment>
																<Common.Comment showComment={showComment}
																	setShowComment={setShowComment}
																	setComment={setComment} comment={comment} />

																<Common.PatientSignature patientSignature={patientSignature}
																	setPatientSignature={setPatientSignature}
																	showPatientSignature={showPatientSignature}
																	setShowPatientSignature={setShowPatientSignature}
																	setIsRefused={setIsRefused}
																	isRefused={isRefused}
																	signatureErrors={signatureErrors}
																	setSignatureErrors={setSignatureErrors}
																	covid19={covid19}
																	setCovid19={setCovid19}
																/>

																<Common.PharmacistSignature
																	pharmacistSignature={pharmacistSignature}
																	setPharmacistSignature={setPharmacistSignature}
																	showPharmacistSignature={showPharmacistSignature}
																	setShowPharmacistSignature={setShowPharmacistSignature}
																	signatureErrors={signatureErrors}
																	setSignatureErrors={setSignatureErrors}
																/>

															</React.Fragment>
														}

														{patientsData?.delivery_status !== 'delivered' &&
															<div className="flex items-center gap-3">
																{showData ?
																	validateReturnedPatchesResponse?.loading ? null :
																		<button className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md"
																			onClick={() => handleConfirmDelivery(patientsData)}>
																			{confirmDeliveryResponse?.loading ? <p className='flex justify-center items-center'><Loader /></p> : '  Continue'}

																		</button>
																	:
																	<button className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md"
																		onClick={() => {
																			setShowData(true);
																			setTimeout(() => {
																				const windowHeight = window.innerHeight;
																				const scrollAmount = windowHeight / 2;
																				window.scrollBy({ top: scrollAmount, behavior: "smooth" });
																			}, 100);
																		}}

																	>Deliver</button>}
															</div>}
													</>
												</div>
											</>
								}
							</div>
						</div>

						{showErrorToast && <DeliveryErrorMessages setShowErrorToast={setShowErrorToast} />}
						{showSuccessToast && <DeliverSuccessfull setShowSuccessToast={setShowSuccessToast} />}

					</div>
				</div>

				{showToast.successToast && <SuccessMessage message={'Profile Updated Successfully.'} setShowToast={() => setShowToast(prevState => ({ ...prevState, successToast: false }))} />}

				<SearchPatientListDeliveredByAnotherDriverAblyHelper
					apiKey={apiKey}
					deliveryDriverDeliveredDispense={deliveryDriverDeliveredDispense}
					pharmacyId={pharmacyId}
					dispatch={dispatch}
					setCordinate={setCordinate}
					location={location}
					setLoading={setLoading}
					patientId={patientId}
					removePatientById={removePatientById}
				/>

			</> : <PageNotFound />}
		</>
	)
}

export default SearchPatientList
