import { createSlice } from "@reduxjs/toolkit";
import { verifyDriverPasscode } from "../../apis/auth/verifyDriverPasscode";

const initialState = {
    loading: false,
    data: null,
    error: null
}

const verifyDriverPasscodeSlice = createSlice({
    name: 'verifyDriverPasscode',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(verifyDriverPasscode.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            }
        })

        builder.addCase(verifyDriverPasscode.fulfilled.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        })

        builder.addCase(verifyDriverPasscode.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        })
    }

})

export default verifyDriverPasscodeSlice.reducer