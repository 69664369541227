import { createSlice } from "@reduxjs/toolkit";
import { UpdatePharmacistSignature } from "../../apis/supervisor/updatePharmacistSign";

const initialState = {
    loading: false,
    data: null,
    error: null
}

const UpdatePharmacistSignatureSlice = createSlice({
    name: 'UpdatePharmacistSignature',
    initialState,

    extraReducers: (builder) => {
        
        builder.addCase(UpdatePharmacistSignature.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            }
        })

        builder.addCase(UpdatePharmacistSignature.fulfilled.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        })

        builder.addCase(UpdatePharmacistSignature.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        })
    }

})

export default UpdatePharmacistSignatureSlice.reducer