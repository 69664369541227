import { useSelector } from 'react-redux';
import * as EncryptedKey from '../constants/encryptedRootReducerKeys';

export const useSuperVisorSelector = () => {

	const patientNewRxDataResponse = useSelector((state) => state.patientNewRxData)
	const UpdateNewRXDataOptionResponse = useSelector(state => state.UpdateNewRXDataOption)
	const patientDeliveredListResponse = useSelector((state) => state[EncryptedKey.patientDeliveredDataBasisOfRoute]);

	const PatientListWithoutPharmacistSignResponse = useSelector(state => state.PatientListWithoutPharmacistSign)
	const UpdatePharmacistSignatureResponse = useSelector(state => state.UpdatePharmacistSignature)

	const updatedDefaultPharmacistResponse = useSelector(state => state.updatedDefaultPharmacist)
	const pharmacyMarkAttandanceResponse = useSelector(state => state.pharmacyMarkAttandance)

	return {
		patientNewRxDataResponse,
		UpdateNewRXDataOptionResponse,
		patientDeliveredListResponse,
		PatientListWithoutPharmacistSignResponse,
		UpdatePharmacistSignatureResponse,
		updatedDefaultPharmacistResponse,
		pharmacyMarkAttandanceResponse
	}
}