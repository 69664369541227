import { useSelector } from 'react-redux';
import * as EncryptedKey from '../constants/encryptedRootReducerKeys';

export const useAuthSelector = () => {

	const authLoginResponse = useSelector((state) => state[EncryptedKey.authLogin]);

	const authPharmacy = authLoginResponse?.data?.data?.driver_pharmacy

	const isSupervisor = authLoginResponse?.data?.data?.driver_profile?.is_supervisor
	const authEmail = authLoginResponse?.data?.data?.email;

	const driverDefaultPharmacist = authLoginResponse?.data?.data?.driver_profile?.driver_default_pharmacist

	const verifyDriverPasscodeResponse = useSelector((state) => state.verifyDriverPasscode);

	const authPharmacies = authLoginResponse?.data?.data?.driver_pharmacy

	const driverSelectedPharmacy = useSelector(state => state?.driverSelectedPharmacy?.data)

	const pharmacyId = driverSelectedPharmacy?.pharmacy_id

	const driverSelectedPharmacist = useSelector(state => state.driverSelectedPharmacist)
	const selectedPharmacistID = driverSelectedPharmacist?.data

	const driverID = authLoginResponse?.data?.data?.driver_profile?.id

	const pharmacyDetail = useSelector((state) => state[EncryptedKey.pharmacyDetail])
	const pharmacyInspectionMode = pharmacyDetail?.data?.data?.inspection_mode

	const SOS_EmergencyEnabledResponse = useSelector(state => state.SOS_EmergencyEnabled)

	const driverOTPVerificationResponse = useSelector(state => state.driverOTPVerification)

	return {
		authLoginResponse,
		verifyDriverPasscodeResponse,
		authEmail,
		authPharmacies,
		driverSelectedPharmacy,
		pharmacyId,
		driverSelectedPharmacist,
		selectedPharmacistID,
		driverID,
		pharmacyInspectionMode,
		SOS_EmergencyEnabledResponse,
		isSupervisor,
		authPharmacy,
		driverDefaultPharmacist,
		driverOTPVerificationResponse
	}
}