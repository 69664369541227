import React from 'react'
import * as Icons from '../../assets/svg/icons'

export const PickedUpSuccessfully = ({ setShowToast }) => {
	return (
		<React.Fragment>
			<div className="bg-gray-900 fixed ms-3 me-3 bottom-0 w-full text-sm text-white  shadow-lg dark:bg-gray-900">
				<div className="flex items-center gap-2 p-4">
					<Icons.ToastMessageIcon />
					Picked Up Successfully
					<div className="ml-auto">
						<button type="button" onClick={() => setShowToast(false)}
							className="inline-flex justify-center items-center w-8 h-8  text-white hover:text-white 
												opacity-50 hover:opacity-100 
												focus:outline-none focus:opacity-100">
							<span className="sr-only">Close</span>
							<Icons.ToastMessageIcon2 />
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}


export const DeliveryErrorMessages = ({ setShowErrorToast }) => {
	return (
		<div
			className="bg-red-800 fixed ms-3 me-3 bottom-0  text-sm text-white shadow-lg dark:bg-gray-900"
			role="alert">
			<div className="flex gap-2 p-4">
				Oops! Got an issue while processing delivery.Please try again.
				<div className="ml-auto">
					<button type="button" onClick={() => setShowErrorToast(false)}
						className="inline-flex flex-shrink-0 justify-center items-center size-5  text-white hover:text-white opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100">
						<span className="sr-only">Close</span>
						<Icons.ToastMessageIcon2 />
					</button>
				</div>
			</div>
		</div>
	)
}

export const DeliverSuccessfull = ({ setShowSuccessToast }) => {
	return (
		<React.Fragment>
			<div className="bg-green-500 fixed ms-3 me-3 bottom-0 w-full text-sm text-white  shadow-lg dark:bg-gray-900">
				<div className="flex items-center gap-2 p-4">
					<Icons.ToastMessageIcon />
					Order Delivered.
					<div className="ml-auto">
						<button type="button" onClick={() => setShowSuccessToast(false)} className="inline-flex justify-center items-center w-8 h-8  text-white hover:text-white opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100">
							<span className="sr-only">Close</span>
							<Icons.ToastMessageIcon2 />
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}


export const MissedDeliveryErrorMessages = ({ setMissedDeliveryErrorToast }) => {
	return (
		<div
			className="bg-red-800 w-full fixed ms-3 me-3 bottom-0  text-sm text-white  shadow-lg dark:bg-gray-900"
			role="alert">
			<div className="flex gap-2 p-4">
				Oops! Got an issue while Missing Delivery.
				<div className="ml-auto">
					<button type="button" onClick={() => setMissedDeliveryErrorToast(false)}
						className="inline-flex flex-shrink-0 justify-center items-center size-5 rounded-lg text-white hover:text-white opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100">
						<span className="sr-only">Close</span>
						<Icons.ToastMessageIcon2 />
					</button>
				</div>
			</div>
		</div>
	)
}

export const DeliveryDoneByAnotherDriver = ({ setMissedDeliveryErrorToast }) => {
	return (
		<div
			className="bg-red-800 w-full fixed ms-3 me-3 bottom-0  text-sm text-white  shadow-lg dark:bg-gray-900"
			role="alert">
			<div className="flex gap-2 p-4">
				Delivery done by another Driver.
				<div className="ml-auto">
					<button type="button" onClick={() => setMissedDeliveryErrorToast(false)}
						className="inline-flex flex-shrink-0 justify-center items-center size-5 rounded-lg text-white hover:text-white opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100">
						<span className="sr-only">Close</span>
						<Icons.ToastMessageIcon2 />
					</button>
				</div>
			</div>
		</div>
	)
}

export const PatientConsentErrorMessage = ({ setShowErrorToast }) => {
	return (
		<div
			className="bg-red-800 fixed ms-3 me-3 bottom-0  text-sm text-white shadow-lg dark:bg-gray-900"
			role="alert">
			<div className="flex gap-2 p-4">
				Oops! Got an issue while adding Details Please try to add again.
				<div className="ml-auto">
					<button type="button" onClick={() => setShowErrorToast(false)}
						className="inline-flex flex-shrink-0 justify-center items-center size-5  text-white hover:text-white opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100">
						<span className="sr-only">Close</span>
						<Icons.ToastMessageIcon2 />
					</button>
				</div>
			</div>
		</div>
	)
}

export const SignatureErrorMessage = ({ setShowErrorToast, message }) => {
	return (
		<div
			className="bg-red-800 w-full fixed ms-3 me-3 bottom-0  text-sm text-white shadow-lg dark:bg-gray-900"
			role="alert">
			<div className="flex gap-2 p-4">
				{message}
				<div className="ml-auto">
					<button type="button" onClick={() => setShowErrorToast(false)}
						className="inline-flex flex-shrink-0 justify-center items-center size-5  text-white hover:text-white opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100">
						<span className="sr-only">Close</span>
						<Icons.ToastMessageIcon2 />
					</button>
				</div>
			</div>
		</div>
	)
}

export const ErrorMessage = ({ setShowToast, message }) => {

	return (
		<div
			className="bg-red-800 w-full fixed ms-3 me-3 bottom-0 z-50 text-sm text-white shadow-lg dark:bg-gray-900"
			role="alert">
			<div className="flex gap-2 p-4">
				{message}
				<div className="ml-auto">
					<button type="button" onClick={setShowToast}
						className="inline-flex flex-shrink-0 justify-center items-center size-5  text-white hover:text-white opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100">
						<span className="sr-only">Close</span>
						<Icons.ToastMessageIcon2 />
					</button>
				</div>
			</div>
		</div>
	)
}

export const SOS_EmergencyEnabledMessage = ({ setShowToast, message }) => {

	return (
		<div
			className="bg-red-800 w-full fixed ms-3 me-3 bottom-0  text-sm text-white shadow-lg dark:bg-gray-900"
			role="alert">
			<div className="flex gap-2 p-4">
				<div dangerouslySetInnerHTML={{ __html: message }} className='gap-4' style={{ display: 'flex', marginRight: '10px' }} />
				<div className="ml-auto">
					<button type="button" onClick={setShowToast}
						className="inline-flex flex-shrink-0 justify-center items-center size-5  text-white hover:text-white opacity-50 hover:opacity-100 
						focus:outline-none focus:opacity-100">
						<span className="sr-only">Close</span>
						<Icons.ToastMessageIcon2 />
					</button>
				</div>
			</div>
		</div>
	)
}

export const SuccessMessage = ({ message, setShowToast }) => {
	return (
		<React.Fragment>
			<div className="bg-green-500 fixed ms-3 me-3 bottom-0 w-full text-sm text-white z-50 shadow-lg dark:bg-gray-900">
				<div className="flex items-center gap-2 p-4">
					<Icons.ToastMessageIcon />
					{message}
					<div className="ml-auto">
						<button type="button" onClick={setShowToast} className="inline-flex justify-center items-center w-8 h-8  text-white hover:text-white 
								opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100">
							<span className="sr-only">Close</span>
							<Icons.ToastMessageIcon2 />
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}