
import { updateWhoVieWedmed } from "../../services/apis/dispense/updatewhoviewedmed";

export function getCurrentDay() {
	const currentDay = new Date();

	const options = {
		timeZone: process.env.REACT_APP_PHARMACY_TIME_ZONE,
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};

	const canadaTime = currentDay.toLocaleString('en-US', options);
	return canadaTime;
}

export const getPharmacyCurrentTime = () => {
	const currentTime = new Date();

	const options = {
		timeZone: process.env.REACT_APP_PHARMACY_TIME_ZONE,
		hour12: true,
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	}

	const canadaTime = currentTime.toLocaleString('en-US', options);
	return canadaTime;
}

export const getPharmacyCurrentDate = () => {
	const currentTime = new Date();

	const options = {
		timeZone: process.env.REACT_APP_PHARMACY_TIME_ZONE,
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	};

	// const canadaTime = currentTime.toLocaleDateString('en-US', options);
	// const formattedDate = canadaTime.split('/').join('-');
	const canadaTime = currentTime.toLocaleDateString('en-CA', options);

	return canadaTime; 
	// return formattedDate;
};

export const getCurrentTime = () => {
	const currentTime = new Date();

	const options = {
		timeZone: process.env.REACT_APP_PHARMACY_TIME_ZONE,
		hour12: true,
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
	}

	const canadaTime = currentTime.toLocaleString('en-US', options);
	return canadaTime;
}

export const getPharmacyOrderDeliveryTime = () => {
	const currentTime = new Date();

	const options = {
		// timeZone: process.env.REACT_APP_PHARMACY_TIME_ZONE,
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: false,
	};

	const datePart = currentTime.toLocaleDateString('en-CA', options).replace(/,/g, '');
	const formattedDateTime = `${datePart}`;

	return formattedDateTime;
}


export const DisplayCurrentDay = (setCurrentDate) => {
	const currentCanadaTime = getCurrentDay();
	setCurrentDate(currentCanadaTime)
}

export function getLocation(setCordinate) {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition((position) => showPosition(position, setCordinate));
	} else {
	}
}

function showPosition(position, setCordinate) {
	setCordinate({
		latitude: position.coords.latitude,
		longitude: position.coords.longitude
	});
}

export const getDefaultPharmacist = (pharmacyId, driverDefaultPharmacist) => {
	return driverDefaultPharmacist?.filter(item => item?.pharmacy_id === pharmacyId);
};


export const handleToggleRXData = (
	index,
	data,
	openIndex,
	setOpenIndex,
	dispatch,
	setSignatureErrors,
	setShowData,
	RemoveAllPatchesId,
	setPatientSignature,
	setShowComment,
	setShowPatientSignature,
	setShowPharmacistSignature,
	setPharmacistSignature,
	setComment,
	setPId,
	pharmacyId
) => {

	if (openIndex === index) {
		setOpenIndex(null);
	}

	else {
		setOpenIndex(index);
		setSignatureErrors((prevState) => ({
			...prevState,
			patientSign: null,
			pharmacistID: null,
			pharmacistSign: null,
		}));

		const requestedData = {
			pharmacy_id: pharmacyId,
			patient_id: data?.id
		}
		dispatch(updateWhoVieWedmed(requestedData))
	}

	setShowData(false);
	dispatch(RemoveAllPatchesId());
	setPatientSignature([null]);
	setShowComment(false);
	setShowPatientSignature(false);
	setShowPharmacistSignature(false);
	setPharmacistSignature(null);
	setShowComment(false);
	setComment("");

	setPId(data?.id);
	setTimeout(() => {
		const nextPatientCard = document.getElementById(`patientCard-${index}`);
		if (nextPatientCard) {
			nextPatientCard.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	}, 100);
};