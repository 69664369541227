import Cookies from "js-cookie";
import Axios from "../../../networking/interceptor";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { oat_driver_login } from "../../../networking/urlEndPoints";
import { authSelectedPharmacy } from "./authSelectedPharmacy";

import * as routeNames from '../../../routes/routeName';
import * as Storage from '../../../storage/index'

export const authLogin = createAsyncThunk('authLogin', async (userDetail, { rejectWithValue }) => {

	try {
		const requesteData = {
			email: userDetail?.email,
			password: userDetail?.password,
			passcode: userDetail?.passcode
		}

		const response = await Axios.post(oat_driver_login, requesteData);
		const data = response.data;

		Cookies.set('authToken', data?.data?.token)
		Cookies.set('verified', 0)

		if (typeof userDetail.navigate === 'function') {
			if (data?.data?.driver_pharmacy?.length === 1) {
				userDetail.dispatch(authSelectedPharmacy(data?.data?.driver_pharmacy?.[0]))
				// userDetail.dispatch(fetchPharmacyDetails(data?.data?.driver_pharmacy?.[0]?.pharmacy_id))
				localStorage.removeItem(Storage.todayPendingRoute)
				localStorage.removeItem(Storage.todayMissedRoute)
				localStorage.removeItem(Storage.finalMissedRoute)
				userDetail.navigate(routeNames.verify_otp)
			}

			else {
				userDetail.navigate(routeNames.SWITCH_PHARMACY)
			}
		}

		return data;

	} catch (error) {
		return rejectWithValue(error.response)
	}
});