import React, { useState } from 'react';

import { ErrorMessages } from '../../components/error/errorMessages';

import * as Common from '../../components/common';

const UpdatePasscode = () => {

    const initialFormData = { currentPasscode: '', newPasscode: '', confirmPasscode: '' };
    const [userPassode, setUserPasscode] = useState(initialFormData);
    const [errorMessage, setErrorMessage] = useState(null);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserPasscode({
            ...userPassode,
            [name]: value
        })
        setErrorMessage(null)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            if (!userPassode?.currentPasscode && !userPassode?.newPasscode && !userPassode?.confirmPasscode) {
                setErrorMessage('Please Complete your all field')
            } else if (!userPassode?.currentPasscode) {
                setErrorMessage('Please Enter Your Current Passcode')
            } else if (!userPassode?.newPasscode) {
                setErrorMessage('Please Enter Your New Passcode')
            } else if (!userPassode?.confirmPasscode) {
                setErrorMessage('Confirm Password')
            } else if (userPassode?.newPasscode !== userPassode?.confirmPasscode) {
                setErrorMessage('The new passcode confirmation does not match.')
            } else {
                setErrorMessage(null)
            }
        } catch (error) {

        }
    };

    return (

        <>
            <div className="container mx-auto px-4 flex items-center justify-center py-4">
                <div className="card bg-white shadow-2xl rounded-lg w-full max-w-md m-auto">
                    <div className='flex mt-2 p-4 gap-4'>
                        <Common.HomeButton />
                        <Common.BackButton />
                    </div>
                    <div className='container px-4 mb-8 mt-4'>
                        <div className='card bg-white w-full bg-white shadow-2xl rounded-lg m-auto pt-4 pb-4'>
                            <div className="card-header py-2 text-center mb-4">
                                <h2 className="text-2xl font-semibold text-gray-900">Change Passcode</h2>
                            </div>
                            <form>

                                <div className='relative pl-4 pr-4 mb-4'>
                                    <input onChange={handleChange} defaultValue={userPassode?.currentPasscode} name='currentPasscode' type="password" className="block pl-4 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " autoComplete='current-password' />
                                    <label htmlFor="email" className="absolute flex ml-2 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                        Current Passcode</label>
                                </div>
                                <div className='relative pl-4 pr-4 mb-4'>
                                    <input onChange={handleChange} defaultValue={userPassode?.newPasscode} name='newPasscode' type="password" className="block pl-4 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " autoComplete='new-password' />
                                    <label htmlFor="email" className="absolute flex ml-2 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                        New Passcode</label>
                                </div>
                                <div className='relative pl-4 pr-4 mb-2'>
                                    <input onChange={handleChange} defaultValue={userPassode?.confirmPasscode} name='confirmPasscode' type="password" className="block pl-4 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " autoComplete='new-password' />
                                    <label htmlFor="email" className="absolute flex ml-2 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                        Confirm Passcode</label>
                                </div>
                                <div className='grid pl-4 pr-4 mb-4'>
                                    {errorMessage !== null ? <ErrorMessages error={errorMessage} /> : null}

                                </div>
                                <div>
                                    <button onClick={handleSubmit} type='submit' className='flex ml-4 mt-4 px-4 py-2 rounded-lg bg-blue-500 text-white font-semibold hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
                                        Submit
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default UpdatePasscode
