import React, { useState, useEffect, useRef } from "react";

import { useCustomDispatch, useCustomNavigate } from "../../hooks";
import { getCurrentTime, getPharmacyCurrentDate } from "../../utils/helper/helper";
import { ErrorMessage, SuccessMessage } from "../../components/common/AllConfirmationMessages";

import { SOSemergencyEnableButton } from '../../components/common/SOSemergencyEnableButton';
import { useSuperVisorSelector } from "../../services/selectors/allSuperVisorSelector";
import { pharmacyMarkAttandance } from "../../services/apis/supervisor/markAttandance";

import { useAuthSelector } from "../../services/selectors/authSelector";
import { LoadingView } from "../../components/common/loader";

import Webcam from "react-webcam";
import * as Common from '../../components/common';
import * as Icons from '../../assets/svg/icons';
import * as routeName from '../../routes/routeName';

const SupverVisorAttandance = () => {

    const navigate = useCustomNavigate();
    const dispatch = useCustomDispatch();

    const [input, setInput] = useState("");
    const [imageSrc, setImageSrc] = useState(null);
    const { pharmacyMarkAttandanceResponse } = useSuperVisorSelector();
    const { pharmacyId } = useAuthSelector();

    const getCurrentDate = getPharmacyCurrentDate();
    const getTime = getCurrentTime();

    const webcamRef = useRef(null);
    const [shouldCallApi, setShouldCallApi] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [errorToast, setErrorToast] = useState(false);
    const [message, setMessage] = useState('');
    const [isCameraLoaded, setIsCameraLoaded] = useState(false);

    const captureImage = () => {
        const imageSrc = webcamRef?.current?.getScreenshot();
        setImageSrc(imageSrc);
    };

    useEffect(() => {
        if (input.length === 4) {
            captureImage();
            setShouldCallApi(true);
        }
    }, [input]);

    useEffect(() => {
        if (input.length === 4 && shouldCallApi) {
            const reqData = {
                pharmacyId: pharmacyId,
                date: getCurrentDate,
                time: getTime,
                image: imageSrc || null,
                setImageSrc: setImageSrc,
                setInput: setInput,
                input: input,
                setShowToast: setShowToast,
                setErrorToast: setErrorToast,
                setMessage: setMessage
            };

            dispatch(pharmacyMarkAttandance(reqData));
            setShouldCallApi(false);
        }
    }, [input, imageSrc, pharmacyId, dispatch, shouldCallApi]);

    const inputRefs = useRef([]);

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);

    useEffect(()=>{
            const nextPatientCard = document.getElementById(`patientCard`);
            if (nextPatientCard) {
                nextPatientCard.scrollIntoView({ behavior: "smooth", block: "start" });
            }
    })
    
    return (
        <div className="app-wrapper flex justify-center h-screen" >
            <div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 overflow-x-hidden mb-0">

                <div className='flex items-center justify-center'>
                    <SOSemergencyEnableButton />
                </div>

                <div className="relative p-4" >
                    <div className="card bg-white p-4 rounded-lg shadow-sm">
                        <div className="flex items-center justify-between gap-3">
                            <div>
                                <Common.TopBar />
                            </div>
                            <div className='flex items-center justify-center btnnn pt-4' onClick={() => navigate(`${routeName.DASHBOARD_PHARMACY}`)}>
                                <Icons.HomeIcon />
                            </div>
                        </div>
                    </div>

                    <h1 className="flex gap-3 mt-2 mb-2 items-center justify-center text-lg font-medium" id={`patientCard`}>Clock-Out</h1>
                    <div className="flex" >
                        <div className="rounded-lg pb-6 flex flex-col md:flex-row w-full max-w-4xl">
                            <div className="flex flex-col items-center justify-center md:w-1/2 mt-2">
                                <div>

                                    {!isCameraLoaded && (
                                        <div  role="status" className="animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center ">
                                            <div className="flex items-center justify-center w-96 h-72 -mb-40 bg-gray-300 sm:w-96 dark:bg-gray-700">

                                            </div>
                                        </div>)
                                    }
                                    {imageSrc ? (
                                        <img src={imageSrc} alt="User" className="w-full h-full rounded-lg object-cover" />
                                    ) : (
                                        <Webcam
                                            audio={false}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            className="w-full h-full object-cover"
                                            videoConstraints={{
                                                width: 720,
                                                height: 1020,
                                                facingMode: "user"
                                            }}
                                            onUserMedia={() => setIsCameraLoaded(true)}
                                            onUserMediaError={() => setIsCameraLoaded(false)}
                                        />
                                    ) }
                                </div>
                            </div>

                            <div className="flex mb-0 flex-col mt-6 items-center justify-center md:w-1/2   md:mt-0">
                                <div className="flex gap-6 -mb-8">
                                    {[...Array(4)].map((_, i) => (
                                        <input
                                            key={i}
                                            type="text"
                                            maxLength="1"
                                            value={input[i] || ""}
                                            inputMode="numeric"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value) {
                                                    const newInput = input.split("");
                                                    newInput[i] = value;
                                                    setInput(newInput.join(""));
                                                    if (i < 3) {
                                                        inputRefs.current[i + 1].focus();
                                                    }
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "Backspace") {
                                                    e.preventDefault(); // Prevent default backspace behavior
                                                    const newInput = input.split("");
                                                    newInput[i] = ""; // Clear the current input
                                                    setInput(newInput.join(""));
                                                    // Move to the previous input field
                                                    if (i > 0) {
                                                        inputRefs.current[i - 1].focus();
                                                    }
                                                }
                                            }}
                                            ref={(el) => (inputRefs.current[i] = el)}
                                            id={`input-${i}`}
                                            className="w-16 -mb-8 h-16 border bg-white border-gray-300 rounded-lg flex items-center justify-center text-xl font-semibold text-center"
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {pharmacyMarkAttandanceResponse?.loading &&
                <div className="fixed top-0 left-0 w-full h-full bg-gray-800 opacity-60 flex justify-center items-center z-50">
                    <LoadingView />
                </div>
            }

            {message && showToast &&
                <SuccessMessage message={message} setShowToast={setShowToast} />
            }

            {message && errorToast &&
                <ErrorMessage message={message} setShowToast={setErrorToast} />
            }
        </div>
    );
};

export default SupverVisorAttandance;
