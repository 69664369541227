
import { createAsyncThunk } from "@reduxjs/toolkit";

import Axios from "../../../networking/interceptor";

export const pharmacyMarkAttandance = createAsyncThunk('checkInBreakInout', async (params, { rejectWithValue }) => {

    const { pharmacyId, date, time, image, setImageSrc, setInput, input, setShowToast, setErrorToast, setMessage } = params
    try {
        const response = await Axios.post('driver/pharmacyuser/checkInBreakInout',
            {
                pharmacy_id: pharmacyId,
                time: time,
                date: date,
                image: image,
                pin: input
            })

        const data = response.data
        if (typeof setImageSrc === 'function' && typeof setInput === 'function') {
            setImageSrc(null)
            setInput('')
        }

        if (typeof setShowToast === 'function') {
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, 2000);
        }

        if (typeof setMessage === 'function') {
            setMessage(data?.message)
        }
        document.activeElement.blur();
        return data;

    } catch (error) {
        if (typeof setErrorToast === 'function') {
            setErrorToast(true)
            setTimeout(() => {
                setErrorToast(false)
            }, 2000);

            setMessage(error?.response?.data?.message)
        }

        if (typeof setImageSrc === 'function' && typeof setInput === 'function') {
            setImageSrc(null)
            setInput('')
        }
        document.activeElement.blur();
        return rejectWithValue(error.response)
    }
});