import React, { useState, useEffect } from 'react'
import { useCustomDispatch } from '../../hooks';
import { SOS_EmergencyEnabled } from '../../services/apis/auth/SOS_EmergencyEnabled';
import { useAuthSelector } from '../../services/selectors/authSelector';
import { ErrorMessage, SuccessMessage } from './AllConfirmationMessages';

import Ably from 'ably';

import * as Icons from '../../assets/svg/icons'
import * as EndPoints from '../../networking/urlEndPoints'
import * as audio from '../../assets/audio';

export const playMessageSound = () => {
	const audio = document.getElementById('messageSound');
	if (audio) {
		audio.play()
			.catch(error => {
				// console.error('Failed to play audio:', error);
			});
	}
}

export const stopMessageSound = () => {
	const audio = document.getElementById('messageSound');
	if (audio) {
		audio.pause();
		audio.currentTime = 0;
	}
}

export const SOSemergencyEnableButton = ({ }) => {

	const dispatch = useCustomDispatch()

	const { pharmacyId, driverID } = useAuthSelector()
	const [showToast, setShowToast] = useState({ locationError: false, successToast: false, ApiError: false, showModal: false })

	const [mapUrl, setMapUrl] = useState(null)
	const [driverName, setDriverName] = useState(null)
	const [driverPhone, setDriverPhone] = useState(null)

	const getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const latitude = position.coords.latitude;
					const longitude = position.coords.longitude;
					const requesteData = {
						pharmacy_id: pharmacyId,
						latitude: latitude,
						longitude: longitude,
						setShowToast: setShowToast
					}
					if (latitude && longitude && pharmacyId) {
						setShowToast(prevState => ({ ...prevState, successToast: true }));
						setTimeout(() => {
							setShowToast(prevState => ({ ...prevState, successToast: false }))
						}, 2000);

						dispatch(SOS_EmergencyEnabled(requesteData))
					}
				},
				(error) => {
					setShowToast(prevState => ({ ...prevState, locationError: true }));
					setTimeout(() => {
						setShowToast(prevState => ({ ...prevState, locationError: false }));
					}, 3000);
				},
				{ enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
			);
		} else {

		}
	};

	useEffect(() => {

		const ably = new Ably.Realtime({ key: EndPoints.apiKey });
		const channel = ably.channels.get('driver-emergency-sos');

		channel.subscribe(`driver-sos-${pharmacyId}`, (message) => {
			if (message.name === `driver-sos-${pharmacyId}` && pharmacyId && driverID !== message?.data?.driver_id) {
				setShowToast(prevState => ({ ...prevState, showModal: true }));
				const lat = message.data.latitude
				const long = message.data.longitude

				let googleMapsLink = `https://www.google.com/maps?q=${lat},${long}`;
				setMapUrl(googleMapsLink)
				setDriverName(message?.data?.driver_name)
				setDriverPhone(message?.data?.driver_phone)
				playMessageSound()
			}
		})

		return () => {
			if (ably.connection.state === 'connected') {
				ably.close();
			}
		}
	}, [pharmacyId]);


	const handleHideToast = () => {
		setShowToast(prevState => ({ ...prevState, showModal: false }));
		stopMessageSound();
	};

	return (
		<React.Fragment>
			<audio id="messageSound">
				<source src={audio.alertTune} type="audio/mpeg" />
			</audio>

			<button style={{ width: '64px', position: 'relative', top: '0', borderRadius: '0 0 5px 5px', marginBottom: '-10px' }}
				className='card flex flex-col h-8 px-1.5 justify-center items-center text-lg text-white bg-red-600 font-bold' onClick={getLocation}>
				SOS
			</button>

			{showToast.showModal &&
				< div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-80 z-50">
					<div className="bg-white rounded-lg p-8 max-w-md mx-4">
						<h2 className="text-2xl font-bold mb-5">{driverName?.charAt(0)?.toUpperCase() + driverName?.slice(1)} may need your help</h2>
						<div className="flex justify-center gap-2">

							<button className="px-5 py-2.5 rounded-md bg-blue-600" onClick={stopMessageSound}>
								<a href={`tel:${driverPhone}`}>
									<Icons.PhoneIcon color='white' />
								</a>
							</button>

							<button onClick={stopMessageSound} className="px-5 py-3 font-bold bg-green-600 text-white rounded hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-400">
								<a href={mapUrl} target='_blank'>Get Location</a>
							</button>
							<button onClick={handleHideToast}
								className="px-5 py-3 font-bold bg-red-600 text-white rounded hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-400">
								X
							</button>
						</div>
					</div>
				</div>
			}

			{
				showToast.successToast &&
				<SuccessMessage message='Notification sent Successfully' setShowToast={() => setShowToast(prevState => ({ ...prevState, successToast: false }))} />
			}

			{
				showToast?.locationError &&
				<ErrorMessage message={'Please Enable the Location'} setShowToast={() => setShowToast(prevState => ({ ...prevState, locationError: false }))} />
			}

			{
				showToast?.ApiError &&
				<ErrorMessage message={'Oops! Notification failed please try again.'} setShowToast={() => setShowToast(prevState => ({ ...prevState, ApiError: false }))} />
			}

		</React.Fragment >
	)
}
