import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_supervisor_analytics } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const fetchTodaysAnalyticsDetails = createAsyncThunk('fetchTodaysAnalyticsDetails', async (params, { rejectWithValue }) => {
	try {

		const response = await Axios.get(`${driver_supervisor_analytics}?pharmacy_id=${params.pharmacy_id}`)

		const data = response.data
		return data;

	} catch (error) {
		return rejectWithValue(error.response)
	}
});