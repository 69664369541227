import { createSlice } from "@reduxjs/toolkit";
import { UploadPatientPrescripition } from "../../apis/pharmacy/uploadPatientPrescriptions";

const initialState = {
	loading: false,
	data: null,
	error: null
}

const UploadPrescripitionSlice = createSlice({
	name: 'UploadPatientPrescripition',
	initialState,

	extraReducers: (builder) => {
		builder.addCase(UploadPatientPrescripition.pending.type, (state) => {
			return {
				...state,
				loading: true,
				data: null,
				error: null
			}
		})

		builder.addCase(UploadPatientPrescripition.fulfilled.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			}
		})

		builder.addCase(UploadPatientPrescripition.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			}
		})
	}

})

export default UploadPrescripitionSlice.reducer