import { createAsyncThunk } from "@reduxjs/toolkit";
import { change_passcode } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const changePasscode = createAsyncThunk('changePasscode', async (requestedData, { rejectWithValue }) => {
    try {

        const response = await Axios.post(change_passcode, requestedData)
        const data = response.data
        return data;

    } catch (error) {
        return rejectWithValue(error.response)
    }
});