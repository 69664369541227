import React from 'react'

import { useAuthSelector } from '../../services/selectors/authSelector';
import { useCustomDispatch, useCustomLocation, useCustomNavigate } from '../../hooks';
import { driverLogout } from '../../services/apis/pharmacy/logout';

import defaultImage from '../../assets/images/usericon.jpeg'
import * as Icons from '../../assets/svg/icons';
import * as routeNames from '../../routes/routeName'

export const Header = () => {

	const dispatch = useCustomDispatch();
	const navigate = useCustomNavigate();
	const location = useCustomLocation();

	const { authLoginResponse } = useAuthSelector();
	const profileData = authLoginResponse?.data?.data?.driver_profile

	return (
		<>
			<div className="user-box flex items-center gap-4 p-4 bg-blue-900 rounded-2xl">
				<div className="avatar flex-shrink-0">
					<img className="w-14 h-14 rounded-full object-cover object-top"
						src={profileData?.avatar} onError={(e) => { e.target.src = defaultImage }}
						loading="lazy" decoding="async" alt='User Avatar' />
				</div>
				<div className="user-details flex-grow">
					<h1 className="text-lg font-bold text-white">{profileData?.name}</h1>
					<p className="text-gray-400 text-sm">{profileData?.email}</p>
				</div>
				{location.pathname === routeNames.change_default_pharmacist ?
					<div className="user-actions flex-shrink-0 flex items-center gap-3">
						<button onClick={() => navigate(routeNames.DASHBOARD_PHARMACY)}>
							<Icons.HomeIcon color='white' />
						</button>
					</div>
					:
					<div className="user-actions flex-shrink-0 flex items-center gap-3">
						<button onClick={() => dispatch(driverLogout())} >
							<Icons.HomeInIcon />
						</button>
					</div>}
			</div>
		</>)
}
