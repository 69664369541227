import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_patient_delivery } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const fetchPatientDeliveryRx = createAsyncThunk('fetchPatientDeliveryRx', async (params, { rejectWithValue }) => {
	try {

		const response = await Axios.get(`${driver_patient_delivery}/${params.id}?pharmacy_id=${params.pharmacy_id}`)
		const data = response.data
		return data;

	} catch (error) {
		return rejectWithValue(error.response)
	}
});