import React, { useState } from 'react'
import { useCustomStates } from '../../hooks/useCustomStates';
import { UploadTriplicatesPatientSign } from './patientSignature';

import { useAllSelector } from '../../services/selectors/allSelector';
import { useCustomDispatch } from '../../hooks';
import { useAuthSelector } from '../../services/selectors/authSelector';
import { UploadedTriplicatesPatientSign } from '../../services/apis/dispense/uploadTriplicatesPatientSign';

export const UploadTripilicatesPatientSigns = ({ rxData, index, image }) => {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showToast, setShowToast] = useState({ successToast: false, errorToast: false })

    const { UploadedTriplicatesPatientSignResponse } = useAllSelector()
    const { pharmacyId } = useAuthSelector()

    const dispatch = useCustomDispatch()

    const openModal = () => {
        setIsModalOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setIsModalOpen(false);
        document.body.style.overflow = 'auto';
    };

    const { pharmacistSignature,
        setPharmacistSignature,
        signatureErrors,
        setSignatureErrors,
    } = useCustomStates()

    const handleSubmitSigns = (value) => {

        const requestedData = {
            pharmacy_id: pharmacyId,
            win_rx_data_id: value.id,
            rxnumber: value.rxnumber,
            patient_sign: pharmacistSignature,
            setIsModalOpen: setIsModalOpen,
            setShowToast: setShowToast
        }
        if (!pharmacistSignature) {
            setSignatureErrors(prevState => ({ ...prevState, pharmacistSign: '1px solid red' }));
        } else {
            dispatch(UploadedTriplicatesPatientSign(requestedData))
        }
    }

    return (
        <React.Fragment>
            <div onClick={openModal} style={{ paddingTop: '7px' }} className='text-xs rounded-md px-1  flex items-center gap-2 uppercase '>
                <label>
                    Sign triplicate
                </label>
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black text-black bg-opacity-50 p-2">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-2 overflow-y-auto max-h-full">
                        <div className="flex justify-between items-center">
                            <h2 className="text-xl font-semibold">Upload Patient Sign</h2>
                            <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">&times;</button>
                        </div>
                        <hr />

                        {image ?
                            <div className='mt-2'>
                                <h1 className='font-semibold'>Prescription Image</h1>
                                <div className="rounded-md p-4 bg-gray-100 rounded-md">
                                    <div className="overflow-y-auto max-h-64">
                                        <img src={image} alt={`image-${index}`} className='border border-gray-100' />
                                    </div>
                                </div>
                            </div> : <p className='mt-2 text-gray-500 text-sm text-center'>No Prescription Image available.</p>}

                        <div className="mt-2 ">
                            <UploadTriplicatesPatientSign
                                pharmacistSignature={pharmacistSignature}
                                setPharmacistSignature={setPharmacistSignature}
                                signatureErrors={signatureErrors}
                                setSignatureErrors={setSignatureErrors}
                            />
                        </div>
                        {showToast.successToast &&
                            <p className='bg-green-500 text-white p-2 rounded-md text-sm'>Uploaded Successfully.</p>}

                        {showToast.errorToast ?
                            UploadedTriplicatesPatientSignResponse?.error?.data?.data?.error === 'Patient Sign Already Exist' ?
                                <p className='bg-red-500 text-white p-2 rounded-md text-sm'>Patient Sign Already Exist</p> :
                                <p className='bg-red-500 text-white p-2 rounded-md text-sm'>Issue Found While Updaing .Please try again</p> : null}

                        <div className="mt-4 flex justify-start gap-4">
                            <button onClick={() => handleSubmitSigns(rxData)} className="bg-red-600 w-full text-white  px-4 py-2 rounded-md hover:bg-red-600">
                                {UploadedTriplicatesPatientSignResponse?.loading ? 'Processing...' : 'Save'}
                            </button>

                            <button onClick={closeModal} className="bg-gray-600  w-full text-white px-4 py-2 rounded-md hover:bg-gray-600">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </React.Fragment>
    )
}
