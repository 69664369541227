import React, { useEffect } from 'react';

import {
	DeliverSuccessfull,
	DeliveryDoneByAnotherDriver,
	DeliveryErrorMessages,
	MissedDeliveryErrorMessages,
	PickedUpSuccessfully,
} from '../../components/common/AllConfirmationMessages';

import {
	PatientDetails,
	PatientMobileAndLocation,
} from '../../components/common/patientDetails';

import {
	MissedDeliveryConfirmation,
	RouteConfirmation
} from '../../components/common/routeConfirmation';

import {
	apiKey,
	deliveryDriverDeliveredDispense
} from '../../networking/urlEndPoints';

import {
	clearAllTodayDispenseData,
	removeTodayDispenePatientById,
	todayDispenseRemovingPageNumber
} from '../../services/slices/dispense/patientForRouteList';

import {
	onhandleConfirmDelivery,
	onhandleMissedDelivery
} from '../../utils/helper/deliveriesHelper';

import { fetchPatientlistOnBasisOfRoute } from '../../services/apis/dispense/patientsForRouteLIst';
import { useAllSelector } from '../../services/selectors/allSelector';

import { LoadingView } from '../../components/common/loader';
import { ErrorMessages } from '../../components/error/errorMessages';

import { DispenseRxData } from '../../components/common/dispenseRxData';
import { handleToggleRXData } from '../../utils/helper/helper';
import { useCustomStates } from '../../hooks/useCustomStates';

import { useAuthSelector } from '../../services/selectors/authSelector';
import { RoutePharmacistModal } from '../../components/common/routePharmacistModal';

import { SOSemergencyEnableButton } from '../../components/common/SOSemergencyEnableButton';
import { RemoveAllPatchesId } from '../../services/slices/dispense/validateReturnPatches';
import { TodayDispenseDeliveredByAnotherDriverAblyHelper } from '../../utils/helper/ablyDeliveredDispenseByOthers';

import InfiniteScroll from 'react-infinite-scroll-component';
import SearchBox from '../../components/common/searchBox';
import Cookies from 'js-cookie';

import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';
import * as Hooks from '../../hooks';
import * as routeName from '../../routes/routeName';

const TodayDispense = () => {

	const dispatch = Hooks.useCustomDispatch();
	const navigate = Hooks.useCustomNavigate();

	const location = Hooks.useCustomLocation()

	const routeId = new URLSearchParams(location.search).get('route')
	const pickRoute = Cookies.get('td')

	const { pharmacyId, driverID, pharmacyInspectionMode } = useAuthSelector()
	const { patientDataBasisOfRouteResponse, todayMissedListResponse, todaysDispenseList, validateReturnedPatchesResponse, todayMissedList } = useAllSelector();

	const { comment,
		setComment,

		cordinate,
		setCordinate,

		openIndex,
		setOpenIndex,

		isRefused,
		setIsRefused,

		patientSignature,
		setPatientSignature,

		pharmacistSignature,
		setPharmacistSignature,

		showErrorToast,
		setShowErrorToast,

		loading,
		setLoading,

		showSuccessToast,
		setShowSuccessToast,

		showComment,
		setShowComment,

		showPatientSignature,
		setShowPatientSignature,

		showPharmacistSignature,
		setShowPharmacistSignature,

		showData,
		setShowData,

		showToast,
		setShowToast,

		showConfirmationModal,
		setShowConfirmationModal,

		setMissedDeliveryErrorToast,
		missedDeliverErrorToast,

		missedDeliveryConfirmation,
		setMissedDeliveryConfirmation,

		missedData,
		setMissedData,

		signatureErrors,
		setSignatureErrors,

		covid19,
		setCovid19,

		selectedRoute,
		setSelectedRoute,

		isTransferRequest,
		setIsTransferRequest,

		loadMore,
		setLoadMore,

		delveryDoneByAnotherDriver,
		setDeliveryDoneByAnotherDriver,

		missedIndex,
		setMissedIndex,

		patientId,
		setPatientID,

		pId,
		setPId,

		showModel,
		setShowModel,

		pickupRoute,
		setPickupRoute

	} = useCustomStates()


	const handleDropdownToggle = (index, data) => {
		handleToggleRXData(
			index,
			data,
			openIndex,
			setOpenIndex,
			dispatch,
			setSignatureErrors,
			setShowData,
			RemoveAllPatchesId,
			setPatientSignature,
			setShowComment,
			setShowPatientSignature,
			setShowPharmacistSignature,
			setPharmacistSignature,
			setComment,
			setPId,
			pharmacyId
		);
	};

	useEffect(() => {
		if (selectedRoute && pharmacyId) {
			const params = {
				route: selectedRoute,
				pharmacy_id: pharmacyId,
				setLoading: setLoading,
			}
			dispatch(clearAllTodayDispenseData())
			dispatch(todayDispenseRemovingPageNumber())
			setLoading(true)
			dispatch(fetchPatientlistOnBasisOfRoute(params))
		}
		return () => { }
	}, [selectedRoute])

	useEffect(() => {
		setSelectedRoute(routeId)
		return () => { }
	}, [routeId])

	const handleConfirmDelivery = (patientData, index) => {
		onhandleConfirmDelivery(patientData,
			index,
			pharmacyId,
			null,
			patientSignature,
			pharmacistSignature,
			cordinate,
			comment,
			dispatch,
			setLoading,
			setOpenIndex,
			setShowErrorToast,
			setShowSuccessToast,
			pharmacyInspectionMode,
			isRefused,
			covid19,
			'today_dispense',
			setIsRefused,
			setSignatureErrors,
			validateReturnedPatchesResponse,
			isTransferRequest,
			setIsTransferRequest,
		);
	}

	const handleMissedDelivery = () => {
		onhandleMissedDelivery(pharmacyId,
			missedData,
			missedIndex,
			dispatch,
			setOpenIndex,
			setMissedDeliveryErrorToast,
			setMissedDeliveryConfirmation,
			"today_missed",
			"today_dispense")
	}

	const fetchMoreData = () => {
		if (patientDataBasisOfRouteResponse?.currentPage < patientDataBasisOfRouteResponse?.totalPages) {
			setLoadMore(true)
			if (selectedRoute && pharmacyId) {
				const params = {
					route: selectedRoute,
					pharmacy_id: pharmacyId,
					page: patientDataBasisOfRouteResponse?.currentPage + 1,
					setLoadMore: setLoadMore
				}
				setLoading(false)
				dispatch(fetchPatientlistOnBasisOfRoute(params))
			}
		}
	}


	useEffect(() => {
		setTimeout(() => {
			Cookies.set('td', false)
		}, 2000);
		return () => { }
	}, [patientDataBasisOfRouteResponse])

	useEffect(() => {
		setPickupRoute(pickRoute)
		return () => { }
	}, [pickRoute])

	useEffect(() => {
		if (patientDataBasisOfRouteResponse?.loading) {
			setShowModel(false)
		} else if (patientDataBasisOfRouteResponse?.data?.length === 0) {
			setShowModel(true)
		} else {
			setShowModel(false)
		}
	}, [patientDataBasisOfRouteResponse])

	return (
		<>
			{patientDataBasisOfRouteResponse?.data?.length > 0 &&
				<InfiniteScroll
					dataLength={patientDataBasisOfRouteResponse?.data?.length}
					next={fetchMoreData}
					hasMore={patientDataBasisOfRouteResponse?.currentPage <= patientDataBasisOfRouteResponse?.totalPages}
				/>
			}

			<div className="app-wrapper flex justify-center">
				<div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden ">
					<div className='flex items-center justify-center'>
						<SOSemergencyEnableButton />
					</div>

					<div className="relative p-4">
						<div className="card bg-white p-4 rounded-lg shadow-sm">
							<div className="flex items-center justify-between gap-3">
								<div>
									<Common.TopBar pharmacyId={pharmacyId} />
								</div>
								<div className='flex items-center justify-center btnnn pt-4' onClick={() => setShowConfirmationModal(true)} >
									<Icons.HomeIcon />
								</div>
							</div>
						</div>

						<div className="title-row my-2" >
							<h1 className="text-xl font-bold">{selectedRoute} Dispense List</h1>
						</div>

						<div className='flex'>
							{todayMissedListResponse?.error !== null ? null :
								todayMissedList?.length !== 0 &&
								<button onClick={() => navigate(`${routeName.Missed_Route}`)}
									className="p-4 mb-3 mr-2 h-10 flex items-center justify-center text-sm font-medium  text-white w-full rounded-lg"
									style={{ backgroundColor: '#c12323' }}>Missed Bag</button>
							}

							<button className="p-4 mb-3 h-10 flex items-center justify-center text-sm font-medium text-white w-full rounded-lg"
								style={{ backgroundColor: '#FEAE02' }} onClick={() => navigate(`${routeName.driver_today_pending}`)}>
								Pending Bag
							</button>
						</div>

						<div className="flex gap-3 mb-3">
							<SearchBox pharmacyId={pharmacyId} />
						</div>

						<div className="routes-list">
							{(patientDataBasisOfRouteResponse?.loading && loading) ?

								<div className='flex justify-center pb-4 h-96'>
									<LoadingView />
								</div> :

								patientDataBasisOfRouteResponse?.error !== null ?

									<div className='flex items-center justify-center pb-4 mb-4 h-96'>
										<ErrorMessages error={'Oops! Got an issue while fetching Data.Please try again!'} />
									</div> :

									(todaysDispenseList?.length === 0 && !patientDataBasisOfRouteResponse?.loading) ?
										<p className='flex justify-center pb-4 h-96'>
											All Deliveries Done for this Route.
										</p>
										:

										todaysDispenseList?.map((data, index) => (
											<React.Fragment key={index}>

												<div className="card bg-white p-4 rounded-lg shadow-sm mb-3" id={`patientCard-${index}`}>

													<PatientDetails
														data={data}
														patientImg={data.profile_image}
														totalRxData={data?.patients_win_rx_data?.length}
														index={index} />

													<div className="flex items-center justify-between mt-4">
														<PatientMobileAndLocation data={data}
															setIsTransferRequest={setIsTransferRequest}
															isTransferRequest={isTransferRequest}
															index={index}
														/>

														<button className="p-2.5 rounded-md bg-gray-100" onClick={() => handleDropdownToggle(index, data)}>
															{openIndex === index ?
																<Icons.DropdownBoxIcon2 /> :
																<Icons.DropdownBoxIcon1 />}
														</button>
													</div>

													{openIndex === index &&
														<>
															<DispenseRxData
																rxData={data?.patients_win_rx_data}
																patientData={data} />

															{patientId !== data?.id ? <>

																{showData &&
																	<React.Fragment>
																		<Common.Comment
																			showComment={showComment}
																			setShowComment={setShowComment}
																			setComment={setComment} comment={comment} />

																		<Common.PatientSignature
																			patientSignature={patientSignature}
																			setPatientSignature={setPatientSignature}
																			showPatientSignature={showPatientSignature}
																			setShowPatientSignature={setShowPatientSignature}
																			isRefused={isRefused}
																			setIsRefused={setIsRefused}
																			signatureErrors={signatureErrors}
																			setSignatureErrors={setSignatureErrors}
																			covid19={covid19}
																			setCovid19={setCovid19}
																		/>

																		<Common.PharmacistSignature
																			pharmacistSignature={pharmacistSignature}
																			setPharmacistSignature={setPharmacistSignature}
																			showPharmacistSignature={showPharmacistSignature}
																			setShowPharmacistSignature={setShowPharmacistSignature}
																			signatureErrors={signatureErrors}
																			setSignatureErrors={setSignatureErrors}
																		/>

																	</React.Fragment>
																}

																<div className="flex items-center gap-3">

																	{!showData &&
																		<button className="px-4 flex-1 py-2 bg-red-500 text-white rounded-md"
																			onClick={() => {
																				return setMissedData(data),
																					setMissedIndex(index),
																					setMissedDeliveryConfirmation(true)
																			}}>
																			<p className='flex justify-center items-center'>
																				Missed</p>
																		</button>}

																	{showData ?
																		validateReturnedPatchesResponse?.loading ? null :
																			<button className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md"
																				onClick={() => handleConfirmDelivery(data, index)}>
																				Continue
																			</button>
																		:
																		<button
																			className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md"
																			onClick={() => {
																				setShowData(true);
																				setTimeout(() => {
																					const windowHeight = window.innerHeight;
																					const scrollAmount = windowHeight / 2;
																					window.scrollBy({ top: scrollAmount, behavior: "smooth" });
																				}, 100);
																			}}
																		>
																			Deliver
																		</button>}

																</div> </> : null}
														</>
													}
												</div>
											</React.Fragment>))}
						</div>

						{loadMore &&
							<div className='flex mb-2'>
								<div className="loaderss"></div>
							</div>}

					</div>

					{(patientDataBasisOfRouteResponse?.loading && loading && pickupRoute === 'true') && <PickedUpSuccessfully setShowToast={setShowToast} />}

					{showErrorToast && <DeliveryErrorMessages setShowErrorToast={setShowErrorToast} />}
					{showSuccessToast && <DeliverSuccessfull setShowSuccessToast={setShowSuccessToast} />}
					{missedDeliverErrorToast && <MissedDeliveryErrorMessages setMissedDeliveryErrorToast={setMissedDeliveryErrorToast} />}
					{delveryDoneByAnotherDriver && <DeliveryDoneByAnotherDriver setMissedDeliveryErrorToast={setDeliveryDoneByAnotherDriver} />}

				</div>

				{
					showConfirmationModal &&
					<RouteConfirmation
						setShowConfirmationModal={setShowConfirmationModal}
						showConfirmationModal={showConfirmationModal}
						pharmacyId={pharmacyId} />
				}


				{
					missedDeliveryConfirmation &&
					<MissedDeliveryConfirmation
						setShowConfirmationModal={setMissedDeliveryConfirmation}
						showConfirmationModal={missedDeliveryConfirmation}
						pharmacyId={pharmacyId}
						handleMissedDelivery={handleMissedDelivery}
						missedData={missedData} />
				}

			</div>

			<RoutePharmacistModal
				showModel={showModel}
				setShowModel={setShowModel}
				isDelivered={false} route={selectedRoute} />


			<TodayDispenseDeliveredByAnotherDriverAblyHelper
				apiKey={apiKey}
				deliveryDriverDeliveredDispense={deliveryDriverDeliveredDispense}
				pharmacyId={pharmacyId}
				driverID={driverID}
				pId={pId}
				routeName={routeName}
				location={location}
				setPatientID={setPatientID}
				setDeliveryDoneByAnotherDriver={setDeliveryDoneByAnotherDriver}
				dispatch={dispatch}
				removeTodayDispenePatientById={removeTodayDispenePatientById}
				setOpenIndex={setOpenIndex}
				setPId={setPId}
				setCordinate={setCordinate}
			/>


		</>
	)
}

export default TodayDispense
