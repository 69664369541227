import React from 'react'

export const ErrorMessages = ({ error }) => {

    return (
        <>
            <div className="p-2 mt-4 py-2 font-semibold text-sm text-red-700 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                <span className="flex justify-center font-medium">{error}</span>
            </div>

        </>
    )
}

export const SuccessMessage = ({ error }) => {

    return (
        <>
            <div className="p-2 mt-4 py-2 font-semibold text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                <span className="flex justify-center font-medium">{error}</span>
            </div>

        </>
    )
}
