import { createSlice } from "@reduxjs/toolkit";
import { authSelectedPharmacist } from "../../apis/auth/selectedPharmacistID";

const initialState = {
	loading: false,
	data: null,
	error: null
}

const authSelectedPharmacistSlice = createSlice({
	name: 'authSelectedPharmacist',
	initialState,

	extraReducers: (builder) => {
		builder.addCase(authSelectedPharmacist.pending.type, (state) => {
			return {
				...state,
				loading: true,
				data: null,
				error: null
			}
		})

		builder.addCase(authSelectedPharmacist.fulfilled.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			}
		})

		builder.addCase(authSelectedPharmacist.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: null,
			}
		})
	}

})

export default authSelectedPharmacistSlice.reducer