import React, { useEffect, useRef, useState } from 'react';

import { Loader } from '../components/common/loader';
import { authLogin } from '../services/apis/auth/authLogin';
import { useAuthSelector } from '../services/selectors/authSelector';
import { DisplayCurrentDay, getCurrentDay } from '../utils/helper/helper';

import * as Icons from '../assets/svg/icons';
import * as Hooks from '../hooks';
import * as routeNames from '../routes/routeName';

const SignIn = () => {

	const initialFormData = { email: '', password: '', passcode: '' };
	const [userDetail, setUserDetail] = useState(initialFormData);
	const [errorMessage, setErrorMessage] = useState({ email: '', password: '', passcode: '' });
	const [showPasscode, setShowPasscode] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [currentDate, setCurrentDate] = useState(getCurrentDay())

	useEffect(() => {
		DisplayCurrentDay(setCurrentDate)
	}, [])

	const inputRef = useRef();
	const input2Ref = useRef();

	const dispatch = Hooks.useCustomDispatch();
	const navigate = Hooks.useCustomNavigate();

	const { authLoginResponse } = useAuthSelector();
	const authError = authLoginResponse?.error?.data?.data

	const handalUserData = (e) => {
		const { name, value } = e.target;

		setUserDetail({
			...userDetail,
			[name]: value
		})
		const errorFields = ['email', 'password', 'passcode'];

		errorFields.forEach(field => {
			if (name === field) {
				setErrorMessage({ ...errorMessage, [field]: '' });
			}
		});

	}

	const validateField = (fieldName, value, requiredMessage) => (!value ? requiredMessage : '');

	const handleSubmit = async (e) => {
		e.preventDefault();
		const specificValidations = {
			email: 'Please Enter Your Email',
			password: 'Please Enter Your Password',
			passcode: 'Please Enter Your Passcode'
		};

		const allValidations = { ...specificValidations };

		const newErrorMessages = Object.fromEntries(
			Object.entries(allValidations).map(([field, message]) => [
				field,
				validateField(field, userDetail[field], message),
			])
		);

		setErrorMessage({
			...errorMessage,
			...newErrorMessages,
		});

		const allDataPresent = Object.values(newErrorMessages).every((message) => message === '');

		try {
			if (allDataPresent) {
				if (userDetail.passcode.length < 4) {
					setErrorMessage({ ...errorMessage, passcode: 'Passcode Should be 4 Digits', email: '', password: '' })
					return
				} else {
					const requesteData = {
						email: userDetail?.email,
						password: userDetail?.password,
						passcode: userDetail?.passcode,
						navigate: navigate,
						dispatch: dispatch
					}
					await dispatch(authLogin(requesteData))
				}
			}
		} catch (error) {
		}
	};

	useEffect(() => {
		if (authLoginResponse?.loading) {
			setErrorMessage({
				...errorMessage,
				password: '',
				email: '',
				passcode: ''
			});
		} else if (authLoginResponse?.error !== null && authError) {
			const { error } = authError;
			const newErrorMessage = { ...errorMessage };

			if (error === 'Invalid Login Credentials.') {
				newErrorMessage.password = 'Invalid Password';
				newErrorMessage.email = 'Invalid Email';
			} else if (error === 'Invalid passcode') {
				newErrorMessage.passcode = authLoginResponse?.error?.data?.data?.error;
			} else if (error?.email?.[0] === 'The email must be a valid email address.') {
				newErrorMessage.email = error?.email?.[0];
			}

			setErrorMessage(newErrorMessage);
		}
	}, [authLoginResponse]);


	useEffect(() => {
		setErrorMessage({
			...errorMessage,
			passcode: '', email: '', password: ''
		})
		return () => { }
	}, [])

	return (
		<>

			<div className="app-wrapper flex justify-center h-screen">
				<div className="app-screen w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-y-hidden overflow-x-hidden ">
					<div className="relative z-10 signin-screen w-full p-3 pt-16">
						<div className="relative mb-3">
							<span className="bg-black text-white text-xs font-medium px-2.5 py-1 rounded-md">
								{currentDate}
							</span>
						</div>
						<h1 className="text-xl font-bold text-primary mb-2">Are you ready?</h1>
						<p>Let's dive in now </p>
						<div className="form-signin mt-10">
							<form className="group">
								<div className="relative form-group">
									<div className="relative">
										<div className="absolute top-3 start-0 flex items-center ps-3.5 pointer-events-none">
											<Icons.EmailIcon />
										</div>
										<input onChange={handalUserData} type="email" id="email" autoComplete="username" name="email" defaultValue={userDetail?.email}
											className={`pl-8 bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full ps-10 p-2.5 [&:not(:placeholder-shown):invalid~span]:block invalid:[&:not(:placeholder-shown)]:border-red-500 valid:[&:not(:placeholder-shown)]:border-green-500  ${errorMessage?.email ? "border-red-600" :
												userDetail?.email ? "border-green-600" : null
												}  `}
											placeholder={` ${errorMessage?.email ? "" : "Enter email address"}`}
										/>
									</div>
									{errorMessage?.email &&
										<p className='text-sm text-red-600'>{errorMessage?.email}</p>}
								</div>
								<div className="relative">
									<div className="relative">
										<div className="relative top-8 start-0 flex justify-between items-center ps-3.5 pointer-events-none">
											<Icons.PasswordIcon />
											<span className='pr-2' style={{ pointerEvents: "auto" }} onClick={(e) => {
												e.stopPropagation();
												setShowPassword(!showPassword);
											}}>
												{showPassword ? <Icons.OpenEyeIcon /> : <Icons.CloseEyeIcon />}
											</span>
										</div>


										<input ref={input2Ref} onChange={handalUserData} type={showPassword ? 'text' : 'password'} id="password" autoComplete="new-password" name="password" defaultValue={userDetail?.password}
											className={`pl-8 bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full ps-10 p-2.5 [&:not(:placeholder-shown):invalid~span]:block invalid:[&:not(:placeholder-shown)]:border-red-500 valid:[&:not(:placeholder-shown)]:border-green-500 ${errorMessage?.password ? "border-red-600" :
												userDetail?.password ? "border-green-600" : null
												}  `}
											placeholder={` ${errorMessage?.password ? "" : "Enter password"}`} pattern={7} />
										{errorMessage?.password &&
											<p className='text-sm text-red-600'>{errorMessage?.password}</p>}

									</div>
								</div>
								<div className="relative">
									<div className="relative">
										<div className="relative top-8 start-0 flex justify-between items-center ps-3.5 pointer-events-none">
											<Icons.PasscodeIcon />
											<span className='pr-2' style={{ pointerEvents: "auto" }} onClick={(e) => {
												e.stopPropagation();
												setShowPasscode(!showPasscode);
											}}>
												{showPasscode ? <Icons.OpenEyeIcon /> : <Icons.CloseEyeIcon />}
											</span>
										</div>

										<input
											onInput={(e) => {
												const inputValue = e.target.value.replace(/\D/g, '');
												e.target.value = inputValue;
												handalUserData(e);
											}}
											autoComplete="new-password"
											ref={inputRef}
											type={showPasscode ? 'text' : 'password'}
											id="passcode" name="passcode" value={userDetail?.passcode}
											className={`pl-8 bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full ps-10 p-2.5 
											[&:not(:placeholder-shown):invalid~span]:block invalid:[&:not(:placeholder-shown)]:border-red-500 valid:[&:not(:placeholder-shown)]:border-green-500
											${errorMessage?.passcode ? "border-red-600" :
													userDetail?.passcode ? "border-green-600" : null
												} `}
											placeholder={` ${errorMessage?.passcode ? "" : "Enter passcode"}`} maxLength={4} inputMode="numeric" />
									</div>
								</div>
								{errorMessage?.passcode &&
									<p className='text-sm text-red-600'>{errorMessage?.passcode}</p>}
								<div className="relative mb-4 mt-6">
									<button onClick={(e) => !authLoginResponse?.loading && handleSubmit(e)} type="submit"
										className="bttn text-white bg-blue-700 hover:bg-blue-800 w-full focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-lg px-5 py-3 me-2 mb-2 group-invalid:pointer-events-none group-invalid:opacity-30">
										{authLoginResponse?.loading ? (
											<p className='flex items-center justify-center gap-2'><Loader /></p>
										) : (
											'Sign in'
										)}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}

export default SignIn 
