import React, {
    useEffect,
    useState
} from 'react';

import { Loader, LoadingView } from '../../components/common/loader';
import { ErrorMessages } from '../../components/error/errorMessages';
import { useAllSelector } from '../../services/selectors/allSelector';

import { useAuthSelector } from '../../services/selectors/authSelector';
import { authToken } from '../../storage/authToken';
import { fetchPharmacistOfPharmacy } from '../../services/apis/dispense/pharmacistOfPharmacy';

import { useSuperVisorSelector } from '../../services/selectors/allSuperVisorSelector';
import { UpdatedDefaultPharmacist } from '../../services/apis/supervisor/updatedDefaultPharmacist';
import { ErrorMessage, SuccessMessage } from '../../components/common/AllConfirmationMessages';

import * as Common from '../../components/common';
import * as Hooks from '../../hooks'
import * as Icons from '../../assets/svg/icons';
import * as routeNames from '../../routes/routeName'

const ChangeDefaultPharmacist = () => {

    const dispatch = Hooks.useCustomDispatch();
    const navigate = Hooks.useCustomNavigate();

    const token = authToken();

    const { pharmacyId, isSupervisor } = useAuthSelector()
    const { pharmacistOfPharmacyListResponse } = useAllSelector();
    const { updatedDefaultPharmacistResponse } = useSuperVisorSelector()

    const pharmacistList = pharmacistOfPharmacyListResponse?.data?.data?.pharmacist
    const defaultPharmacist = pharmacistOfPharmacyListResponse?.data?.data?.default_pharmacist

    const [selectedPharmacist, setSelectedPharmacist] = useState('');
    const [showToast, setShowToast] = useState({ successToast: false, errorToast: false })

    const handlePharmacySelect = (id) => {
        setSelectedPharmacist(id);
    };

    useEffect(() => {
        if (token && isSupervisor === 1) {
            dispatch(fetchPharmacistOfPharmacy(pharmacyId))
        }
        return () => { }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleContinuePharmacyProcessing = async () => {
        try {
            const uploadData = {
                pharmacy_id: pharmacyId,
                pharmacist_id: selectedPharmacist.id,
                setShowToast: setShowToast
            }
            if (pharmacyId && selectedPharmacist?.id)
                dispatch(UpdatedDefaultPharmacist(uploadData))
        } catch {

        }
    }


    return (
        <>
            <div className="app-wrapper flex justify-center h-screen">
                <div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden ">

                    {/* <div className="relative p-4">
                        <Common.Header />
                        <div className="title-row my-5">
                        </div> */}

                    <div className="relative p-4" >
                        <div className="card bg-white p-4 rounded-lg shadow-sm">

                            <div className="flex items-center justify-between gap-3">
                                <div>
                                    <Common.TopBar pharmacyId={pharmacyId} />
                                </div>

                                <div className='flex items-center justify-center btnnn pt-4' onClick={() => navigate(`${routeNames.DASHBOARD_PHARMACY}`)}>
                                    <Icons.HomeIcon />
                                </div>
                            </div>
                        </div>


                        {pharmacistOfPharmacyListResponse?.loading ?
                            <div className='flex items-center justify-center pb-4 mb-4'>
                                <LoadingView />
                            </div>
                            :
                            pharmacistOfPharmacyListResponse?.error !== null ?
                                <div className='flex items-center justify-center pb-4 mb-4'>
                                    <ErrorMessages error={'Oops! Got an issue whille fetching Pharmacies Detatils.Please try to reload the page again.'} />
                                </div>
                                :
                                pharmacistList?.length === 0 ?
                                    <div className="flex items-center justify-center pb-4 mb-4">
                                        <div className="flex items-center justify-center pb-4 mb-4">
                                            <p className='text-gray-800'>Looks like there are No Pharmacist right now. Hang tight, we're on the lookout for more options!</p>
                                        </div>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div className='bg-gray-100 mt-4 p-4 rounded-md'>
                                            {pharmacistList?.map((value, index) => (
                                                <div key={index} className=''>
                                                    <label htmlFor={`item-checkbox-${index}`}
                                                        className="cursor-pointer drop-shadow-md item-box flex items-center gap-4 p-4 bg-white rounded-2xl mb-4">
                                                        <div className="avatar flex-shrink-0">
                                                            <div
                                                                className="w-14 h-14 flex items-center justify-center rounded-full object-cover object-top">
                                                                <img className="w-full rounded-lg object-cover object-top" alt=''
                                                                    src={value?.photo} style={{ width: '56px', height: '56px' }} />
                                                            </div>
                                                        </div>
                                                        <div className="item-details flex-grow">
                                                            <h1 className="text-lg font-bold text-gray-700">{value?.name.charAt(0).toUpperCase() + value?.name.slice(1)} {value?.last_name}</h1>
                                                            <h1 className="text-sm text-gray-400 break-all">{value?.email}</h1>
                                                        </div>
                                                        <div className="item-actions relative flex justify-end items-end flex-shrink-0 flex items-center gap-3">
                                                            <input id={`item-checkbox-${index}`} type="radio" value={selectedPharmacist} name="pharmacy-selection"
                                                                className="peer" onChange={() => handlePharmacySelect(value)} defaultChecked={defaultPharmacist?.id === value.id} />
                                                        </div>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="relative mb-4 mt-6">
                                            <button type="button" onClick={() => updatedDefaultPharmacistResponse?.loading ? null : handleContinuePharmacyProcessing()}
                                                className={`text-white ${selectedPharmacist ? 'bg-blue-700 hover:bg-blue-800' : 'bg-blue-100'} 
															w-full flex items-center justify-center focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-lg px-5 py-3 me-2 mb-2 
															group-invalid:pointer-events-none group-invalid:opacity-30`}>
                                                {updatedDefaultPharmacistResponse?.loading ? <Loader /> : 'Continue'}
                                            </button>
                                        </div>
                                    </React.Fragment>
                        }
                    </div>
                </div>
            </div>

            {showToast.successToast &&
                <SuccessMessage message={'Pharmacist Updated Sucessfully'} setShowToast={() => setShowToast(prevState => ({ ...prevState, successToast: false }))} />}

            {showToast.errorToast &&
                <ErrorMessage message={'Oops Got an issue while updating Pharmacist.please try again '} setShowToast={() => setShowToast(prevState => ({ ...prevState, errorToast: false }))} />
            }


        </>)
}

export default ChangeDefaultPharmacist