import { createSlice } from "@reduxjs/toolkit";
import { ValidateReturnedPatches } from "../../apis/dispense/validateReturnPatches";

const initialState = {
    loading: false,
    data: null,
    error: null,
    previousPatchIds: [],
    isReturn: {},
    winrx_data_id: []
}

const ValidateReturnedPatchesSlice = createSlice({
    name: 'ValidateReturnedPatches',
    initialState,

    reducers: {
        RemoveAllPatchesId(state) {
            state.previousPatchIds = []
            state.data = null
            state.isReturn = {}
            state.winrx_data_id = []
        }
    },

    extraReducers: (builder) => {
        builder.addCase(ValidateReturnedPatches.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            }
        })

        builder.addCase(ValidateReturnedPatches.fulfilled.type, (state, action) => {

            const { previous_patch_id, winrx_data_id, is_return } = action?.payload?.data;

            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
                previousPatchIds: state?.previousPatchIds?.includes(previous_patch_id)
                    ? state?.previousPatchIds
                    : [...state.previousPatchIds, previous_patch_id],
                isReturn: {
                    ...state.isReturn,
                    [winrx_data_id]: {
                        previous_patch_id: previous_patch_id,
                        answer: is_return
                    }
                },
                winrx_data_id: state?.winrx_data_id?.includes(winrx_data_id)
                    ? state?.winrx_data_id
                    : [...state.winrx_data_id, winrx_data_id],
            };

        })

        builder.addCase(ValidateReturnedPatches.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        })
    }

})

export const { RemoveAllPatchesId } = ValidateReturnedPatchesSlice.actions;

export default ValidateReturnedPatchesSlice.reducer