import { createAsyncThunk } from "@reduxjs/toolkit";
import { today_missed } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const fetchFinalMissed = createAsyncThunk('fetchFinalMissed', async (params, { rejectWithValue }) => {
	try {

		const response = await Axios.get(today_missed, {
			params: {
				pharmacy_id: params.pharmacy_id,
				status: params.status
			}
		})
		const data = response.data
		return data;

	} catch (error) {
		return rejectWithValue(error.response)
	}
});