import React, { useState } from 'react'
import { useCustomDispatch } from '../../hooks'
import { useAuthSelector } from '../../services/selectors/authSelector'

import { ValidateReturnedPatches } from '../../services/apis/dispense/validateReturnPatches'
import { ErrorMessage } from './AllConfirmationMessages'

import { UploadTripilicatesPatientSigns } from './uploadTripilicatesPatientSigns'
import { GenrateRxLink } from '../../services/apis/dispense/genrateRxLink'
import { LoadingView } from './loader'
import { useAllSelector } from '../../services/selectors/allSelector'

import medicalHistory from '../../assets/images/medical-history.gif'

export const DispenseRxData = ({ rxData, patientData }) => {

	const [image, setImage] = useState('')

	return (
		<React.Fragment>
			{rxData
				?.slice()
				?.sort((a, b) => {
					if (a?.is_din_matched?.status === 1 && b?.is_din_matched?.status !== 1) {
						return -1;
					} else if (a?.is_din_matched?.status !== 1 && b?.is_din_matched?.status === 1) {
						return 1;
					} else {
						return 0;
					}
				})
				?.map((value, index) => (
					<React.Fragment key={index}>

						<div className="mt-2 mb-2 break-all">
							<div className="p-2 rounded-md"
								style={{ backgroundColor: value?.is_din_matched?.status === 1 && value?.is_din_matched?.color, border: '1px solid lightgray' }}>

								<div className='flex gap-2'>
									<p className="text-sm font-normal text-white rounded-md p-1 " style={{ fontSize: '12px', height: '29px', background: 'black', paddingLeft: '10px', paddingRight: '10px' }}>
										{value?.rxnumber}
									</p>

									{value.rx_witness == 'Y' &&
										<p className="text-sm font-normal text-white bg-yellow-500 rounded-md p-1 " style={{ fontSize: '12px', height: '29px', background: '#6A057B', paddingLeft: '10px', paddingRight: '10px' }}>
											Witness Required
										</p>}

									<GenrateRxNumberDataPDF value={value} />

									{value?.is_triplicates_pending?.is_triplicates &&
										<div className='text-sm font-normal text-white bg-red-500 rounded-md' onClick={() => setImage(value?.is_triplicates_pending?.precription_image)}>
											<UploadTripilicatesPatientSigns
												index={index}
												rxData={value}
												image={image} />
										</div>
									}

								</div>

								<h3 className="text-sm font-medium my-1">[{value?.refill_reqty}]{value?.drug}</h3>
								<p className="text-sm text-gray-900">{value?.rxsig}</p>
								<p className='pt-1' dangerouslySetInnerHTML={{ __html: value.prescriptionExpiryDay }} />
								{value.note && <p className=' text-gray-700 bg-gray-200 p-1 pl-2 rounded-md' style={{ color: 'black', fontSize: '10px' }}>{value?.note}</p>}

								{value?.is_din_matched?.is_fentanyl === 1 && <div className=''>
									<ReturnedPatches
										index={index}
										rxData={value}
									/>
								</div>}
							</div>
						</div>

					</React.Fragment>
				))
			}
		</React.Fragment >
	)
}


export const GenrateRxNumberDataPDF = ({ value }) => {

	const { pharmacyId } = useAuthSelector()
	const { GenrateRxLinkResponse } = useAllSelector()
	const dispatch = useCustomDispatch()

	const handleGenrateRxLink = (value) => {
		const requestedData = {
			pharmacy_id: pharmacyId,
			rxnum: value.rxnumber,
			rx_br: value.rx_br
		}
		dispatch(GenrateRxLink(requestedData))
	}

	return (
		<React.Fragment>
			{value?.rx_br &&
				<p className="p-1" onClick={() => handleGenrateRxLink(value)}>
					{/* <img src={medicalHistory} alt='' className='w-6 h-6 rounded-sm' /> */}
					<svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 0 64 64" width="512" height="512"><g id="File"><path d="M18,3V24H8V5a2.006,2.006,0,0,1,2-2Z" style={{ fill: '#bcbec0' }} /><path d="M25,3V25H14V5a2.006,2.006,0,0,1,2-2Z" style={{ fill: '#d1d3d4' }} /><path d="M56,5V25H20V5a2.006,2.006,0,0,1,2-2H54A2.006,2.006,0,0,1,56,5Z" style={{ fill: '#e6e7e8' }} /><path d="M61,27V57a4,4,0,0,1-4,4H7a4,4,0,0,1-4-4V19a2.006,2.006,0,0,1,2-2H24.22a1,1,0,0,1,.97.76l1.62,6.48a1,1,0,0,0,.97.76H59A2.006,2.006,0,0,1,61,27Z" style={{ fill: '#ffa733' }} /><circle cx="32" cy="43" r="10" style={{ fill: '#ffd422' }} /><polygon points="38 41 38 45 34 45 34 49 30 49 30 45 26 45 26 41 30 41 30 37 34 37 34 41 38 41" style={{ fill: '#ff5023' }} /><polygon points="52 3 52 11 49 9 46 11 46 3 52 3" style={{ fill: '#ff5023' }} /><path d="M41.707,13.707l-1.414-1.414L38,14.586l-.826-.826A3,3,0,0,0,36,8H32a1,1,0,0,0-1,1v8h2V14h1.586l2,2-2.293,2.293,1.414,1.414L38,17.414l2.293,2.293,1.414-1.414L39.414,16ZM36,12H33V10h3a1,1,0,0,1,0,2Z" style={{ fill: '#1e81ce' }} /></g></svg>
				</p>}
			{GenrateRxLinkResponse?.loading &&
				<div className="fixed top-0 left-0 w-full h-full bg-gray-800 opacity-20 flex justify-center items-center z-50">
					<LoadingView />
				</div>}

		</React.Fragment>
	)
}





export const CounsellingNewRxData = ({ rxData,
	counsellingOption,
	handleRadioChange,
	patient_name
}) => {

	const radioOptions = [
		{ value: 'Yes', label: <><strong>Yes,</strong> Counselling Provided</> },
		{ value: 'No', label: <><strong>No,</strong> Counselling not Provided</> },
		{ value: 'Refused', label: <>Patient <strong>Refused</strong> Counselling</> }
	];

	return (
		<React.Fragment>
			{rxData
				?.slice()
				?.sort((a, b) => {
					if (a?.is_din_matched?.status === 1 && b?.is_din_matched?.status !== 1) {
						return -1;
					} else if (a?.is_din_matched?.status !== 1 && b?.is_din_matched?.status === 1) {
						return 1;
					} else {
						return 0;
					}
				})
				?.map((value, index) => (
					<React.Fragment key={index}>
						<div className="mt-2 mb-2 break-all">
							<div className="p-2 rounded-md" style={{ backgroundColor: value?.is_din_matched?.status === 1 && value?.is_din_matched?.color, border: '1px solid lightgray' }}>
								<span className="text-sm font-normal text-white bg-green-500 rounded-md p-1"  style={{ fontSize: '12px', height: '29px', background: 'black', paddingLeft: '10px', paddingRight: '10px' }}>
									{value?.rxnumber}
								</span>
								<h3 className="text-sm font-medium my-1">
									[{value?.refill_reqty}]{value?.drug}
								</h3>
								<p className="text-sm text-gray-900">{value?.rxsig}</p>
								<p className="pt-1" dangerouslySetInnerHTML={{ __html: value.prescriptionExpiryDay }} />
								{value.note && <p className=' text-gray-700 bg-gray-200 p-1 pl-2 rounded-md' style={{ color: 'black', fontSize: '10px' }}>{value?.note}</p>}
							</div>
						</div>

						{radioOptions?.map((option, idx) => (
							<div key={idx} className="text-sm mt-1 bg-gray-50 rounded-md px-3 py-2 flex items-center gap-2 uppercase w-full">
								<input
									type="radio"
									name={`counselling-${index}`}
									value={option.value}
									checked={counsellingOption[index] === option.value}
									onChange={(e) => handleRadioChange(e, index, value, patient_name)}
									className="outline-none"
									id={`${option.value.toLowerCase()}-${index}`}
									style={{ outline: 'none', border: '0.1px solid gray', borderRadius: '4px' }}
								/>
								<label htmlFor={`${option.value.toLowerCase()}-${index}`}>{option.label}</label>
							</div>
						))}

					</React.Fragment>
				))}
		</React.Fragment>
	)
}


export const PendingPharmacistSignRxData = ({ rxData }) => {

	return (
		<React.Fragment>
			{rxData
				?.slice()
				?.sort((a, b) => {
					if (a?.is_din_matched?.status === 1 && b?.is_din_matched?.status !== 1) {
						return -1;
					} else if (a?.is_din_matched?.status !== 1 && b?.is_din_matched?.status === 1) {
						return 1;
					} else {
						return 0;
					}
				})?.map((value, index) => (
					<React.Fragment key={index}>
						{value?.is_din_matched?.status === 0 ? null :
							<div className="mt-2 mb-2 break-all">
								<div className="p-2 rounded-md" style={{ backgroundColor: value?.is_din_matched?.status === 1 && value?.is_din_matched?.color, border: '1px solid lightgray' }}>

									<div className='flex'>
										<span className="text-sm font-normal text-white bg-green-500 rounded-md p-1 "  style={{ fontSize: '12px', height: '29px', background: 'black', paddingLeft: '10px', paddingRight: '10px' }}>{value?.rxnumber}</span>

										<span className='text-xs  bg-gray-50 rounded-md px-3 py-1 flex items-center gap-2 uppercase ml-2'>
											<input type='checkbox' className='outline-none' id={`signatures-${index}`} style={{ outline: 'none', border: '0.1px solid gray', borderRadius: '4px', }} />
											<label htmlFor={`signatures-${index}`}>
												Ignore
											</label>
										</span>
									</div>

									<h3 className="text-sm font-medium my-1">[{value?.refill_reqty}]{value?.drug}</h3>
									<p className="text-sm text-gray-900">{value?.rxsig}</p>
									<p className='pt-1' dangerouslySetInnerHTML={{ __html: value?.prescriptionExpiryDay }} />
									{value.note && <p className=' text-gray-700 bg-gray-200 p-1 pl-2 rounded-md' style={{ color: 'black', fontSize: '10px' }}>{value?.note}</p>}
								</div>
							</div>}
					</React.Fragment>
				))}
		</React.Fragment>
	)
}

export const ReturnedPatches = ({ index, rxData }) => {

	const [selectedValue, setSelectedValue] = useState(null);
	const dispatch = useCustomDispatch()
	const [showToast, setShowToast] = useState({ successToast: false, errorToast: false })

	const { pharmacyId } = useAuthSelector()

	const handleRadioChange = (e) => {
		const value = e.target.value
		setSelectedValue(value)
		const requestedData = {
			pharmacy_id: pharmacyId,
			patient_id: rxData.patient_id,
			rxnumber: rxData.rxnumber,
			is_return: value,
			patients_win_rx_data_id: rxData.id,
			setShowToast: setShowToast
		}
		dispatch(ValidateReturnedPatches(requestedData))
	};

	return (
		<React.Fragment>
			<div style={{ position: 'relative' }} className=''>


				<div style={{ border: '1px solid lightgray' }} className={`text-xs font-semibold mt-1 bg-gray-50 rounded-md px-1.5   gap-2 uppercase`}>

					<div className='text-xs font-semibold pt-2 rounded-md px-0 py-0 flex items-center gap-2 uppercase'>
						<label>
							Is Returned Patches
						</label>
					</div>

					<div className='flex items-center pt-1 pb-2 space-x-16'>
						<div className='flex items-center'>
							<input
								type='radio'
								name={`returnpatch-${index}`}
								value='yes'
								checked={selectedValue === 'yes'}
								onChange={handleRadioChange}
								className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
								id={`yes-${index}`}
								style={{ outline: 'none', border: '0.1px solid gray', borderRadius: '4px' }}
							/>
							<label htmlFor={`yes-${index}`} className="ms-2 pl-1 text-sm font-medium text-gray-900 dark:text-gray-300">
								YES
							</label>
						</div>

						<div className='flex items-center'>
							<input
								type='radio'
								name={`returnpatch-${index}`}
								value='no'
								checked={selectedValue === 'no'}
								onChange={handleRadioChange}
								className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
								id={`no-${index}`}
								style={{ outline: 'none', border: '0.1px solid gray', borderRadius: '4px' }}
							/>
							<label htmlFor={`no-${index}`} className="ms-2 pl-1 text-sm font-medium text-gray-900 dark:text-gray-300">
								NO
							</label>
						</div>

						<div className='flex items-center'>
							<input
								type='radio'
								name={`returnpatch-${index}`}
								value='na'
								checked={selectedValue === 'na'}
								onChange={handleRadioChange}
								className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
								id={`Na-${index}`}
								style={{ outline: 'none', border: '0.1px solid gray', borderRadius: '4px' }}
							/>
							<label htmlFor={`Na-${index}`} className="ms-2 pl-1 text-sm font-medium text-gray-900 dark:text-gray-300">
								NA
							</label>
						</div>
					</div>

				</div>

				{/* {showToast.successToast &&
					<div className='fixed  left-0 w-full bg-green-500 text-white text-center py-2 z-50'>
						<SuccessMessage message={'Updated Successfully'} />
					</div>} */}

				{showToast.errorToast &&
					<div className='fixed  left-0 w-full  text-white text-center py-2 z-50'>
						<ErrorMessage message={'Oops!.Got an Issue.please try again.'} />
					</div>}
			</div>
		</React.Fragment>
	);
};
