export const isReduxStoredCleared = !localStorage.getItem('persist:root')

export const todayMissedRoute = 'Ebl~NA16q<6msl'
export const todayPendingRoute = '67nz£BPhJc4QPdl'
export const finalMissedRoute = '$4N<1P4sqVDoFDS'

export const initalTabsLoadingData = '$8h-76l#^*'



