import { createAsyncThunk } from "@reduxjs/toolkit";
import { who_viewed_med } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const updateWhoVieWedmed = createAsyncThunk('updateWhoVieWedmed', async (requestedData, { rejectWithValue }) => {
    try {

        const response = await Axios.post(who_viewed_med, requestedData)
        const data = response.data
        return data;

    } catch (error) {
        return rejectWithValue(error.response)
    }
});