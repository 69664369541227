import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_submit_consent } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";
import avatar from '../../../assets/images/userimg.png';

export const submitPatientConsent = createAsyncThunk('driverLogout', async (requesteData, { rejectWithValue }) => {

	try {
		const formData = new FormData();

		(requesteData.profile_img instanceof File) && formData.append('profile_img', requesteData.profile_img);
		(requesteData.document_img instanceof File) && formData.append('document_img', requesteData.document_img);

		// formData.append('profile_img', requesteData.profile_img);
		// formData.append('document_img', requesteData.document_img);
		formData.append('terms', requesteData.terms);
		formData.append('pharmacy_id', requesteData.pharmacy_id);
		formData.append('phn', requesteData.phn);
		formData.append('name', requesteData.name);
		formData.append('sign', requesteData.sign);
		formData.append('dob', requesteData.dob);
		formData.append('pharmacy_from', requesteData.pharmacy_from);

		const response = await Axios.post(driver_submit_consent, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				"Accept": "multipart/form-data",
			}
		})
		const data = response.data

		if (typeof requesteData.setShowMailModal === 'function') {
			requesteData.setShowMailModal(true)

			setTimeout(() => {
				requesteData.setShowMailModal(false)
			}, 2000);
		}

		if (typeof requesteData.setPatientConsentDetail === 'function') {
			requesteData.setPatientConsentDetail({
				...requesteData.patientConsentDetail,
				patientName: '', dob: '', transferingForm: '', phn: '',
			})
			requesteData.handleClearSignature()
			requesteData.setSelectedDate('')
			requesteData.setSelectedImage(avatar)
			requesteData.setSelectedFile(null)
			requesteData.setSelectedImageFile(null)
		}

		return data;

	} catch (error) {
		if (typeof requesteData.setShowToast === 'function') {
			requesteData.setShowToast(true)

			setTimeout(() => {
				requesteData.setShowToast(false)
			}, 4000);
		}
		return rejectWithValue(error.response)
	}
});