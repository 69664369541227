import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_patient_newrx } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const fetchPatientNewRxData = createAsyncThunk('fetchPatientNewRxData', async (params, { rejectWithValue }) => {
	try {

		const response = await Axios.get(`${driver_patient_newrx}?pharmacy_id=${params.pharmacy_id}`)

		if (typeof params.setLoading === 'function') {
			params.setLoading(false)
		}

		const data = response.data
		return data;

	} catch (error) {

		if (typeof params.setLoading === 'function') {
			params.setLoading(false)
		}

		return rejectWithValue(error.response)
	}
});