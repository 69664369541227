import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_logout } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";
import Cookies from "js-cookie";
import { finalMissedRoute, todayMissedRoute, todayPendingRoute } from "../../../storage";
import { persistor } from '../../../services/store/store'
export const driverLogout = createAsyncThunk('driverLogout', async (_, { rejectWithValue }) => {
	try {

		const response = await Axios.post(driver_logout)
		const data = response.data
		Cookies.remove('authToken')
		window.location.href = '/'
		localStorage.removeItem(todayPendingRoute)
		localStorage.removeItem(todayMissedRoute)
		localStorage.removeItem(finalMissedRoute)
		Cookies.remove('$8h-76l#^*')
		Cookies.remove('verified')
		persistor.purge()
		return data;

	} catch (error) {
		return rejectWithValue(error.response)
	}
});