import axios from "axios";
import Cookies from 'js-cookie';

import { persistor } from '../services/store/store';

const Axios = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL
});

Axios.interceptors.request.use(
	(config) => {
		const token = Cookies.get("authToken");

		config.headers["Accept"] = "application/json";
		// config.headers["Content-Type"] = "application/json";

		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

Axios.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error?.response?.status === 401) {
			Cookies.remove('authToken')
			Cookies.remove('verified')
			persistor.purge()
			window.location.href = '/'
		}
		return Promise.reject(error);
	}
);

export default Axios;
