import { createSlice } from "@reduxjs/toolkit";
import { fetchPatientSearchList } from "../../apis/dispense/patientSearch";

const initialState = {
	loading: false,
	data: null,
	error: null
}

const patientSearchListSlice = createSlice({
	name: 'fetchPatientSearchList',
	initialState,
	reducers: {
		clearAllPatientSearchData(state) {
			state.data = null
		}
	},

	extraReducers: (builder) => {
		builder.addCase(fetchPatientSearchList.pending.type, (state) => {
			return {
				...state,
				loading: true,
				// data: null,
				error: null
			}
		})

		builder.addCase(fetchPatientSearchList.fulfilled.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			}
		})

		builder.addCase(fetchPatientSearchList.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			}
		})
	}

})

export const { clearAllPatientSearchData } = patientSearchListSlice.actions;

export default patientSearchListSlice.reducer