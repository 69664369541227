import { createSlice } from "@reduxjs/toolkit";
import { fetchPatientListWithoutPharmacistSign } from "../../apis/supervisor/patientWithoutPharmacistSign";

const initialState = {
	loading: false,
	data: [],
	error: null,
    currentPage: 1,
	totalPages: null
}

const PatientListWithoutPharmacistSignSlice = createSlice({

	name: 'fetchPatientListWithoutPharmacistSign',
	initialState,

	reducers: {
		filterPatientsByPharmacistSign(state, action) {
			const updatedData = state?.data?.filter(patient => patient.id !== action.payload);
			state.data = updatedData;
		},

		deleteAllPatientList(state) {
			state.data = []
		}
	},

	extraReducers: (builder) => {
		builder.addCase(fetchPatientListWithoutPharmacistSign.pending.type, (state) => {
			return {
				...state,
				loading: true,
				error: null
			}
		})

		builder.addCase(fetchPatientListWithoutPharmacistSign.fulfilled.type, (state, action) => {
			const existingIds = new Set(state?.data?.map(item => item.id));
			const newData = action?.payload?.data?.filter(item => !existingIds.has(item.id));
			return {
				...state,
				loading: false,
				data: [...state.data, ...newData],
				error: null,
                currentPage: action.payload.meta.current_page,
				totalPages: action.payload.meta.last_page,
				error: null
			}
		})

		builder.addCase(fetchPatientListWithoutPharmacistSign.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: [],
				error: action.payload
			}
		})
	}

})

export const { filterPatientsByPharmacistSign, deleteAllPatientList } = PatientListWithoutPharmacistSignSlice.actions;

export default PatientListWithoutPharmacistSignSlice.reducer