import React, { useEffect, useState } from 'react';

import { ErrorMessages, SuccessMessage } from '../../components/error/errorMessages';
import { changePasscode } from '../../services/apis/setting/changePasscode';
import { useAllSelector } from '../../services/selectors/allSelector';

import { Accordion } from 'flowbite-react';
import ThemeButton from '../../components/form/button';
import ThemeTextInput from '../../components/form/textInput';
import * as Hooks from '../../hooks';

const ChangePasscode = () => {

    const initialFormData = { oldPasscode: '', newPasscode: '', confirmPasscode: '' };
    const [changePasscodeData, setChangePasscodeData] = useState(initialFormData);
    const [errorMessage, setErrorMessage] = useState(null);

    const dispatch = Hooks.useCustomDispatch();

    const { changePasscodeResponse } = useAllSelector();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!changePasscodeData?.oldPasscode && !changePasscodeData?.newPasscode && !changePasscodeData?.confirmPasscode) {
                setErrorMessage('Please Complete your all field')
            } else if (!changePasscodeData?.oldPasscode) {
                setErrorMessage('Please Enter Your Old Passcode')
            } else if (!changePasscodeData?.newPasscode) {
                setErrorMessage('Please Enter Your New Passcode')
            } else if (!changePasscodeData?.confirmPasscode) {
                setErrorMessage('Please Enter Confirm Passcode')
            } else if (changePasscodeData?.oldPasscode?.length < 4) {
                setErrorMessage('Old Passcode must be at least 4 characters')
            } else if (changePasscodeData?.newPasscode?.length < 4) {
                setErrorMessage('New Passcode must be at least 4 characters')
            } else if (changePasscodeData?.confirmPasscode?.length < 4) {
                setErrorMessage('Confirm Passcode must be at least 4 characters')
            } else if (changePasscodeData?.newPasscode !== changePasscodeData?.confirmPasscode) {
                setErrorMessage('The new passcode confirmation does not match.')
            } else {
                setErrorMessage(null)
                const requestedData = {
                    old_passcode: changePasscodeData?.oldPasscode,
                    new_passcode: changePasscodeData?.newPasscode,
                    new_passcode_confirmation: changePasscodeData?.confirmPasscode
                }
                await dispatch(changePasscode(requestedData))
            }
        } catch (error) {

        }

    };

    useEffect(() => {
        if (changePasscodeResponse) {
            if (changePasscodeResponse?.data?.success === true) {
                setErrorMessage(null)
            } else {
                var errorMsg = "";
                var error = changePasscodeResponse?.error?.data?.data?.error || "";
                error = typeof error === "string" ? error : Object.keys(error).map(key => errorMsg += error[key]);
                setErrorMessage(errorMsg || errorMessage)
            }
        }
    }, [
        changePasscodeResponse
    ])

    return (
        <>
            <Accordion collapseAll={true} className='shadow-sm bg-white overflow-hidden mt-3 border-none divide-dashed px-4'>
                <Accordion.Panel>
                    <Accordion.Title className='bg-white hover:bg-white p-2.5 px-0 focus:ring-0'>Change passcode</Accordion.Title>
                    <Accordion.Content className='px-0 py-4'>
                        <form>
                            <ThemeTextInput
                                label='Old passcode'
                                icon={() => (<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                        <path d="M4.99967 18.8335C4.54134 18.8335 4.14898 18.6703 3.82259 18.3439C3.4962 18.0175 3.33301 17.6252 3.33301 17.1668V8.8335C3.33301 8.37516 3.4962 7.9828 3.82259 7.65641C4.14898 7.33002 4.54134 7.16683 4.99967 7.16683H5.83301V5.50016C5.83301 4.34739 6.23926 3.36475 7.05176 2.55225C7.86426 1.73975 8.8469 1.3335 9.99967 1.3335C11.1525 1.3335 12.1351 1.73975 12.9476 2.55225C13.7601 3.36475 14.1663 4.34739 14.1663 5.50016V7.16683H14.9997C15.458 7.16683 15.8504 7.33002 16.1768 7.65641C16.5031 7.9828 16.6663 8.37516 16.6663 8.8335V17.1668C16.6663 17.6252 16.5031 18.0175 16.1768 18.3439C15.8504 18.6703 15.458 18.8335 14.9997 18.8335H4.99967ZM9.99967 14.6668C10.458 14.6668 10.8504 14.5036 11.1768 14.1772C11.5031 13.8509 11.6663 13.4585 11.6663 13.0002C11.6663 12.5418 11.5031 12.1495 11.1768 11.8231C10.8504 11.4967 10.458 11.3335 9.99967 11.3335C9.54134 11.3335 9.14898 11.4967 8.82259 11.8231C8.4962 12.1495 8.33301 12.5418 8.33301 13.0002C8.33301 13.4585 8.4962 13.8509 8.82259 14.1772C9.14898 14.5036 9.54134 14.6668 9.99967 14.6668ZM7.49967 7.16683H12.4997V5.50016C12.4997 4.80572 12.2566 4.21544 11.7705 3.72933C11.2844 3.24322 10.6941 3.00016 9.99967 3.00016C9.30523 3.00016 8.71495 3.24322 8.22884 3.72933C7.74273 4.21544 7.49967 4.80572 7.49967 5.50016V7.16683Z" fill="#1B2336" />
                                    </g>
                                </svg>)
                                }
                                id='oldPasscode'
                                name='oldPasscode'
                                value={changePasscodeData?.oldPasscode}
                                type='password'
                                placeholder='********'
                                onChange={(e) => setChangePasscodeData({ ...changePasscodeData, oldPasscode: e.target.value })}
                                inputProps={{ maxLength: 4 }}
                            />
                            <ThemeTextInput
                                label='New passcode'
                                icon={() => (<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                        <path d="M4.99967 18.8335C4.54134 18.8335 4.14898 18.6703 3.82259 18.3439C3.4962 18.0175 3.33301 17.6252 3.33301 17.1668V8.8335C3.33301 8.37516 3.4962 7.9828 3.82259 7.65641C4.14898 7.33002 4.54134 7.16683 4.99967 7.16683H5.83301V5.50016C5.83301 4.34739 6.23926 3.36475 7.05176 2.55225C7.86426 1.73975 8.8469 1.3335 9.99967 1.3335C11.1525 1.3335 12.1351 1.73975 12.9476 2.55225C13.7601 3.36475 14.1663 4.34739 14.1663 5.50016V7.16683H14.9997C15.458 7.16683 15.8504 7.33002 16.1768 7.65641C16.5031 7.9828 16.6663 8.37516 16.6663 8.8335V17.1668C16.6663 17.6252 16.5031 18.0175 16.1768 18.3439C15.8504 18.6703 15.458 18.8335 14.9997 18.8335H4.99967ZM9.99967 14.6668C10.458 14.6668 10.8504 14.5036 11.1768 14.1772C11.5031 13.8509 11.6663 13.4585 11.6663 13.0002C11.6663 12.5418 11.5031 12.1495 11.1768 11.8231C10.8504 11.4967 10.458 11.3335 9.99967 11.3335C9.54134 11.3335 9.14898 11.4967 8.82259 11.8231C8.4962 12.1495 8.33301 12.5418 8.33301 13.0002C8.33301 13.4585 8.4962 13.8509 8.82259 14.1772C9.14898 14.5036 9.54134 14.6668 9.99967 14.6668ZM7.49967 7.16683H12.4997V5.50016C12.4997 4.80572 12.2566 4.21544 11.7705 3.72933C11.2844 3.24322 10.6941 3.00016 9.99967 3.00016C9.30523 3.00016 8.71495 3.24322 8.22884 3.72933C7.74273 4.21544 7.49967 4.80572 7.49967 5.50016V7.16683Z" fill="#1B2336" />
                                    </g>
                                </svg>)
                                }
                                id='newPasscode'
                                name='newPasscode'
                                value={changePasscodeData?.newPasscode}
                                type='password'
                                placeholder='********'
                                onChange={(e) => setChangePasscodeData({ ...changePasscodeData, newPasscode: e.target.value })}
                                inputProps={{ maxLength: 4 }}
                            />
                            <ThemeTextInput
                                label='Re-type passcode'
                                icon={() => (<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                        <path d="M4.99967 18.8335C4.54134 18.8335 4.14898 18.6703 3.82259 18.3439C3.4962 18.0175 3.33301 17.6252 3.33301 17.1668V8.8335C3.33301 8.37516 3.4962 7.9828 3.82259 7.65641C4.14898 7.33002 4.54134 7.16683 4.99967 7.16683H5.83301V5.50016C5.83301 4.34739 6.23926 3.36475 7.05176 2.55225C7.86426 1.73975 8.8469 1.3335 9.99967 1.3335C11.1525 1.3335 12.1351 1.73975 12.9476 2.55225C13.7601 3.36475 14.1663 4.34739 14.1663 5.50016V7.16683H14.9997C15.458 7.16683 15.8504 7.33002 16.1768 7.65641C16.5031 7.9828 16.6663 8.37516 16.6663 8.8335V17.1668C16.6663 17.6252 16.5031 18.0175 16.1768 18.3439C15.8504 18.6703 15.458 18.8335 14.9997 18.8335H4.99967ZM9.99967 14.6668C10.458 14.6668 10.8504 14.5036 11.1768 14.1772C11.5031 13.8509 11.6663 13.4585 11.6663 13.0002C11.6663 12.5418 11.5031 12.1495 11.1768 11.8231C10.8504 11.4967 10.458 11.3335 9.99967 11.3335C9.54134 11.3335 9.14898 11.4967 8.82259 11.8231C8.4962 12.1495 8.33301 12.5418 8.33301 13.0002C8.33301 13.4585 8.4962 13.8509 8.82259 14.1772C9.14898 14.5036 9.54134 14.6668 9.99967 14.6668ZM7.49967 7.16683H12.4997V5.50016C12.4997 4.80572 12.2566 4.21544 11.7705 3.72933C11.2844 3.24322 10.6941 3.00016 9.99967 3.00016C9.30523 3.00016 8.71495 3.24322 8.22884 3.72933C7.74273 4.21544 7.49967 4.80572 7.49967 5.50016V7.16683Z" fill="#1B2336" />
                                    </g>
                                </svg>)
                                }
                                id='confirmPasscode'
                                name='confirmPasscode'
                                value={changePasscodeData?.confirmPasscode}
                                type='password'
                                placeholder='********'
                                onChange={(e) => setChangePasscodeData({ ...changePasscodeData, confirmPasscode: e.target.value })}
                                inputProps={{ maxLength: 4 }}
                            />
                            {
                                errorMessage ?
                                    <ErrorMessages error={errorMessage} />
                                    : changePasscodeResponse?.data?.success === true ?
                                        <SuccessMessage error="Passcode changed successfully" />
                                        : null

                            }
                            <div className='mt-5'>
                                <ThemeButton
                                    text='Change passcode'
                                    isProcessing={changePasscodeResponse?.loading}
                                    onClick={handleSubmit}
                                />
                            </div>
                        </form>
                    </Accordion.Content>
                </Accordion.Panel>
            </Accordion>

        </>
    )
}

export default ChangePasscode