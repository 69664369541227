import { createSlice } from "@reduxjs/toolkit";
import { driverDeliveredRouteList } from "../../apis/dispense/driverDeliveredRouteList";

const initialState = {
	loading: false,
	data: null,
	error: null
}

const driverDeliveredRouteListSlice = createSlice({
	name: 'driverDeliveredRouteList',
	initialState,

	extraReducers: (builder) => {
		builder.addCase(driverDeliveredRouteList.pending.type, (state) => {
			return {
				...state,
				loading: true,
				// data: null,
				error: null
			}
		})

		builder.addCase(driverDeliveredRouteList.fulfilled.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			}
		})

		builder.addCase(driverDeliveredRouteList.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			}
		})
	}

})

export default driverDeliveredRouteListSlice.reducer