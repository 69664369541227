import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_pharmacy } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const fetchSwitchPharmacy = createAsyncThunk('fetchSwitchPharmacy', async (_,{ rejectWithValue }) => {
    try {
       
        const response = await Axios.get(driver_pharmacy)
        const data = response.data
        return data;

    } catch (error) {
        return rejectWithValue(error.response)
    }
});