import React, { useEffect, useState } from 'react'

import {
	useCustomNavigate,
	useCustomDispatch
} from '../../hooks';

import { useAllSelector } from '../../services/selectors/allSelector';
import { fetchTodaysAnalyticsDetails } from '../../services/apis/pharmacy/superVisorAnalytics';
import { useAuthSelector } from '../../services/selectors/authSelector';

import { LoadingView } from '../../components/common/loader';
import { SOSemergencyEnableButton } from '../../components/common/SOSemergencyEnableButton';
import { getDefaultPharmacist } from '../../utils/helper/helper';

import SearchBox from '../../components/common/searchBox';

import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';
import * as routeName from '../../routes/routeName';

const TodaysAnalytics = () => {

	const navigate = useCustomNavigate()
	const dispatch = useCustomDispatch()

	const [defaultPharmacist, setDefaultPharmacist] = useState(null)

	const { pharmacyId, isSupervisor, driverDefaultPharmacist } = useAuthSelector()
	const { todaysAnalyticsResponse } = useAllSelector()

	const deliveredDispense = todaysAnalyticsResponse?.data?.data?.delivered
	const pendingDispense = todaysAnalyticsResponse?.data?.data?.pending
	const finalMissedDispense = todaysAnalyticsResponse?.data?.data?.final_missed
	const pending_signatures = todaysAnalyticsResponse?.data?.data?.pharmacist_sign_not_done

	useEffect(() => {
		const params = {
			pharmacy_id: pharmacyId
		}
		if (isSupervisor === 1 && pharmacyId) {
			dispatch(fetchTodaysAnalyticsDetails(params))
		}
		return () => { }
	}, [])

	const cardData = [
		{ bgColor: 'yellow', iconBgColor: 'yellow', icon: <Icons.UserDispenseIcon />, title: 'Counselling', value: 'Counselling', link: routeName.Counselling, fontSize: '12px', fontWeight: '500' },
		{ bgColor: 'blue', iconBgColor: 'blue', icon: <Icons.PendingSignatures />, title: `Pending Signatures (${pending_signatures})`, value: 'pending_sign', link: routeName.pending_pharmacist_signatures, fontSize: '12px', fontWeight: '500' },
		{ bgColor: 'purple', iconBgColor: 'purple', icon: <Icons.PendingDispenseIcon />, title: `Pending Dispense (${pendingDispense})`, value: 'Pending', link: routeName.driver_today_pending, fontSize: '12px', fontWeight: '500' },
		{ bgColor: 'green', iconBgColor: 'green', icon: <Icons.PendingDispenseIcon />, title: `Delivered Dispense (${deliveredDispense})`, value: 'Delivered', link: routeName.DRIVER_ROUTE_DELIVERED, fontSize: '12px', fontWeight: '500' },
		{ bgColor: 'pink', iconBgColor: 'pink', icon: <Icons.PendingDispenseIcon />, title: `Missed Dispense (${finalMissedDispense})`, value: 'Missed', fontSize: '12px', link: routeName.driver_Missed_Route, fontWeight: '500' },
		{ bgColor: 'red', iconBgColor: 'red', icon: <Icons.UserDispenseIcon />, title: `Switch Pharmacist`, value: 'Default_Pharmacist', fontSize: '12px', link: routeName.change_default_pharmacist, fontWeight: '500' },
		{ bgColor: 'blue', iconBgColor: 'blue', icon: <Icons.UserDispenseIcon />, title: `Attandance`, value: 'Attandance', fontSize: '12px', link: routeName.mark_attandance, fontWeight: '500' },
	]

	useEffect(() => {
		const matchedPharmacies = getDefaultPharmacist(pharmacyId, driverDefaultPharmacist);
		setDefaultPharmacist(matchedPharmacies)
	}, [pharmacyId, driverDefaultPharmacist])

	return (
		<React.Fragment>
			<div className="app-wrapper flex justify-center">
				<div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 
								sm:w-6/12 h-full overflow-x-hidden h-screen">

					<div className='flex items-center justify-center'>
						<SOSemergencyEnableButton />
					</div>

					<div className="relative p-4" >
						<div className="card bg-white p-4 rounded-lg shadow-sm">
							<div className="flex items-center justify-between gap-3">
								<div>
									<Common.TopBar pharmacyId={pharmacyId} />
								</div>
								<div className='flex items-center justify-center btnnn pt-4' onClick={() => navigate(`${routeName.DASHBOARD_PHARMACY}`)}>
									<Icons.HomeIcon />
								</div>
							</div>
						</div>

						<div className="flex gap-3 mb-3">
							<SearchBox pharmacyId={pharmacyId} />
						</div>


						{todaysAnalyticsResponse?.loading ?
							<LoadingView />
							:
							todaysAnalyticsResponse?.error !== null ?
								<p className='text-red-500'>Oops! Got an issue while Fetching Data.Please try again later.</p>
								:
								<>
									<div className="flex gap-3 mb-3 items-center justify-center text-lg font-medium">
										Supervisor
									</div>

									<div className="dashboard-cards-grid grid grid-cols-2 gap-2">
										{cardData?.map((card, index) => (
											<React.Fragment key={index}>
												{card.value === 'pending_sign' ?
													defaultPharmacist?.length > 0 &&
													<div className={`card flex flex-col p-3 rounded-md bg-blue-400 bg-opacity-10 gap-1 mb-1`} onClick={() => navigate(routeName.pending_pharmacist_signatures)}>
														<div className={`rounded-md bg-blue-700 flex items-center justify-center`} style={{ width: '40px', height: '40px' }}>
															<Icons.PendingSignatures />
														</div>
														<div className="text-info">
															<h1 className="text-sm font-medium" style={{ fontSize: "12px", fontWeight: '500px' }}>Pending Signatures ({pending_signatures})</h1>
														</div>
													</div>
													:
													<div className={`card flex flex-col  p-3 rounded-md bg-${card.bgColor}-400 bg-opacity-10 gap-1 mb-1`}
														onClick={() => navigate(card.link)}>
														<div className={`rounded-md bg-${card.iconBgColor}-700 flex items-center justify-center`} style={{ width: '40px', height: '40px' }}>
															{card.icon}
														</div>
														<div className="text-info">
															<h1 className="text-sm font-medium" style={{ fontSize: card.fontSize, fontWeight: card.fontWeight }}>{card.title}</h1>
														</div>
													</div>}
											</React.Fragment>
										))}
									</div>
								</>
						}

					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default TodaysAnalytics