import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_get_all_pending_list } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";
import { removePatientById } from "../../slices/dispense/patientPendingList";

export const fetchPatientPendingList = createAsyncThunk('fetchPatientPendingList', async (requesteData, { rejectWithValue }) => {
	try {

		const params = {
			page: requesteData.page,
			pharmacy_id: requesteData.pharmacy_id,
		}
		const response = await Axios.get(driver_get_all_pending_list, { params })
		const data = response.data
		if (typeof requesteData.setLoading === 'function') {
			requesteData.setLoading(false)
		}

		if (requesteData.patient_id) {
			requesteData.dispatch(removePatientById(requesteData.patient_id));
		}

		if (typeof requesteData?.setLoadMore === 'function') {
			requesteData.setLoadMore(false)
		}

		return data;

	} catch (error) {
		if (typeof requesteData.setLoading === 'function') {
			requesteData.setLoading(false)
		}
		return rejectWithValue(error.response)
	}
});