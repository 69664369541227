
import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_get_delivered_patients } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const fetchPatientDeliveredList = createAsyncThunk('fetchPatientDeliveredList', async (params, { rejectWithValue }) => {

    try {
        const response = await Axios.get(driver_get_delivered_patients, {
            params: {
                pharmacy_id: params.pharmacy_id,
                page: params.page
            }
        })
        const data = response.data
        if (typeof params.setLoading === 'function') {
            params.setLoading(false)
        }
        if (typeof params.setLoadMore === 'function') {
            params.setLoadMore(false)
        }
        return data;

    } catch (error) {
        if (typeof params.setLoading === 'function') {
            params.setLoading(false)
        }
        if (typeof params.setLoadMore === 'function') {
            params.setLoadMore(false)
        }
        return rejectWithValue(error.response)
    }
});