import { createSlice } from "@reduxjs/toolkit";
import { verifyDriverOTP } from "../../apis/auth/verifyOtp";

const initialState = {
    loading: false,
    data: null,
    error: null
}

const driverOTPVerificationSlice = createSlice({
    name: 'verifyDriverOTP',
    initialState,

    reducers: {
        removeValidateOTPData(state) {
            state.error = null;
            state.data = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(verifyDriverOTP.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            }
        })

        builder.addCase(verifyDriverOTP.fulfilled.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        })

        builder.addCase(verifyDriverOTP.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        })
    }

})

export const { removeValidateOTPData } = driverOTPVerificationSlice.actions;

export default driverOTPVerificationSlice.reducer