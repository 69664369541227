import { confirmDelivery } from "../../services/apis/dispense/confirmDelivery";
import { removePatientById } from "../../services/slices/dispense/patientPendingList";
import { updateDeliveryStatus } from '../../services/apis/dispense/updateDeliveryStatus';
import { removeTodayDispenePatientById } from "../../services/slices/dispense/patientForRouteList";
import { getPharmacyOrderDeliveryTime } from "./helper";

// -----------------------------------PENDING AND TODAY DISPENSE DELIVERY RX DATA START---------------
export const onhandleConfirmDelivery = (patientData,
    index,
    pharmacyId,
    selectePharmacist,
    patientSignature,
    pharmacistSignature,
    cordinate,
    comment,
    dispatch,
    setLoading,
    setOpenIndex,
    setShowErrorToast,
    setShowSuccessToast,
    pharmacyInspectionMode,
    isRefused,
    covid19,
    type,
    setIsRefused,
    setSignatureErrors,
    validateReturnedPatchesResponse,
    isTransferRequest,
    setIsTransferRequest,
) => {

    const front_delivery_time = getPharmacyOrderDeliveryTime()
    // console.log(front_delivery_time)

    const params = {
        route: patientData.route,
        pharmacy_id: pharmacyId,
        setLoading: setLoading,
    }

    const requesteData = {
        pharmacy_id: pharmacyId,
        pharmacist_id: selectePharmacist,
        patient_sign: [...patientSignature],
        pharmacist_sign: pharmacistSignature,
        delivery_latitude: cordinate?.latitude,
        delivery_longitude: cordinate?.longitude,
        patient_id: patientData?.id,
        comment: comment,
        dispatch: dispatch,
        params: params,
        setLoading: setLoading,
        setOpenIndex: setOpenIndex,
        setShowErrorToast: setShowErrorToast,
        setShowSuccessToast: setShowSuccessToast,
        type: type,
        is_refused: isRefused ? 1 : 0,
        covid19_exemptions: covid19 ? 1 : 0,
        is_return: validateReturnedPatchesResponse.isReturn,
        isTransferRequest: isTransferRequest,
        setIsTransferRequest: setIsTransferRequest,
        front_delivery_time: front_delivery_time
    }

    try {
        const hasRxWitness = patientData?.patients_win_rx_data?.some(patient => patient.rx_witness === 'Y');
        const filteredData = patientData?.patients_win_rx_data?.filter(item => item.is_din_matched.is_fentanyl === 1);
        const signaturesRequired = pharmacyInspectionMode === 1 && ((isRefused ? null : patientSignature[0] == null))

        if (pharmacyId && patientData?.id) {

            if (hasRxWitness) {

                if (patientSignature[0] == null && !covid19 && !isRefused) {
                    setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red' }))
                }

                else if (filteredData) {
                    if (validateReturnedPatchesResponse?.winrx_data_id?.length !== filteredData?.length) {
                        alert('Return Patches Feilds is required.')
                    } else {
                        handleSignature(signaturesRequired)
                    }
                }

                else {
                    handleSignature(signaturesRequired);
                    setSignatureErrors(prevState => ({ ...prevState, patientSign: null }))
                }

            }

            else if (filteredData) {

                if (validateReturnedPatchesResponse?.winrx_data_id?.length !== filteredData?.length) {
                    alert('Return Patches Feilds is required.')
                }

                else {
                    handleSignature(signaturesRequired)
                }
            }

            else {
                handleSignature(signaturesRequired)
            }

        }

        function handleSignature(isSignatureRequired) {

            if (isSignatureRequired) {
                setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red' }));
            }

            else if (isTransferRequest && patientSignature[0] === null) {
                setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red' }));
            }

            else {

                setSignatureErrors(prevState => ({ ...prevState, patientSign: null, pharmacistSign: null }));
                setShowSuccessToast(true);
                setOpenIndex(null);

                if (type === 'today_dispense') {
                    dispatch(removeTodayDispenePatientById(patientData?.id));
                } else {
                    dispatch(removePatientById(patientData?.id));
                }

                setIsRefused(false);
                setTimeout(() => {
                    const nextIndex = index + 1;
                    const nextPatientCard = document.getElementById(`patientCard-${nextIndex}`);
                    if (nextPatientCard) {
                        nextPatientCard.scrollIntoView({ behavior: "smooth", block: "center" });
                    }
                }, 100);

                setTimeout(() => {
                    setShowSuccessToast(false);
                }, 2000);

                setLoading(false);
                dispatch(confirmDelivery(requesteData));
            }
        }

    } catch (error) {

    }
};
// -----------------------------------PENDING AND TODAY DISPENSE DELIVERY RX DATA END---------------

// -----------------------------------PENDING AND TODAY DISPENSE MISSED RX DATA END---------------
export const onhandleMissedDelivery = (pharmacyId,
    missedData,
    missedIndex,
    dispatch,
    setOpenIndex,
    setMissedDeliveryErrorToast,
    setMissedDeliveryConfirmation,
    status,
    type
) => {

    const params = {
        pharmacy_id: pharmacyId,
        route: missedData.route,
        status: status
    };
    const requesteData = {
        pharmacy_id: pharmacyId,
        dispatch: dispatch,
        params: params,
        patient_id: missedData?.id,
        setOpenIndex: setOpenIndex,
        type: type,
        setMissedDeliveryErrorToast: setMissedDeliveryErrorToast,
        setMissedDeliveryConfirmation: setMissedDeliveryConfirmation
    };

    try {
        if (missedData?.id && pharmacyId) {
            if (type === 'today_dispense') {
                dispatch(removeTodayDispenePatientById(missedData?.id));
            } else {
                dispatch(removePatientById(missedData?.id));
            }

            setTimeout(() => {
                const nextIndex = missedIndex + 1;
                const nextPatientCard = document.getElementById(`patientCard-${nextIndex}`);
                if (nextPatientCard) {
                    nextPatientCard.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }, 100);
            setMissedDeliveryConfirmation(false);
            setOpenIndex(null);
            dispatch(updateDeliveryStatus(requesteData));
        }
    } catch (error) {
        // Handle error
    }
};
// -----------------------------------PENDING AND TODAY DISPENSE DELIVERY RX DATA END---------------

// -----------------------------------TODAY MISSED AND FINAL MISSED DISPENSE DELIVERY RX DATA START---------------
export const handleMissedConfirmDelivery = ({
    patientData,
    pharmacyId,
    selectedPharmacistID,
    patientSignature,
    pharmacistSignature,
    cordinate,
    comment,
    dispatch,
    setOpenIndex,
    setShowErrorToast,
    setShowSuccessToast,
    isRefused,
    covid19,
    validateReturnedPatchesResponse,
    isTransferRequest,
    setIsTransferRequest,
    pharmacyInspectionMode,
    setSignatureErrors,
    setIsRefused,
    fetchAction,
    filteredData,
    hasRxWitness
}) => {

    const front_delivery_time = getPharmacyOrderDeliveryTime()

    const params = {
        route: patientData?.route,
        pharmacy_id: pharmacyId,
    };

    const requesteData = {
        pharmacy_id: pharmacyId,
        pharmacist_id: selectedPharmacistID,
        patient_sign: [...patientSignature],
        pharmacist_sign: pharmacistSignature,
        delivery_latitude: cordinate?.latitude,
        delivery_longitude: cordinate?.longitude,
        patient_id: patientData?.id,
        comment: comment,
        dispatch: dispatch,
        params: params,
        setOpenIndex: setOpenIndex,
        setShowErrorToast: setShowErrorToast,
        setShowSuccessToast: setShowSuccessToast,
        type: fetchAction,
        is_refused: isRefused ? 1 : 0,
        covid19_exemptions: covid19 ? 1 : 0,
        is_return: validateReturnedPatchesResponse.isReturn,
        isTransferRequest,
        setIsTransferRequest,
        front_delivery_time: front_delivery_time
    };

    try {

        if (pharmacyId && patientData?.id) {
            const signaturesRequired = pharmacyInspectionMode === 1 && (!isRefused && patientSignature[0] == null);

            const handleSignature = () => {

                if (signaturesRequired) {
                    setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red' }));
                }

                else if (isTransferRequest && patientSignature[0] === null) {
                    setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red' }));
                }

                else {
                    setSignatureErrors(prevState => ({ ...prevState, patientSign: null, pharmacistSign: null }));
                    dispatch(confirmDelivery(requesteData));
                    setIsRefused(false);
                }
            };

            if (hasRxWitness) {

                if (patientSignature[0] == null && !covid19 && !isRefused) {
                    setSignatureErrors(prevState => ({ ...prevState, patientSign: '1px solid red', pharmacistID: '1px solid red' }));
                }

                else if (filteredData) {

                    if (validateReturnedPatchesResponse?.winrx_data_id?.length !== filteredData?.length) {
                        alert('Return Patches Fields are required.');
                    }

                    else {
                        handleSignature();
                    }
                }

                else {
                    handleSignature();
                }
            }

            else if (filteredData) {

                if (validateReturnedPatchesResponse?.winrx_data_id?.length !== filteredData?.length) {
                    alert('Return Patches Fields are required.');
                }

                else {
                    handleSignature();
                }
            }

            else {
                handleSignature();
            }
        }
    }

    catch (error) {
        console.error("Error confirming delivery:", error);
    }
};
// -----------------------------------TODAY MISSED AND FINAL MISSED DISPENSE DELIVERY RX DATA END---------------


