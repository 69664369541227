import { combineReducers } from "redux";
import {
	authLoginSlice,
	authSelectedPharmacySlice,
	authSelectedPharmacistSlice,
	SOS_EmergencyEnabledSlice,
	driverOTPVerificationSlice
} from "./auth";

import * as Dispense from './dispense'
import * as Pharmacy from './pharmacy'
import * as EncryptedKey from '../constants/encryptedRootReducerKeys';
import * as ProfileSetting from './setting'
import * as SuperVisorAccess from './supervisor'

export const rootReducer = combineReducers({

	[EncryptedKey.authLogin]: authLoginSlice,
	driverOTPVerification: driverOTPVerificationSlice,

	driverSelectedPharmacy: authSelectedPharmacySlice,
	driverSelectedPharmacist: authSelectedPharmacistSlice,

	SOS_EmergencyEnabled: SOS_EmergencyEnabledSlice,

	[EncryptedKey.patientDataBasisOfRoute]: Dispense.patientDataBasisOfRouteSlice,
	[EncryptedKey.pharmacistOfPharmacyList]: Dispense.pharmacistOfPharmacyListSlice,
	[EncryptedKey.todayMissedList]: Dispense.todayMissedListSlice,
	[EncryptedKey.driverDeliveredRouteList]: Dispense.driverDeliveredRouteListSlice,

	finalMissedList: Dispense.finalMissedListSlice,
	updateDeliveryList: Dispense.updateDeliveryStatusSlice,
	patientSearchList: Dispense.patientSearchListSlice,
	[EncryptedKey.pharmacyDetail]: Dispense.PharmacyDetailSlice,
	validateReturnedPatches: Dispense.ValidateReturnedPatchesSlice,

	patientDeliveryRxList: Dispense.patientDeliveryRxSlice,
	patientPendingList: Dispense.patientPendingListSlice,
	confirmDelivery: Dispense.confirmDeliverySlice,
	UploadedTriplicatesPatientSign: Dispense.UploadedTriplicatesPatientSignSlice,
	GenrateRxLink: Dispense.GenrateRxLinkSlice,

	changePassword: ProfileSetting.changePasswordSlice,
	changePasscode: ProfileSetting.changePasscodeSlice,
	updateProfile: ProfileSetting.updateProfileSlice,
	updatePatientProfile: ProfileSetting.updatePatientProfileSlice,

	[EncryptedKey.switchPharmacy]: Pharmacy.switchPharmacySlice,
	driverLogout: Pharmacy.driverLogoutSlice,
	patientConsent: Pharmacy.patientConsentSlice,
	allPharmacyList: Pharmacy.allPharmacyListSlice,
	UploadPrescripition: Pharmacy.UploadPrescripitionSlice,
	todaysAnalytics: Pharmacy.fetchTodaysAnalyticsDetailsSlice,

	patientNewRxData: SuperVisorAccess.PatientNewRxDataSlice,
	UpdateNewRXDataOption: SuperVisorAccess.UpdateNewRXDataOptionSlice,
	PatientListWithoutPharmacistSign: SuperVisorAccess.PatientListWithoutPharmacistSignSlice,

	[EncryptedKey.patientDeliveredDataBasisOfRoute]: SuperVisorAccess.patientDelivedListSlice,
	UpdatePharmacistSignature: SuperVisorAccess.UpdatePharmacistSignatureSlice,
	updatedDefaultPharmacist: SuperVisorAccess.UpdatedDefaultPharmacistSlice,
	pharmacyMarkAttandance: SuperVisorAccess.pharmacyMarkAttandanceSlice

})