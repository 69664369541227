import React, { useEffect, useState } from 'react';

import { ErrorMessages, SuccessMessage } from '../../components/error/errorMessages';
import { changePassword } from '../../services/apis/setting/changePassword';
import { useAllSelector } from '../../services/selectors/allSelector';

import { Accordion } from 'flowbite-react';
import ThemeButton from '../../components/form/button';
import ThemeTextInput from '../../components/form/textInput';
import * as Hooks from '../../hooks';

const ChangePassword = () => {

    const initialFormData = { oldPassword: '', newPassword: '', confirmPassword: '' };
    const [changePasswordData, setChangePasswordData] = useState(initialFormData);
    const [errorMessage, setErrorMessage] = useState(null);

    const dispatch = Hooks.useCustomDispatch();

    const { changePasswordResponse } = useAllSelector();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!changePasswordData?.oldPassword && !changePasswordData?.newPassword && !changePasswordData?.confirmPassword) {
                setErrorMessage('Please Complete your all field')
            } else if (!changePasswordData?.oldPassword) {
                setErrorMessage('Please Enter Your Old Password')
            } else if (!changePasswordData?.newPassword) {
                setErrorMessage('Please Enter Your New Password')
            } else if (!changePasswordData?.confirmPassword) {
                setErrorMessage('Confirm Password')
            } else if (changePasswordData?.newPassword !== changePasswordData?.confirmPassword) {
                setErrorMessage('The new password confirmation does not match.')
            } else {
                setErrorMessage(null)
                const requestedData = {
                    old_password: changePasswordData?.oldPassword,
                    new_password: changePasswordData?.newPassword,
                    new_password_confirmation: changePasswordData?.confirmPassword
                }
                await dispatch(changePassword(requestedData))
            }
        } catch (error) {

        }

    };

    useEffect(() => {
        if (changePasswordResponse) {
            if (changePasswordResponse?.data?.success === true) {
                setErrorMessage(null)
            } else {
                setErrorMessage(changePasswordResponse?.error?.data?.data?.error || errorMessage)
            }
        }
    }, [
        changePasswordResponse
    ])

    return (
        <>
            <Accordion collapseAll={true} className='shadow-sm bg-white overflow-hidden mt-3 border-none divide-dashed px-4'>
                <Accordion.Panel>
                    <Accordion.Title className='bg-white hover:bg-white p-2.5 px-0 focus:ring-0'>Change password</Accordion.Title>
                    <Accordion.Content className='px-0 py-4'>
                        <form>
                            <ThemeTextInput
                                label='Old password'
                                icon={() => (<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                        <path d="M4.99967 18.8335C4.54134 18.8335 4.14898 18.6703 3.82259 18.3439C3.4962 18.0175 3.33301 17.6252 3.33301 17.1668V8.8335C3.33301 8.37516 3.4962 7.9828 3.82259 7.65641C4.14898 7.33002 4.54134 7.16683 4.99967 7.16683H5.83301V5.50016C5.83301 4.34739 6.23926 3.36475 7.05176 2.55225C7.86426 1.73975 8.8469 1.3335 9.99967 1.3335C11.1525 1.3335 12.1351 1.73975 12.9476 2.55225C13.7601 3.36475 14.1663 4.34739 14.1663 5.50016V7.16683H14.9997C15.458 7.16683 15.8504 7.33002 16.1768 7.65641C16.5031 7.9828 16.6663 8.37516 16.6663 8.8335V17.1668C16.6663 17.6252 16.5031 18.0175 16.1768 18.3439C15.8504 18.6703 15.458 18.8335 14.9997 18.8335H4.99967ZM9.99967 14.6668C10.458 14.6668 10.8504 14.5036 11.1768 14.1772C11.5031 13.8509 11.6663 13.4585 11.6663 13.0002C11.6663 12.5418 11.5031 12.1495 11.1768 11.8231C10.8504 11.4967 10.458 11.3335 9.99967 11.3335C9.54134 11.3335 9.14898 11.4967 8.82259 11.8231C8.4962 12.1495 8.33301 12.5418 8.33301 13.0002C8.33301 13.4585 8.4962 13.8509 8.82259 14.1772C9.14898 14.5036 9.54134 14.6668 9.99967 14.6668ZM7.49967 7.16683H12.4997V5.50016C12.4997 4.80572 12.2566 4.21544 11.7705 3.72933C11.2844 3.24322 10.6941 3.00016 9.99967 3.00016C9.30523 3.00016 8.71495 3.24322 8.22884 3.72933C7.74273 4.21544 7.49967 4.80572 7.49967 5.50016V7.16683Z" fill="#1B2336" />
                                    </g>
                                </svg>)
                                }
                                name='oldPassword'
                                id='oldPassword'
                                value={changePasswordData?.oldPassword}
                                type='password'
                                placeholder='********'
                                onChange={(e) => setChangePasswordData({ ...changePasswordData, oldPassword: e.target.value })}
                            />
                            <ThemeTextInput
                                label='New password'
                                icon={() => (<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                        <path d="M4.99967 18.8335C4.54134 18.8335 4.14898 18.6703 3.82259 18.3439C3.4962 18.0175 3.33301 17.6252 3.33301 17.1668V8.8335C3.33301 8.37516 3.4962 7.9828 3.82259 7.65641C4.14898 7.33002 4.54134 7.16683 4.99967 7.16683H5.83301V5.50016C5.83301 4.34739 6.23926 3.36475 7.05176 2.55225C7.86426 1.73975 8.8469 1.3335 9.99967 1.3335C11.1525 1.3335 12.1351 1.73975 12.9476 2.55225C13.7601 3.36475 14.1663 4.34739 14.1663 5.50016V7.16683H14.9997C15.458 7.16683 15.8504 7.33002 16.1768 7.65641C16.5031 7.9828 16.6663 8.37516 16.6663 8.8335V17.1668C16.6663 17.6252 16.5031 18.0175 16.1768 18.3439C15.8504 18.6703 15.458 18.8335 14.9997 18.8335H4.99967ZM9.99967 14.6668C10.458 14.6668 10.8504 14.5036 11.1768 14.1772C11.5031 13.8509 11.6663 13.4585 11.6663 13.0002C11.6663 12.5418 11.5031 12.1495 11.1768 11.8231C10.8504 11.4967 10.458 11.3335 9.99967 11.3335C9.54134 11.3335 9.14898 11.4967 8.82259 11.8231C8.4962 12.1495 8.33301 12.5418 8.33301 13.0002C8.33301 13.4585 8.4962 13.8509 8.82259 14.1772C9.14898 14.5036 9.54134 14.6668 9.99967 14.6668ZM7.49967 7.16683H12.4997V5.50016C12.4997 4.80572 12.2566 4.21544 11.7705 3.72933C11.2844 3.24322 10.6941 3.00016 9.99967 3.00016C9.30523 3.00016 8.71495 3.24322 8.22884 3.72933C7.74273 4.21544 7.49967 4.80572 7.49967 5.50016V7.16683Z" fill="#1B2336" />
                                    </g>
                                </svg>)
                                }
                                name='newPassword'
                                id='newPassword'
                                value={changePasswordData?.newPassword}
                                type='password'
                                placeholder='********'
                                onChange={(e) => setChangePasswordData({ ...changePasswordData, newPassword: e.target.value })}
                            />
                            <ThemeTextInput
                                label='Re-type password'
                                icon={() => (<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                        <path d="M4.99967 18.8335C4.54134 18.8335 4.14898 18.6703 3.82259 18.3439C3.4962 18.0175 3.33301 17.6252 3.33301 17.1668V8.8335C3.33301 8.37516 3.4962 7.9828 3.82259 7.65641C4.14898 7.33002 4.54134 7.16683 4.99967 7.16683H5.83301V5.50016C5.83301 4.34739 6.23926 3.36475 7.05176 2.55225C7.86426 1.73975 8.8469 1.3335 9.99967 1.3335C11.1525 1.3335 12.1351 1.73975 12.9476 2.55225C13.7601 3.36475 14.1663 4.34739 14.1663 5.50016V7.16683H14.9997C15.458 7.16683 15.8504 7.33002 16.1768 7.65641C16.5031 7.9828 16.6663 8.37516 16.6663 8.8335V17.1668C16.6663 17.6252 16.5031 18.0175 16.1768 18.3439C15.8504 18.6703 15.458 18.8335 14.9997 18.8335H4.99967ZM9.99967 14.6668C10.458 14.6668 10.8504 14.5036 11.1768 14.1772C11.5031 13.8509 11.6663 13.4585 11.6663 13.0002C11.6663 12.5418 11.5031 12.1495 11.1768 11.8231C10.8504 11.4967 10.458 11.3335 9.99967 11.3335C9.54134 11.3335 9.14898 11.4967 8.82259 11.8231C8.4962 12.1495 8.33301 12.5418 8.33301 13.0002C8.33301 13.4585 8.4962 13.8509 8.82259 14.1772C9.14898 14.5036 9.54134 14.6668 9.99967 14.6668ZM7.49967 7.16683H12.4997V5.50016C12.4997 4.80572 12.2566 4.21544 11.7705 3.72933C11.2844 3.24322 10.6941 3.00016 9.99967 3.00016C9.30523 3.00016 8.71495 3.24322 8.22884 3.72933C7.74273 4.21544 7.49967 4.80572 7.49967 5.50016V7.16683Z" fill="#1B2336" />
                                    </g>
                                </svg>)
                                }
                                name='confirmPassword'
                                id='confirmPassword'
                                value={changePasswordData?.confirmPassword}
                                type='password'
                                placeholder='********'
                                onChange={(e) => setChangePasswordData({ ...changePasswordData, confirmPassword: e.target.value })}
                            />
                            {
                                errorMessage ?
                                    <ErrorMessages error={errorMessage} />
                                    : changePasswordResponse?.data?.success === true ?
                                        <SuccessMessage error="Password changed successfully" />
                                        : null

                            }
                            <div className='mt-5'>
                                <ThemeButton
                                    text='Change password'
                                    isProcessing={changePasswordResponse?.loading}
                                    onClick={handleSubmit}
                                />
                            </div>
                        </form>
                    </Accordion.Content>
                </Accordion.Panel>
            </Accordion>
            {/* <div className="container mx-auto px-4 flex items-center justify-center py-4">
                <div className="card bg-white shadow-2xl rounded-lg w-full max-w-md m-auto">

                    <div className="card-header py-2 text-center mt-8">
                        <h2 className="text-2xl font-semibold text-gray-900">Change Password</h2>
                    </div>
                    <div className='flex mt-2 p-4 gap-4'>
                        <Common.HomeButton />
                        <Common.BackButton />
                    </div>
                    <form>
                        <div className='relative pl-4 pr-4 mb-4'>
                            <input onChange={handleChange} defaultValue={changePasswordData?.oldPassword} name='oldPassword' id='oldPassword' type="password" className="block pl-4 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " autoComplete='oldPassword' />
                            <label htmlFor="email" className="absolute flex ml-2 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                Old Password</label>
                        </div>
                        <div className='relative pl-4 pr-4 mb-4'>
                            <input onChange={handleChange} defaultValue={changePasswordData?.newPassword} name='newPassword' id='newPassword' type="password" className="block pl-4 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " autoComplete='newPassword' />
                            <label htmlFor="email" className="absolute flex ml-2 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                New Password</label>
                        </div>
                        <div className='relative pl-4 pr-4 mb-2'>
                            <input onChange={handleChange} defaultValue={changePasswordData?.confirmPassword} name='confirmPassword' id='confirmPassword' type="password" className="block pl-4 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " autoComplete='confirmPassword' />
                            <label htmlFor="email" className="absolute flex ml-2 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                                Confirm Password</label>
                        </div>

                        <div>
                            <button onClick={(e) => !changePasswordResponse?.loading && handleSubmit(e)} type='submit' className='flex ml-4 px-4 py-3 mb-8 rounded-lg bg-blue-500 text-white font-semibold hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
                                {changePasswordResponse?.loading ? (
                                    <p className='flex items-center justify-center gap-2'>Processing...</p>
                                ) : (
                                    'Submit'
                                )}</button>
                        </div>
                    </form>
                </div>
            </div> */}

        </>
    )
}

export default ChangePassword