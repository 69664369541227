import { createSlice } from "@reduxjs/toolkit";
import { UpdateNewRXDataOption } from "../../apis/supervisor/updateNewRxOption";

const initialState = {
	loading: false,
	data: null,
	error: null
}

const UpdateNewRXDataOptionSlice = createSlice({
	name: 'UpdateNewRXDataOption',
	initialState,

	extraReducers: (builder) => {
		builder.addCase(UpdateNewRXDataOption.pending.type, (state) => {
			return {
				...state,
				loading: true,
				data: null,
				error: null
			}
		})

		builder.addCase(UpdateNewRXDataOption.fulfilled.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			}
		})

		builder.addCase(UpdateNewRXDataOption.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			}
		})
	}

})

export default UpdateNewRXDataOptionSlice.reducer