import { createSlice } from "@reduxjs/toolkit";
import { pharmacyMarkAttandance } from "../../apis/supervisor/markAttandance";

const initialState = {
    loading: false,
    data: null,
    error: null
}

const pharmacyMarkAttandanceSlice = createSlice({
    name: 'pharmacyMarkAttandance',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(pharmacyMarkAttandance.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                error: null,
                data: null
            }
        })

        builder.addCase(pharmacyMarkAttandance.fulfilled.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };
        });

        builder.addCase(pharmacyMarkAttandance.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: null
            }
        })
    }

})

export default pharmacyMarkAttandanceSlice.reducer