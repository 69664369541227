import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as Icons from '../../assets/svg/icons';
import * as routeNames from '../../routes/routeName';

export const HomeButton = () => {

	const navigate = useNavigate();

	return (
		<>
			<div>
				<button onClick={() => navigate(routeNames.DASHBOARD_PHARMACY)} className="text-gray-600 hover:text-gray-900 py-2 px-4 pt-2 border border-gray-300 rounded focus:outline-none">
					<Icons.HomeIcon />
				</button>
			</div>

		</>
	)
}

export const BackButton = () => {

	const handleClick = () => {
		window.history.back();
	};

	return (
		<>
			<div>
				<button onClick={handleClick} className="text-gray-600 hover:text-gray-900 py-2 px-4 border border-gray-300 rounded focus:outline-none">Back</button>
			</div>

		</>
	)
}

