
import { createAsyncThunk } from "@reduxjs/toolkit";

export const authSelectedPharmacy = createAsyncThunk('authSelectedPharmacy', async (data, { rejectWithValue }) => {
	try {
		const response = await data
		return response

	} catch (error) {
		return rejectWithValue(error.response)
	}

})