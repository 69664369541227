import { Button } from "flowbite-react";
import { Loader } from "../common/loader";

const ThemeButton = ({ text = "", isProcessing = false, onClick = null, type = "button" }) => {
    return (
        <div className="relative">
            <Button type={type} size='md' color='blue' className="w-full" onClick={onClick} disabled={isProcessing} >
                {
                    isProcessing ? <Loader /> : text
                }
            </Button>
        </div>
    )
}
export default ThemeButton;