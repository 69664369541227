import { Label, TextInput } from "flowbite-react";
import React from 'react';

const ThemeTextInput = ({
	name = "",
	icon = null,
	onChange = null,
	errorMessage = null,
	// defaultValue = null,
	type = "text",
	id = "",
	placeholder = "",
	required = true,
	disabled = false,
	value = "",
	label = "",
	inputProps = {},
	isBottomMargin = true,
}) => {

	return (
		<div className={`relative form-group ${isBottomMargin ? 'mb-3' : ''}`}>
			<div className="mb-2 block">
				<Label htmlFor={id || name} value={label} />
			</div>
			<TextInput
				sizing='md'
				color={errorMessage ? 'failure' : 'gray'}
				helperText={
					errorMessage ? <>
						{errorMessage}
					</> : null
				}
				id={id || name}
				name={name}
				type={type}
				inputMode={type}
				icon={icon}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				disabled={disabled}
				{...inputProps}
			/>
		</div>
	)
}

export default ThemeTextInput;