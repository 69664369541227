import { createSlice } from "@reduxjs/toolkit";
import { authLogin } from "../../apis/auth/authLogin";

const initialState = {
    loading: false,
    data: null,
    error: null
}

 const authLoginSlice = createSlice({
    name: 'authLogin',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(authLogin.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            }
        })

        builder.addCase(authLogin.fulfilled.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        })

        builder.addCase(authLogin.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        })
    }

})

export default authLoginSlice.reducer