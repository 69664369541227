import Cookies from 'js-cookie'

export const authToken = () => {    
  return (
    Cookies.get('authToken')
  )
}

export const authOTPVerified = () => {    
  return (
    Cookies.get('verified')
  )
}
