import { createAsyncThunk } from "@reduxjs/toolkit";
import { update_patient_profile } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const updatePatientProfile = createAsyncThunk('updatePatientProfile', async (requestedData, { rejectWithValue }) => {
    try {

        const formData = new FormData()
        formData.append('pharmacy_id', requestedData.pharmacy_id)
        formData.append('patient_id', requestedData.patient_id)
        formData.append('profile_image', requestedData.profile_image)

        const response = await Axios.post(update_patient_profile, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "multipart/form-data",
            }
        })
        const data = response.data
        return data;

    } catch (error) {
        return rejectWithValue(error.response)
    }
});