import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_delivered_route_list } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const driverDeliveredRouteList = createAsyncThunk('driverDeliveredRouteList', async (pharmacyId, { rejectWithValue }) => {
  
	try {
		const params = {
			pharmacy_id: pharmacyId
		}
		const response = await Axios.get(driver_delivered_route_list, { params })
		const data = response.data
		return data;
	} catch (error) {
		return rejectWithValue(error.response)
	}
});
