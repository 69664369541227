import { createSlice } from "@reduxjs/toolkit";
import { fetchTodaysAnalyticsDetails } from "../../apis/pharmacy/superVisorAnalytics";

const initialState = {
	loading: false,
	data: null,
	error: null
}

const fetchTodaysAnalyticsDetailsSlice = createSlice({
	name: 'fetchTodaysAnalyticsDetails',
	initialState,

	extraReducers: (builder) => {
		builder.addCase(fetchTodaysAnalyticsDetails.pending.type, (state) => {
			return {
				...state,
				loading: true,
				data: null,
				error: null
			}
		})

		builder.addCase(fetchTodaysAnalyticsDetails.fulfilled.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: action.payload,
				error: null
			}
		})

		builder.addCase(fetchTodaysAnalyticsDetails.rejected.type, (state, action) => {
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload
			}
		})
	}

})

export default fetchTodaysAnalyticsDetailsSlice.reducer