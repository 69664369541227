import { createSlice } from "@reduxjs/toolkit";
import { updateProfile } from "../../apis/setting/updateProfile";

const initialState = {
    loading: false,
    data: null,
    error: null
}

const updateProfileSlice = createSlice({
    name: 'updateProfile',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(updateProfile.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            }
        })

        builder.addCase(updateProfile.fulfilled.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        })

        builder.addCase(updateProfile.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        })
    }

})

export default updateProfileSlice.reducer