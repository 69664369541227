import { createAsyncThunk } from "@reduxjs/toolkit";
import { driver_get_Pharmacy } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const fetchPharmacyDetails = createAsyncThunk('fetchPharmacyDetails', async (id, { rejectWithValue }) => {
	try {

		const response = await Axios.get(`${driver_get_Pharmacy}/${id}`)
		const data = response.data
		return data;

	} catch (error) {
		return rejectWithValue(error.response)
	}
});