import { createAsyncThunk } from "@reduxjs/toolkit";

import Axios from "../../../networking/interceptor";

export const GenrateRxLink = createAsyncThunk('GenrateRxLink', async (params, { rejectWithValue }) => {
    try {

        const response = await Axios.post('driver/get-file-data', params)
        const data = response.data
        openBase64Pdf(response?.data?.encoded_pdf)
        return data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

const openBase64Pdf = (base64Data) => {
    const binaryString = window.atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url);
};
