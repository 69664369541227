import { createAsyncThunk } from "@reduxjs/toolkit";
import { update_profile } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const updateProfile = createAsyncThunk('updateProfile', async (requestedData, { rejectWithValue }) => {
    try {

        const response = await Axios.post(update_profile, requestedData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "multipart/form-data",
            }
        })
        const data = response.data
        return data;

    } catch (error) {
        return rejectWithValue(error.response)
    }
});