import { createAsyncThunk } from "@reduxjs/toolkit";
import { otp_verification } from "../../../networking/urlEndPoints";

import * as routeName from '../../../routes/routeName'
import Axios from "../../../networking/interceptor";
import Cookies from "js-cookie";
import { fetchPharmacyDetails } from "../dispense/getPharmacyDetail";

export const verifyDriverOTP = createAsyncThunk('verifyDriverOTP', async ({ reqData, navigate }, { rejectWithValue }) => {
    try {

        const response = await Axios.post(otp_verification, 
            {
                pharmacy_id: reqData.pharmacy_id,
                otp: reqData.otp
            }
        );
        const data = response.data;

        if (response?.status === (200 || 201)) {
            Cookies.set('verified', 1)
            reqData.dispatch(fetchPharmacyDetails(reqData.pharmacy_id))
            navigate(routeName.DASHBOARD_PHARMACY)
        }

        return data

    } catch (error) {
        // Cookies.remove('verified')
        Cookies.set('verified', 0)
        return rejectWithValue(error.response)
    }
});