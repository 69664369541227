import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import * as Icons from '../../assets/svg/icons';

export const PharmacistSignature = ({ pharmacistSignature, setPharmacistSignature, showPharmacistSignature, setShowPharmacistSignature,
	signatureErrors,
	setSignatureErrors
}) => {

	const sigRef = useRef();
	const [showSign, setShowSign] = useState(false)

	const handlePharmacistSignatureEnd = () => {
		const signatureDataURL = sigRef?.current?.toDataURL();
		if (signatureDataURL) {
			setPharmacistSignature(signatureDataURL);
		}
		setShowSign(false)
	}

	const handleClearSignature = () => {
		sigRef?.current?.clear();
		setPharmacistSignature(null);
		setShowSign(false)
	}

	const handleToggleVisibility = () => {
		setShowPharmacistSignature(!showPharmacistSignature)
		if (!showPharmacistSignature) {
			handlePharmacistSignatureEnd()
			setShowSign(true)
		}

		setTimeout(() => {
			if (pharmacistSignature) {
				const canvasRef = sigRef.current
				if (canvasRef) {
					canvasRef.fromDataURL(pharmacistSignature);
				}
			}
		})
	}

	useEffect(() => {
		if (pharmacistSignature) {
			setSignatureErrors({ ...signatureErrors, pharmacistSign: null })
		}
	}, [pharmacistSignature])

	return (
		<>
			<div className="p-4 bg-gray-100 rounded-md mb-3" style={{ border: signatureErrors.pharmacistSign }}>
				<div className="collapse-header flex items-center justify-between" onClick={handleToggleVisibility}>
					<h2>Pharmacist Signature</h2>
					{showPharmacistSignature ?
						<Icons.PharmacistSignatureIcon2 /> :
						<Icons.PharmacistSignatureIcon1 />}
				</div>
				{showPharmacistSignature &&
					<div className="mt-3">
						<div className="w-full relative flex items-center justify-center rounded-md">
							<SignatureCanvas
								penColor="black"
								clearOnResize={false}
								canvasProps={{ className: 'signature' }}
								ref={sigRef}
								onEnd={handlePharmacistSignatureEnd}
							/>
							<button onClick={handleClearSignature}
								className="text-xs absolute bg-gray-50 bottom-2 right-2 rounded-md px-3 py-1 flex items-center gap-2 uppercase">
								<Icons.ClearPatientSignatureIcon />
								Clear</button>
						</div>
					</div>
				}
			</div>
		</>
	)
}

export const Signature = ({ patientSignature, setPatientSignature, showPatientSignature, setShowPatientSignature, handleClearSignature, sigRef }) => {

	const [showSign, setShowSign] = useState(false)

	const handlePharmacistSignatureEnd = () => {
		const signatureDataURL = sigRef?.current?.toDataURL();
		if (signatureDataURL) {
			setPatientSignature(signatureDataURL);
		}
		setShowSign(false)
	}

	const handleToggleVisibility = () => {
		setShowPatientSignature(!showPatientSignature)
		if (!showPatientSignature) {
			handlePharmacistSignatureEnd()
			setShowSign(true)
		}

		setTimeout(() => {
			if (patientSignature) {
				const canvasRef = sigRef.current
				if (canvasRef) {
					canvasRef.fromDataURL(patientSignature);
				}
			}
		})
	}

	return (
		<>
			<div className="p-4 bg-gray-100 rounded-md mb-3">
				<div className="collapse-header flex items-center justify-between" onClick={handleToggleVisibility}>
					<h2>Signature</h2>
					{showPatientSignature ?
						<Icons.PharmacistSignatureIcon2 /> :
						<Icons.PharmacistSignatureIcon1 />}
				</div>
				{showPatientSignature &&
					<div className="mt-3">
						<div className="w-full relative flex items-center justify-center rounded-md">

							<SignatureCanvas
								clearOnResize={false}
								penColor="black"
								canvasProps={{ className: 'signature' }}
								ref={sigRef}
								onEnd={handlePharmacistSignatureEnd}
							/>
							<button onClick={handleClearSignature} type='button'
								className="text-xs absolute bg-gray-50 bottom-2 right-2 rounded-md px-3 py-1 flex items-center gap-2 uppercase">
								<Icons.ClearPatientSignatureIcon />
								Clear</button>
						</div>
					</div>
				}
			</div>
		</>
	)
}

