import React, { useEffect } from 'react';

import {
	PatientDetails,
	PatientMobileAndLocation,
} from '../../components/common/patientDetails';

import {
	removePatientById,
	clearData,
	pendingDispenseRemovingPageNumber
} from '../../services/slices/dispense/patientPendingList';

import {
	onhandleConfirmDelivery,
	onhandleMissedDelivery
} from '../../utils/helper/deliveriesHelper';

import { handleToggleRXData } from '../../utils/helper/helper';
import { apiKey, deliveryDriverDeliveredDispense } from '../../networking/urlEndPoints';
import { MissedDeliveryConfirmation } from '../../components/common/routeConfirmation';
import { useAuthSelector } from '../../services/selectors/authSelector';

import { useAllSelector } from '../../services/selectors/allSelector';
import { ErrorMessages } from '../../components/error/errorMessages';
import { fetchPatientPendingList } from '../../services/apis/dispense/patientPendingList';

import { useCustomStates } from '../../hooks/useCustomStates';
import { DispenseRxData } from '../../components/common/dispenseRxData';
import { PendingDispenseDeliveredByAnotherDriverAblyHelper } from '../../utils/helper/ablyDeliveredDispenseByOthers';

import { SOSemergencyEnableButton } from '../../components/common/SOSemergencyEnableButton';
import { RemoveAllPatchesId } from '../../services/slices/dispense/validateReturnPatches';

import InfiniteScroll from 'react-infinite-scroll-component';
import SearchBox from '../../components/common/searchBox';

import * as Messages from '../../components/common/AllConfirmationMessages'
import * as routeNames from '../../routes/routeName'
import * as Icons from '../../assets/svg/icons';
import * as Common from '../../components/common';

import * as Hooks from '../../hooks';
import * as Loading from '../../components/common/loader'

import PageNotFound from '../notFound/pageNotFound';

const PendingDispense = () => {

	const dispatch = Hooks.useCustomDispatch();
	const navigate = Hooks.useCustomNavigate();

	const { pharmacyId, driverID, pharmacyInspectionMode } = useAuthSelector()
	const { patientPendingListResponse, validateReturnedPatchesResponse } = useAllSelector();

	const { comment,
		setComment,

		cordinate,
		setCordinate,

		isRefused,
		setIsRefused,

		openIndex,
		setOpenIndex,

		patientSignature,
		setPatientSignature,

		pharmacistSignature,
		setPharmacistSignature,

		showErrorToast,
		setShowErrorToast,

		loading,
		setLoading,

		showSuccessToast,
		setShowSuccessToast,

		showComment,
		setShowComment,

		showPatientSignature,
		setShowPatientSignature,

		showPharmacistSignature,
		setShowPharmacistSignature,

		showData,
		setShowData,

		apiCallMade,
		setApiCallMade,

		showToast,
		setShowToast,

		missedDeliveryConfirmation,
		setMissedDeliveryConfirmation,

		missedData,
		setMissedData,

		missedDeliverErrorToast,
		setMissedDeliveryErrorToast,

		signatureErrors,
		setSignatureErrors,

		covid19,
		setCovid19,

		isTransferRequest,
		setIsTransferRequest,

		loadMore,
		setLoadMore,

		delveryDoneByAnotherDriver,
		setDeliveryDoneByAnotherDriver,

		missedIndex,
		setMissedIndex,

		patientId,
		setPatientID,

		pId,
		setPId,

	} = useCustomStates()

	const handleDropdownToggle = (index, data) => {
		handleToggleRXData(
			index,
			data,
			openIndex,
			setOpenIndex,
			dispatch,
			setSignatureErrors,
			setShowData,
			RemoveAllPatchesId,
			setPatientSignature,
			setShowComment,
			setShowPatientSignature,
			setShowPharmacistSignature,
			setPharmacistSignature,
			setComment,
			setPId,
			pharmacyId
		)
	}

	useEffect(() => {
		if (pharmacyId) {
			setLoading(true)
			if (!apiCallMade) {
				dispatch(clearData())
				dispatch(pendingDispenseRemovingPageNumber())
				dispatch(fetchPatientPendingList({
					page: 1,
					pharmacy_id: pharmacyId,
					setLoading: setLoading
				}))

				setApiCallMade(true)
			}
		}
		return () => { }
	}, [])


	const handleConfirmDelivery = (patientData, index) => {
		onhandleConfirmDelivery(patientData,
			index,
			pharmacyId,
			null,
			patientSignature,
			pharmacistSignature,
			cordinate,
			comment,
			dispatch,
			setLoading,
			setOpenIndex,
			setShowErrorToast,
			setShowSuccessToast,
			pharmacyInspectionMode,
			isRefused,
			covid19,
			'pending_dispense',
			setIsRefused,
			setSignatureErrors,
			validateReturnedPatchesResponse,
			isTransferRequest,
			setIsTransferRequest,
		);
	}

	const handleMissedDelivery = () => {
		onhandleMissedDelivery(pharmacyId,
			missedData,
			missedIndex,
			dispatch,
			setOpenIndex,
			setMissedDeliveryErrorToast,
			setMissedDeliveryConfirmation,
			"today_missed",
			"pending_dispense")
	}

	const fetchMoreData = () => {
		if (patientPendingListResponse?.currentPage < patientPendingListResponse?.totalPages) {
			setLoadMore(true)
			dispatch(fetchPatientPendingList({
				page: patientPendingListResponse?.currentPage + 1,
				pharmacy_id: pharmacyId,
				setLoading: setLoading,
				setLoadMore: setLoadMore
			}))
		}
	}

	return (
		<>
			{pharmacyId ? <>
				{patientPendingListResponse?.data?.length > 0 &&
					<InfiniteScroll
						dataLength={patientPendingListResponse?.data?.length}
						next={fetchMoreData}
						hasMore={patientPendingListResponse?.currentPage <= patientPendingListResponse?.totalPages}
					/>
				}

				<div className="app-wrapper flex justify-center">
					<div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden">
						<div className='flex items-center justify-center'>
							<SOSemergencyEnableButton />
						</div>

						<div className="relative p-4" >
							<div className="card bg-white p-4 rounded-lg shadow-sm">

								<div className="flex items-center justify-between gap-3">
									<div>
										<Common.TopBar pharmacyId={pharmacyId} />
									</div>

									<div className='flex items-center justify-center btnnn pt-4' onClick={() => navigate(`${routeNames.DASHBOARD_PHARMACY}`)} >
										<Icons.HomeIcon />
									</div>

								</div>
							</div>

							<div className="title-row my-2" >
								<h1 className="text-xl font-bold">Today's Pending Dispense</h1>
							</div>

							<div className="flex gap-3 mb-3">
								<SearchBox pharmacyId={pharmacyId} />
							</div>

							<div className="routes-list">

								{(patientPendingListResponse?.loading && loading) ?

									<div className='flex justify-center h-96'>
										<Loading.LoadingView />
									</div> :

									patientPendingListResponse?.error !== null ?
										<div className='flex  justify-center pb-4 mb-4 h-96'>
											<ErrorMessages error={'Oops! Got an issue while fetching Data.Please try again!'} />
										</div> :

										(patientPendingListResponse?.data?.length === 0 && !patientPendingListResponse?.loading) ?
											<p className='flex  justify-center pb-4 pt-3 h-96'>No more deliveries for today.</p>
											:

											patientPendingListResponse?.data?.map((data, index) => (

												<React.Fragment key={index}>

													<div className="card bg-white p-4 rounded-lg shadow-sm mb-3" id={`patientCard-${index}`}>
														<PatientDetails
															data={data}
															patientImg={data.profile_image}
															totalRxData={data?.patients_win_rx_data?.length}
															index={index} />

														<div className="flex items-center justify-between mt-4">
															<PatientMobileAndLocation
																data={data} setIsTransferRequest={setIsTransferRequest}
																isTransferRequest={isTransferRequest}
																index={index}
															/>

															<button className="p-2.5 rounded-md bg-gray-100"
																onClick={() => {
																	handleDropdownToggle(index, data);
																}} >
																{openIndex === index ? <Icons.DropdownBoxIcon2 /> : <Icons.DropdownBoxIcon1 />}
															</button>

														</div>


														{openIndex === index &&
															<>
																<DispenseRxData
																	rxData={data?.patients_win_rx_data}
																	signatureErrors={signatureErrors}
																	patientData={data} />

																{patientId !== data?.id ? <>
																	{showData &&
																		<React.Fragment>
																			<Common.Comment showComment={showComment}
																				setShowComment={setShowComment}
																				setComment={setComment} comment={comment} />

																			<Common.PatientSignature patientSignature={patientSignature}
																				setPatientSignature={setPatientSignature}
																				showPatientSignature={showPatientSignature}
																				setShowPatientSignature={setShowPatientSignature}
																				isRefused={isRefused}
																				setIsRefused={setIsRefused}
																				signatureErrors={signatureErrors}
																				setSignatureErrors={setSignatureErrors}
																				covid19={covid19}
																				setCovid19={setCovid19}
																			/>

																			<Common.PharmacistSignature
																				pharmacistSignature={pharmacistSignature}
																				setPharmacistSignature={setPharmacistSignature}
																				showPharmacistSignature={showPharmacistSignature}
																				setShowPharmacistSignature={setShowPharmacistSignature}
																				signatureErrors={signatureErrors}
																				setSignatureErrors={setSignatureErrors}
																			/>
																		</React.Fragment>
																	}

																	<div className="flex items-center gap-3">
																		{!showData &&
																			<button className="px-4 flex-1 py-2 bg-red-500 text-white rounded-md"
																				onClick={() => {
																					return setMissedData(data),
																						setMissedIndex(index),
																						setMissedDeliveryConfirmation(true)
																				}}>
																				Missed
																			</button>}

																		{showData ?
																			validateReturnedPatchesResponse?.loading ? null :
																				<button className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md"
																					onClick={() => handleConfirmDelivery(data, index)}>
																					Continue
																				</button>
																			:
																			<button className="px-4 flex-1 py-2 bg-green-500 text-white rounded-md"
																				onClick={() => {
																					setShowData(true);
																					setTimeout(() => {
																						const windowHeight = window.innerHeight;
																						const scrollAmount = windowHeight / 2;
																						window.scrollBy({ top: scrollAmount, behavior: "smooth" });
																					}, 100);
																				}}
																			>
																				Deliver
																			</button>
																		}
																	</div>
																</> : null}
															</>
														}
													</div>
												</React.Fragment>))}
							</div>

							{
								loadMore &&
								<div className='flex mb-2'>
									<div className="loaderss"></div>
								</div>
							}

						</div>

						{showToast && <Messages.PickedUpSuccessfully setShowToast={setShowToast} />}
						{showErrorToast && <Messages.DeliveryErrorMessages setShowErrorToast={setShowErrorToast} />}

						{showSuccessToast && <Messages.DeliverSuccessfull setShowSuccessToast={setShowSuccessToast} />}
						{missedDeliverErrorToast && <Messages.MissedDeliveryErrorMessages setMissedDeliveryErrorToast={setMissedDeliveryErrorToast} />}
						{delveryDoneByAnotherDriver && <Messages.DeliveryDoneByAnotherDriver setMissedDeliveryErrorToast={setDeliveryDoneByAnotherDriver} />}

					</div>

					{missedDeliveryConfirmation &&
						<MissedDeliveryConfirmation
							setShowConfirmationModal={setMissedDeliveryConfirmation}
							showConfirmationModal={missedDeliveryConfirmation}
							pharmacyId={pharmacyId} handleMissedDelivery={handleMissedDelivery}
							missedData={missedData} />}

					<PendingDispenseDeliveredByAnotherDriverAblyHelper
						apiKey={apiKey}
						deliveryDriverDeliveredDispense={deliveryDriverDeliveredDispense}
						pharmacyId={pharmacyId}
						driverID={driverID}
						pId={pId}
						routeName={routeNames}
						setPatientID={setPatientID}
						setDeliveryDoneByAnotherDriver={setDeliveryDoneByAnotherDriver}
						dispatch={dispatch}
						removePatientById={removePatientById}
						setOpenIndex={setOpenIndex}
						setPId={setPId}
						setCordinate={setCordinate}
					/>
				</div>
			</>
				: <PageNotFound />}
		</>
	)
}

export default PendingDispense
