import React, {
	useState,
	useEffect
} from 'react'

import { useAuthSelector } from '../../services/selectors/authSelector';
import { getPharmacyCurrentTime } from '../../utils/helper/helper';

export const TopBar = () => {

	const { driverSelectedPharmacy } = useAuthSelector()
	const [currentDateAndTime, setCurrentDateAndTime] = useState(getPharmacyCurrentTime);

	const DisplayCurrentTime = () => {
		const currentCanadaTime = getPharmacyCurrentTime();
		setCurrentDateAndTime(currentCanadaTime);
	}

	useEffect(() => {
		const interval = setInterval(DisplayCurrentTime, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<>
			<div className="flex items-center gap-3">
				<div className="rounded-md flex items-center justify-center"
					style={{ width: '60px', height: '60px' }}>
					<img className='rounded-lg' src={driverSelectedPharmacy?.pharmacies?.logo_thumb_full_url
					} />
				</div>

				<div className="text-info">
					<h1 className="text-md font-bold">{driverSelectedPharmacy?.pharmacies?.name.toUpperCase()}</h1>
					<p className="text-sm text-gray-500">{driverSelectedPharmacy?.pharmacies?.city}, {driverSelectedPharmacy?.pharmacies?.country}</p>
					<p className="text-sm text-gray-500">{currentDateAndTime}</p>
				</div>
			</div>

		</>
	)
}


