import { createAsyncThunk } from "@reduxjs/toolkit";
import { change_password } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const changePassword = createAsyncThunk('changePassword', async (requestedData, { rejectWithValue }) => {
    try {

        const response = await Axios.post(change_password, requestedData)
        const data = response.data
        return data;

    } catch (error) {
        return rejectWithValue(error.response)
    }
});