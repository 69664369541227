import { createAsyncThunk } from "@reduxjs/toolkit";
import { update_newrx_option } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const UpdateNewRXDataOption = createAsyncThunk('UpdateNewRXDataOption', async (requestedData, { rejectWithValue }) => {

	try {

		const uploadData = {
			pharmacy_id: requestedData.pharmacy_id,
			rx_data_id: requestedData.rx_data_id,
			counselling: requestedData.counselling
		}

		const response = await Axios.post(`${update_newrx_option}`, uploadData)

		const data = response.data

		// const params = {
		// 	pharmacy_id: requestedData.pharmacy_id
		// }

		// if (typeof requestedData.setLoading === 'function') {
		// 	// requestedData.setLoading(false)
		// 	// requestedData.dispatch(fetchPatientNewRxData(params))
		// }

		return data;

	} catch (error) {

		if (typeof requestedData.setShowToast === 'function') {
			requestedData.setShowToast(prevState => ({ ...prevState, errorToast: true }))

			setTimeout(() => {
				requestedData.setShowToast(prevState => ({ ...prevState, errorToast: false }))
			}, 2000);
		}

		return rejectWithValue(error.response)
	}
})