import { useEffect } from 'react';
import { getLocation } from './helper';
import { fetchTodayMissed } from '../../services/apis/dispense/todayMissed';

import * as routeNames from '../../routes/routeName'

import Ably from 'ably';

export const TodayDispenseDeliveredByAnotherDriverAblyHelper = ({
    apiKey,
    deliveryDriverDeliveredDispense,
    pharmacyId,
    driverID,
    pId,
    routeName,
    location,
    setPatientID,
    setDeliveryDoneByAnotherDriver,
    dispatch,
    removeTodayDispenePatientById,
    setOpenIndex,
    setPId,
    setCordinate
}) => {


    useEffect(() => {
        getLocation(setCordinate)
    }, [])

    useEffect(() => {
        const ably = new Ably.Realtime({ key: apiKey });
        const channel = ably.channels.get(deliveryDriverDeliveredDispense);

        channel.subscribe(`delivered-dispense-${pharmacyId}`, (message) => {
            if (message?.name === `delivered-dispense-${pharmacyId}` && location.pathname.startsWith(routeName.DRIVER_ROUTE_TODAY)) {
                if (message?.data?.auth_driver_id !== driverID) {
                    
                    setPatientID(message?.data?.patient_id)
                    
                    if (pId === message?.data?.patient_id) {
                        setDeliveryDoneByAnotherDriver(true)
                        setTimeout(() => {
                            setDeliveryDoneByAnotherDriver(false)
                        }, 5000);

                        setTimeout(() => {
                            dispatch(removeTodayDispenePatientById(message?.data?.patient_id));
                            setPId(null)
                            setPatientID(null)
                            setOpenIndex(null)
                        }, 3000);

                    } else {
                        dispatch(removeTodayDispenePatientById(message?.data?.patient_id));
                        setOpenIndex(null)
                        setPId(null)
                        setPatientID(null)
                    }
                }
            }
        })
        return () => {
            if (ably.connection.state === 'connected') {
                ably.close();
            }
        }
    }, [pId, pharmacyId]);

    return null;
};

export const PendingDispenseDeliveredByAnotherDriverAblyHelper = ({
    apiKey,
    deliveryDriverDeliveredDispense,
    pharmacyId,
    driverID,
    pId,
    setPatientID,
    setDeliveryDoneByAnotherDriver,
    dispatch,
    removePatientById,
    setOpenIndex,
    setPId,
    setCordinate
}) => {


    useEffect(() => {
        getLocation(setCordinate)
    }, [])


    useEffect(() => {
        const ably = new Ably.Realtime({ key: apiKey });
        const channel = ably.channels.get(deliveryDriverDeliveredDispense);

        channel.subscribe(`delivered-dispense-${pharmacyId}`, (message) => {
            if (message?.name === `delivered-dispense-${pharmacyId}`) {
                if (message?.data?.auth_driver_id !== driverID) {
                    setPatientID(message?.data?.patient_id)

                    if (pId === message?.data?.patient_id) {
                        setDeliveryDoneByAnotherDriver(true)
                        setTimeout(() => {
                            setDeliveryDoneByAnotherDriver(false)
                        }, 5000);

                        setTimeout(() => {
                            dispatch(removePatientById(message?.data?.patient_id));
                            setPId(null)
                            setPatientID(null)
                            setOpenIndex(null)
                        }, 3000);

                    } else {
                        dispatch(removePatientById(message?.data?.patient_id));
                        setOpenIndex(null)
                        setPId(null)
                        setPatientID(null)
                    }
                }
            }
        })
        return () => {
            if (ably.connection.state === 'connected') {
                ably.close();
            }
        }
    }, [pId, pharmacyId]);

    return null;
};

export const TodayMissedDeliveredByAnotherDriverAblyHelper = ({
    apiKey,
    deliveryDriverDeliveredDispense,
    pharmacyId,
    dispatch,
    setCordinate,
    location,
    setLoading,

}) => {

    useEffect(() => {
        getLocation(setCordinate)
    }, [])

    useEffect(() => {
		const ably = new Ably.Realtime({ key: apiKey });
		const channel = ably.channels.get(deliveryDriverDeliveredDispense);

		channel.subscribe(`delivered-dispense-${pharmacyId}`, (message) => {
			if (message?.name === `delivered-dispense-${pharmacyId}` && location.pathname.startsWith(routeNames.Missed_Route)) {
				if (pharmacyId) {
					const params = {
						pharmacy_id: pharmacyId,
						status: 'today_missed',
					};
					setLoading(false)
					dispatch(fetchTodayMissed(params))
				}
			}
		})
		return () => {
			if (ably.connection.state === 'connected') {
				ably.close();
			}
		}
	}, []);

    return null;
};

export const FinalDeliveredByAnotherDriverAblyHelper = ({
    apiKey,
    deliveryDriverDeliveredDispense,
    pharmacyId,
    dispatch,
    setCordinate,
    location,
    setLoading,
}) => {

    useEffect(() => {
        getLocation(setCordinate)
    }, [])

    useEffect(() => {
		const ably = new Ably.Realtime({ key: apiKey });
		const channel = ably.channels.get(deliveryDriverDeliveredDispense);

		channel.subscribe(`delivered-dispense-${pharmacyId}`, (message) => {
			if (message?.name === `delivered-dispense-${pharmacyId}` && location.pathname.startsWith(routeNames.driver_Missed_Route)) {
				if (pharmacyId) {
					const params = {
						pharmacy_id: pharmacyId,
						status: 'final_missed',
					};
					setLoading(false)
					dispatch(fetchTodayMissed(params))
				}
			}
		})
		return () => {
			if (ably.connection.state === 'connected') {
				ably.close();
			}
		}
	}, []);

    return null;
};


export const SearchPatientListDeliveredByAnotherDriverAblyHelper = ({
    apiKey,
    deliveryDriverDeliveredDispense,
    pharmacyId,
    dispatch,
    setCordinate,
    setLoading,
    patientId,
    removePatientById
}) => {

    useEffect(() => {
        getLocation(setCordinate)
    }, [])

    useEffect(() => {

        const ably = new Ably.Realtime({ key: apiKey });
        const channel = ably.channels.get(deliveryDriverDeliveredDispense);

        channel.subscribe(`delivered-dispense-${pharmacyId}`, (message) => {
            if (message?.name === `delivered-dispense-${pharmacyId}`) {
                if (message?.data?.patient_id === patientId) {
                    setLoading(false)
                    dispatch(removePatientById(message?.data?.patient_id))
                }
            }
        })

        return () => {
            if (ably.connection.state === 'connected') {
                ably.close();
            }
        }
    }, [patientId, pharmacyId])

    return null;
};

export const getStatusSpan = (status, bgColor, textColor) => (
    <span className={`px-2 py-1.5 text-sm  rounded-md bg-${bgColor}-600 bg-opacity-10 text-${textColor}-900 w-auto flex justify-center items-center`}>
        {status}
    </span>
);