import { createAsyncThunk } from "@reduxjs/toolkit";
import { is_return_validate } from "../../../networking/urlEndPoints";

import Axios from "../../../networking/interceptor";

export const ValidateReturnedPatches = createAsyncThunk('ValidateReturnedPatches', async (requestedData, { rejectWithValue }) => {
    try {

        const response = await Axios.post(is_return_validate,
            {
                pharmacy_id: requestedData.pharmacy_id,
                patient_id: requestedData.patient_id,
                rxnumber: requestedData.rxnumber,
                is_return: requestedData.is_return,
                patients_win_rx_data_id: requestedData.patients_win_rx_data_id
            })

        const data = response.data
        if (typeof requestedData.setShowToast === 'function') {
            requestedData.setShowToast((prevstat) => ({ ...prevstat, successToast: true }))
            setTimeout(() => {
                requestedData.setShowToast((prevstat) => ({ ...prevstat, successToast: false }))
            }, 2000);
        }
        return data;

    } catch (error) {

        if (typeof requestedData.setShowToast === 'function') {
            requestedData.setShowToast((prevstat) => ({ ...prevstat, errorToast: true }))
            setTimeout(() => {
                requestedData.setShowToast((prevstat) => ({ ...prevstat, errorToast: false }))
            }, 2000);
        }

        return rejectWithValue(error.response)
    }
});