import React, {
	useEffect,
	useState
} from 'react';

import { LoadingView } from '../../components/common/loader';
import { fetchSwitchPharmacy } from '../../services/apis/pharmacy/switchPharmacy';
import { ErrorMessages } from '../../components/error/errorMessages';
import { useAllSelector } from '../../services/selectors/allSelector';

import { authToken } from '../../storage/authToken';
import { authSelectedPharmacy } from '../../services/apis/auth/authSelectedPharmacy';
import { RemoveSelectedPharmacy } from '../../services/slices/auth/authSelectedPharmacy';

import * as Common from '../../components/common';
import * as routeNames from '../../routes/routeName';
import * as Hooks from '../../hooks'
import * as Storage from '../../storage/index'

import Cookies from "js-cookie";

const SwitchPharmacy = () => {

	const navigate = Hooks.useCustomNavigate();
	const dispatch = Hooks.useCustomDispatch();
	const location = Hooks.useCustomLocation()

	const token = authToken();

	const { switchPharmacyResponse } = useAllSelector();
	const pharmacyList = switchPharmacyResponse?.data?.data

	const [selectedPharmacy, setSelectedPharmacy] = useState(null);

	const handlePharmacySelect = (id) => {
		setSelectedPharmacy(id);
	};

	useEffect(() => {
		if (token && location.pathname === routeNames.SWITCH_PHARMACY) {
			dispatch(fetchSwitchPharmacy())
		}
		return () => { }
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleContinuePharmacyProcessing = async () => {
		try {
			if (selectedPharmacy?.pharmacy_id) {
				dispatch(authSelectedPharmacy(selectedPharmacy))
				// dispatch(fetchPharmacyDetails(selectedPharmacy?.pharmacy_id))
				localStorage.removeItem(Storage.todayPendingRoute)
				localStorage.removeItem(Storage.todayMissedRoute)
				localStorage.removeItem(Storage.finalMissedRoute)
				navigate(`${routeNames.verify_otp}`)
			}
		} catch {

		}
	}

	useEffect(() => {
		dispatch(RemoveSelectedPharmacy())
		Cookies.set('verified', 0)
	}, [])

	return (

		<>
			<div className="app-wrapper flex justify-center h-screen">
				<div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden ">
					<div className="relative p-4">
						<Common.Header />
						<div className="title-row my-5">
						</div>

						{switchPharmacyResponse?.loading ?
							<div className='flex items-center justify-center pb-4 mb-4'>
								<LoadingView />
							</div>
							:
							switchPharmacyResponse?.error !== null ?
								<div className='flex items-center justify-center pb-4 mb-4'>
									<ErrorMessages error={'Oops! Got an issue whille fetching Pharmacies Detatils.Please try to reload the page again.'} />
								</div>
								:
								pharmacyList?.length === 0 ?
									<div className="flex items-center justify-center pb-4 mb-4">
										<ErrorMessages error={"Looks like there are no pharmacies nearby right now. Hang tight, we're on the lookout for more options!"} />
									</div>
									:
									<React.Fragment>
										{pharmacyList?.map((value, index) => (
											<div key={index}>
												<label htmlFor={`item-checkbox-${index}`}
													className="cursor-pointer drop-shadow-md item-box flex items-center gap-4 p-4 bg-white rounded-2xl mb-4">
													<div className="avatar flex-shrink-0">
														<div
															className="w-14 h-14 flex items-center justify-center bg-gray-200 rounded-full object-cover object-top">
															<img className="w-full rounded-lg object-cover object-top" alt=''
																src={value?.pharmacies?.logo_thumb_full_url} />
														</div>
													</div>
													<div className="item-details flex-grow">
														<h1 className="text-lg font-bold text-gray-700">{value?.pharmacies?.name?.toUpperCase()}</h1>
														<h1 className="text-sm text-gray-400">{value?.pharmacies?.city}</h1>
													</div>
													<div className="item-actions flex-shrink-0 flex items-center gap-3">
														<input id={`item-checkbox-${index}`} type="radio" value={index} name="pharmacy-selection"
															className="peer" onChange={() => handlePharmacySelect(value)} />
													</div>
												</label>
											</div>
										))}

										<div className="relative mb-4 mt-6">
											<button type="button" onClick={handleContinuePharmacyProcessing}
												className={`text-white ${selectedPharmacy ? 'bg-blue-700 hover:bg-blue-800' : 'bg-blue-100'} 
															w-full focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-lg px-5 py-3 me-2 mb-2 
															group-invalid:pointer-events-none group-invalid:opacity-30`}>
												Continue
											</button>
										</div>
									</React.Fragment>
						}
					</div>
				</div>
			</div>
		</>)
}

export default SwitchPharmacy