
import { createAsyncThunk } from "@reduxjs/toolkit";
import { save_pharmasist_sign } from "../../../networking/urlEndPoints";
import { fetchPatientListWithoutPharmacistSign } from "./patientWithoutPharmacistSign";

import Axios from "../../../networking/interceptor";

export const UpdatePharmacistSignature = createAsyncThunk('UpdatePharmacistSignature', async (requestedData, { rejectWithValue }) => {

    try {

        const uploadData = {
            pharmacy_id: requestedData.pharmacy_id,
            signature: requestedData.signature,
            patient_id: requestedData.patient_id
        }

        const response = await Axios.post(save_pharmasist_sign, uploadData)
        const data = response.data

        return data;

    } catch (error) {

        if (typeof requestedData.setShowToast === 'function') {
            requestedData.setLoading(true)

            const params = {
                pharmacy_id: requestedData.pharmacy_id,
                page: 1,
            }

            requestedData.dispatch(fetchPatientListWithoutPharmacistSign(params))
            requestedData.setShowToast(prevState => ({ ...prevState, errorToast: true }))

            setTimeout(() => {
                requestedData.setShowToast(prevState => ({ ...prevState, errorToast: false }))
            }, 2000)

        }
        return rejectWithValue(error.response)
    }
});