import React, {
	useEffect,
	useState
} from "react";
import { fetchAllPharmacyList } from "../../services/apis/pharmacy/allPharmacyListForSelectBoxInPatientConsent";
import { useAllSelector } from "../../services/selectors/allSelector";

import Select from 'react-select';

import * as Hooks from '../../hooks'

export const ToPharmacy = ({
	patientConsentDetail,
	setPatientConsentDetail,
	errorMessages,
	setErrorMessages,
}) => {
	const { allPharmacyListResponse, patientConsentResponse } = useAllSelector();
	const pharmacyLists = allPharmacyListResponse?.data?.data;
	const [pharmacyList, setPharmacyList] = useState([]);

	const dispatch = Hooks.useCustomDispatch();

	useEffect(() => {
		if (allPharmacyListResponse?.loading) {
			setErrorMessages({
				...errorMessages,
				transferingForm: "",
			});
		} else if (allPharmacyListResponse?.error !== null) {
			setErrorMessages({
				...errorMessages,
				transferingForm:
					"Getting an issue while fetching request please try to reload the page or try again",
			});
		} else {
			setErrorMessages({
				...errorMessages,
				transferingForm: "",
			});
		}
	}, [allPharmacyListResponse]);

	useEffect(() => {
		if (!allPharmacyListResponse?.error && allPharmacyListResponse?.data) {
			// const formattedLists = Object.values(pharmacyLists)?.map((value, index) => ({
			// 	label: `${value}`,
			// 	value: `${index}`
			// }));
			const formattedLists = Object.entries(pharmacyLists)?.map(([key, value]) => ({
				label: value,
				value: key
			}));

			setPharmacyList(formattedLists);
		}
	}, [allPharmacyListResponse]);

	useEffect(() => {
		if (pharmacyList?.length === 0) {
			dispatch(fetchAllPharmacyList())
		}
		return () => { };
	}, [])

	return (
		<>
			<div
				className={`${!errorMessages?.transferingForm && patientConsentDetail?.transferingForm && "select-wrapper z-10"}`}>
				<Select
					isMulti={false}
					onChange={(selectedValue) => {
						setPatientConsentDetail(
							{
								...patientConsentDetail,
								transferingForm: selectedValue,
							},
							setErrorMessages({
								...errorMessages,
								transferingForm: "",
							}),
						);
					}}
					value={patientConsentDetail.transferingForm}
					options={pharmacyList}
					classNamePrefix="react-select"
					styles={{
						control: (provided) => ({
							...provided,
							height: 42,
							paddingLeft: "6px",
							color: "#6B7280",
							backgroundColor: "#f9fafb",
							borderRadius: "8px",
							position: "relative",
							border: "1px solid lightgrey",
						}),
						menuList: (provided) => ({
							...provided,
							maxHeight: "300px",
							overflowY: "auto",
						}),
						menu: (provided) => ({
							...provided,
							maxHeight: "200px",
							overflowY: "hidden",
						}),
					}}
					isSearchable={true}
					noOptionsMessage={() =>
						allPharmacyListResponse?.loading ? "Loading..." : "No Options"
					}
					className="basic-multi-select"
					placeholder={`${errorMessages.transferingForm ? "" : "Select Pharmacy"}`}
				/>

			</div>
		</>
	);
};