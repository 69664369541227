import { createSlice } from "@reduxjs/toolkit";
import { UpdatedDefaultPharmacist } from "../../apis/supervisor/updatedDefaultPharmacist";

const initialState = {
    loading: false,
    data: null,
    error: null
}

const UpdatedDefaultPharmacistSlice = createSlice({
    name: 'UpdatedDefaultPharmacist',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(UpdatedDefaultPharmacist.pending.type, (state) => {
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            }
        })

        builder.addCase(UpdatedDefaultPharmacist.fulfilled.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        })

        builder.addCase(UpdatedDefaultPharmacist.rejected.type, (state, action) => {
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        })
    }

})

export default UpdatedDefaultPharmacistSlice.reducer